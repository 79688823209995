import { Injectable } from '@angular/core';

// services
import { GLOBAL } from './global';


@Injectable({
  providedIn: 'root'
})

export class ConfigService {

	public imagen_default_inmueble: string;
	public imagen_default_agente: string;
	public imagen_default_inicio: string;
	public imagenes_default_inicio: Array<string>;
	public imagen_default_nosotros: string;
	public imagen_default_servicios: string;
    public imagen_default_emprendimientos: string;
	public imagen_default_contactos: string;

	constructor() {

		const imagen_default_inmueble = GLOBAL.web && GLOBAL.web.imagen_default_inmueble ? GLOBAL.web.imagen_default_inmueble : 'default-1.jpg';
		const imagen_default_agente = GLOBAL.web && GLOBAL.web.imagen_default_agente ? GLOBAL.web.imagen_default_agente : 'default-1.jpg';

		this.imagen_default_inmueble = GLOBAL.url_webs_default + 'inmueble/' + imagen_default_inmueble;
		this.imagen_default_agente = GLOBAL.url_webs_default + 'agente/' + imagen_default_agente;
		this.imagen_default_inicio = GLOBAL.url_webs_default + 'inicio/default-1.jpg';
		this.imagenes_default_inicio = [ 
											'default-1.jpg', 
											'default-2.jpg', 
											'default-3.jpg',
											'default-4.jpg',
											'default-5.jpg'
										];
		this.imagen_default_nosotros = GLOBAL.url_webs_default + 'nosotros/default-1.jpg';
		this.imagen_default_servicios = GLOBAL.url_webs_default + 'servicios/default-1.jpg';
        this.imagen_default_emprendimientos = GLOBAL.url_webs_default + 'emprendimientos/default-1.jpg';
		this.imagen_default_contactos = GLOBAL.url_webs_default + 'contactos/default-1.jpg';
	}

	getTodosLosDatosDeConfiguracion(){

		const obj = {
			imagen_default_inmueble: this.imagen_default_inmueble,
			imagen_default_agente: this.imagen_default_agente,
			imagen_default_inicio: this.imagen_default_inicio,
			imagenes_default_inicio: this.imagenes_default_inicio,
			imagen_default_nosotros: this.imagen_default_nosotros,
			imagen_default_servicios: this.imagen_default_servicios,
            imagen_default_emprendimientos: this.imagen_default_emprendimientos,
			imagen_default_contactos: this.imagen_default_contactos
		};

		return obj;
	}
}