import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
	selector: 'app-pagination',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.css'
]})

export class PaginationComponent {
	
	@Input() current_page: number;
	@Input() items_total: number;
	@Input() items_x_page: number;

	@Output() eventSelectPage = new EventEmitter();

	public cant_pages: number;
	public show_pages: Array<any>;

	constructor(private cdRef: ChangeDetectorRef){

		this.cant_pages = 0;
		this.show_pages = [];
	}

	ngOnInit(){
		//console.log('PaginationComponente esta cargado ..');
	}

	ngOnChanges(){
		// Se ejecuta ngOnChanges, cuando cambio cualquier propiedad @Input
		// desde el template del padre.
		this.show();
	}

	// Asi tiene que ser el formato del objeto params
	/*
	params = {
			page: 0,
			total_items: 0,
			items_x_page: 0
		}
	*/
	show(params: any = { page: null, items_total: null, items_x_page: null }){

		const { page, items_total, items_x_page } = params;

		this.current_page = page ? page : this.current_page;
		this.items_total = items_total ? items_total : this.items_total;
		this.items_x_page = items_x_page ? items_x_page : this.items_x_page;
		
		this.show_pages = [];

		this.items_x_page = this.items_x_page == 0 ? 1 : this.items_x_page;

		this.cant_pages = Math.ceil(this.items_total / this.items_x_page);


		if ( this.cant_pages > 10) {

			for(var i=1; i<= this.cant_pages; i++){
				let agregado = false;

				// Si son los dos primeros siempre los agrego
				if ( i <= 2 ) { 
					this.show_pages.push(i);
					agregado = true;
				}
				// Si son las dos ultimas paginas siempre las agrego
				if (( !agregado ) && (i >= this.cant_pages - 1)) {
					this.show_pages.push(i);
					agregado = true;
				}
				// Si la pagina actual es menor a 6 agrego todas las menores a 6
				if (( !agregado ) && (i<=6) && (this.current_page <= 6)) {
					this.show_pages.push(i);
					agregado = true;
				}

				// Si la pagina actual esta dentro de las ultimas 6 paginas, agrego las ultimas 6
				if (( !agregado) && (i >= this.cant_pages - 6) && (this.current_page >= this.cant_pages-6)) {
					this.show_pages.push(i);
					agregado = true;
				}

				// Si es mas -2 o +2 a la pagina actual, lo incluyo
				if (( !agregado) && ( i >= this.current_page -2 ) && ( i <= this.current_page + 2)) {
					this.show_pages.push(i);
					agregado = true;
				}
			}
		} else if ( this.cant_pages > 0 ) {
			for(var i=1; i<= this.cant_pages; i++){
				this.show_pages.push(i);
			}			
		}

		// Obligo a que actualice el DOM 
		this.cdRef.detectChanges();
	}

	selectPage(page){
		this.eventSelectPage.emit({page: page});
	}

	previous(){

		const page = this.current_page > 1 ? (this.current_page - 1) : 1;

		this.eventSelectPage.emit({page: page});
	}

	next(){

		const page = this.current_page < this.cant_pages ? (this.current_page + 1) : this.cant_pages;

		this.eventSelectPage.emit({page: page});
	}
}