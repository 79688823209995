<div class="container p-t-20 p-b-20">

	<div class="page-wrapper">

		<article class="inmueble" *ngIf="inmueble">

			<h1 class="title">{{ inmueble.titulo }}</h1>

			<div class="images">

        <ng-template [ngIf]="inmueble.imagen_principal" [ngIfElse]="imagenInmuebleDefault">
            <app-carousel-imagenes
                [pathDirImages]="url_images_inmuebles"
                [images]="imagenes"
                [width]="'100%'"
                [height]="'100%'"
                [show_zoom_button]="true"
                [show_image_text]="false"
                (onZoomImagen)="showGallery($event)"
            ></app-carousel-imagenes>
        </ng-template>

        <ng-template #imagenInmuebleDefault>
            <img src="{{ imagen_default_inmueble }} ">
        </ng-template>

        <ng-container [ngSwitch]="inmueble.id_estado_aviso">
          <div class="inmueble-estado reservado" *ngSwitchCase="2">
            Reservado
          </div>
          <div class="inmueble-estado vendido" *ngSwitchCase="3">
            Vendido
          </div>
          <div class="inmueble-estado alquilado" *ngSwitchCase="4">
            Alquilado
          </div>
        </ng-container>
			</div>

      <!-- Section - Mensajes Importantes -->
      <div class="mensajes-importantes">
        <div class="mensaje-importante" *ngIf="inmueble.urgente">
          Urgente
        </div>

        <div class="mensaje-importante" *ngIf="inmueble.oportunidad">
          Oportunidad
        </div>

        <div class="mensaje-importante" *ngIf="inmueble.apto_credito">
          Apto crédito
        </div>
        <div class="mensaje-importante" *ngIf="inmueble.retasado">
            Retasado
        </div>
      </div>

			<div class="data">
				<span class="codigo">Código: {{ inmueble.id }}</span>

        <div class="compartir-redes-sociales" *ngIf="url_inmueble">

            <a (click)="compartirInmueble('facebook')" class="compartir facebook" title="Compartir en Facebook">
                <i class="mdi mdi-facebook"></i>
            </a>

            <a (click)="compartirInmueble('twitter')" class="compartir twitter" title="Compartir en Twitter">
                <i class="mdi mdi-twitter"></i>
            </a>

            <a (click)="compartirInmueble('pinterest')" class="compartir pinterest" title="Compartir en Pinterest">
                <i class="mdi mdi-pinterest"></i>
            </a>

            <a (click)="compartirInmueble('whatsapp')" class="compartir whatsapp" title="Compartir en WhatsApp">
                <i class="mdi mdi-whatsapp"></i>
            </a>

        </div>

            <div class="precio">
                <div class="arrow"></div>

                <ng-template [ngIf]="!inmueble.no_publicar_precio && inmueble.precio && inmueble.precio > 0" [ngIfElse]="sinPrecio">
                    <span *ngIf="inmueble.moneda == 1" class="moneda">$</span>
                    <span *ngIf="inmueble.moneda == 2" class="moneda">U$D</span>
                    <span>{{ inmueble.precio | number: '1.0' }}</span>
                </ng-template>

                <ng-template #sinPrecio>
                    <span>Consulte su valor</span>
                </ng-template>
            </div>

            <div class="tipo_inmueble">
                <div class="arrow"></div>
                <span>{{ inmueble.tipo_inmueble }}  </span>
                <span *ngIf="inmueble.tipo_unidad"> - {{ inmueble.tipo_unidad }}</span>
            </div>

            <div class="tipo_operacion">
                <div class="arrow"></div>
                <span>{{ inmueble.tipo_operacion }}</span>
            </div>

      </div>

			<div class="content">
				<div class="details">
					<!-- Detalles del tipo casa -->
                    <ng-container *ngIf="inmueble.id_tipo_inmueble == 1">
                    	<span *ngIf="inmueble.ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.ambientes}} ambientes</span>
                    	<span *ngIf="inmueble.dormitorios"><i class="mdi mdi-bed"></i>{{inmueble.dormitorios}} dormitorios</span>
                        <span *ngIf="(inmueble.id_tipo_operacion == 3 || inmueble.id_tipo_operacion == 4 ) && inmueble.huespedes"><i class="mdi mdi-account-multiple"></i>Cant. Huéspedes: {{inmueble.huespedes}}</span>
                    	<span *ngIf="inmueble.banos"><i class="mdi mdi-shower"></i>{{inmueble.banos}} baños</span>
                    	<span *ngIf="inmueble.cocheras"><i class="mdi mdi-car"></i>{{inmueble.cocheras}} cocheras</span>
                    	<span *ngIf="inmueble.plantas"><i class="mdi mdi-stairs"></i>Plantas: {{inmueble.plantas}}</span>
                    	<span *ngIf="inmueble.sup_cubierta && inmueble.sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.sup_cubierta}} m²</span>
                    	<span *ngIf="inmueble.sup_terreno && inmueble.sup_terreno > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. terreno: {{inmueble.sup_terreno}} m²</span>
                    	<span *ngIf="inmueble.long_frente && inmueble.long_frente > 0"><i class="mdi mdi-vector-line"></i>Long.frente:  {{inmueble.long_frente}} </span>
                    	<span *ngIf="inmueble.long_fondo && inmueble.long_fondo > 0"><i class="mdi mdi-vector-line"></i>Long.fondo:  {{inmueble.long_fondo}}</span>
                    	<span *ngIf="inmueble.estado"><i class="mdi mdi-sort-descending"></i>Estado: {{inmueble.estado}} </span>
                    	<span *ngIf="inmueble.tipo_techo"><i class="mdi mdi-home-outline"></i>Tipo techo: {{inmueble.tipo_techo}}</span>
                    	<span *ngIf="inmueble.tipo_piso"><i class="mdi mdi-format-vertical-align-bottom"></i>Tipo piso: {{inmueble.tipo_piso}}</span>
                        <span *ngIf="(inmueble.id_tipo_operacion == 3 || inmueble.id_tipo_operacion == 4 ) && inmueble.admite_mascotas"><i class="mdi mdi-dog-side"></i>Se admiten mascotas</span>
                    </ng-container>
                    <!-- Detalles del tipo cochera -->
                    <ng-container *ngIf="inmueble.id_tipo_inmueble == 2">
                    	<span *ngIf="inmueble.largo && inmueble.largo > 0"><i class="mdi mdi-vector-line"></i>Largo: {{inmueble.largo}} mt.</span>
                    	<span *ngIf="inmueble.ancho && inmueble.ancho > 0"><i class="mdi mdi-vector-line"></i>Ancho: {{inmueble.ancho}} mt.</span>
                        <span *ngIf="inmueble.altura && inmueble.altura > 0"><i class="mdi mdi-vector-line"></i>Altura: {{inmueble.altura}} mt.</span>
                        <span *ngIf="inmueble.sup_cubierta && inmueble.sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.sup_cubierta}} m²</span>
                    	<span *ngIf="inmueble.tipo_cobertura"><i class="mdi mdi-garage-open"></i>Cobertura: {{inmueble.tipo_cobertura}}</span>
                    	<span *ngIf="inmueble.tipo_coche"><i class="mdi mdi-car"></i>Coche: {{inmueble.tipo_coche}}</span>
                    	<span *ngIf="inmueble.tipo_cochera"><i class="mdi mdi-car-side"></i>Tipo: {{inmueble.tipo_cochera}}</span>
                    	<span *ngIf="inmueble.tipo_acceso"><i class="mdi mdi-arrow-up-bold-outline"></i>Acceso: {{inmueble.tipo_acceso}}</span>
                    	<span *ngIf="inmueble.baulera"><i class="mdi mdi-check"></i>Con baulera</span>
                    </ng-container>
                    <!-- Detalles del tipo comercio -->
                    <ng-container *ngIf="inmueble.id_tipo_inmueble == 3">
                    	<span *ngIf="inmueble.sup_local && inmueble.sup_local > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. local: {{inmueble.sup_local}}</span>
                    	<span *ngIf="inmueble.sup_deposito && inmueble.sup_deposito > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. deposito: {{inmueble.sup_deposito}}</span>
                    	<span *ngIf="inmueble.ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.ambientes}} ambientes</span>
                    	<span *ngIf="inmueble.banos"><i class="mdi mdi-toilet"></i>{{inmueble.banos}} baños</span>
                    	<span *ngIf="inmueble.cocheras"><i class="mdi mdi-car"></i>{{inmueble.cocheras}} cocheras</span>
                    	<span *ngIf="inmueble.estado"><i class="mdi mdi-sort-descending"></i>Estado: {{inmueble.estado}} </span>
                    	<span *ngIf="inmueble.recaudacion_mensual && inmueble.recaudacion_mensual > 0"><i class="mdi mdi-currency-usd"></i>Recaudación: {{inmueble.recaudacion_mensual}}</span>
                    	<span *ngIf="inmueble.antiguedad_comercio"><i class="mdi mdi-calendar-clock"></i>Antiguedad: {{inmueble.antiguedad_comercio}}</span>
                    </ng-container>
                    <!-- Detalles del tipo departamento -->
                    <ng-container  *ngIf="inmueble.id_tipo_inmueble == 4">
                    	<span *ngIf="inmueble.ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.ambientes}} ambientes</span>
                    	<span *ngIf="inmueble.dormitorios"><i class="mdi mdi-bed"></i>{{inmueble.dormitorios}} dormitorios</span>
                        <span *ngIf="(inmueble.id_tipo_operacion == 3 || inmueble.id_tipo_operacion == 4 ) && inmueble.huespedes"><i class="mdi mdi-account-multiple"></i>Cant. Huéspedes: {{inmueble.huespedes}}</span>
                    	<span *ngIf="inmueble.banos"><i class="mdi mdi-shower"></i>{{inmueble.banos}} baños</span>
                    	<span *ngIf="inmueble.toilettes"><i class="mdi mdi-toilet"></i>{{inmueble.toilettes}} toilettes</span>
                    	<span *ngIf="inmueble.cocheras"><i class="mdi mdi-car"></i>{{inmueble.cocheras}} cocheras</span>
                    	<span *ngIf="inmueble.sup_cubierta && inmueble.sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.sup_cubierta}} m²</span>
                    	<span *ngIf="inmueble.sup_descubierta && inmueble.sup_cubierta > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. descubierta: {{inmueble.sup_descubierta}} m²</span>
                    	<span *ngIf="inmueble.estado"><i class="mdi mdi-sort-descending"></i>Estado: {{inmueble.estado}} </span>
                    	<span *ngIf="inmueble.disposicion"><i class="mdi mdi-compass-outline"></i>Disposición:  {{inmueble.disposicion}} </span>
                    	<span *ngIf="inmueble.tipo_balcon"><i class="mdi mdi-gate"></i>Balcon: {{inmueble.tipo_balcon}}</span>
                    	<span *ngIf="inmueble.expensas && inmueble.expensas > 0"><i class="mdi mdi-cash-usd"></i>Expensas: {{inmueble.expensas}}</span>
                        <span *ngIf="(inmueble.id_tipo_operacion == 2 || inmueble.id_tipo_operacion == 3 || inmueble.id_tipo_operacion == 4 ) && inmueble.admite_mascotas"><i class="mdi mdi-dog-side"></i>Se admiten mascotas</span>
                    </ng-container>
                    <!-- Detalles del tipo galpon -->
                    <ng-container *ngIf="inmueble.id_tipo_inmueble == 5">
                        <span *ngIf="inmueble.sup_cubierta && inmueble.sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.sup_cubierta}} m²</span>
                    	<span *ngIf="inmueble.sup_descubierta && inmueble.sup_descubierta > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. descubierta: {{inmueble.sup_descubierta}} m²</span>
                    	<span *ngIf="inmueble.long_frente && inmueble.long_frente > 0"><i class="mdi mdi-vector-line"></i>Long. frente: {{inmueble.long_frente}} mt.</span>
                    	<span *ngIf="inmueble.long_fondo && inmueble.long_fondo > 0"><i class="mdi mdi-vector-line"></i>Long. fondo: {{inmueble.long_fondo}} mt.</span>
                    	<span *ngIf="inmueble.altura_techo && inmueble.altura_techo > 0"><i class="mdi mdi-home-outline"></i>Altura: {{inmueble.altura_techo}} mt.</span>
                    	<span *ngIf="inmueble.banos"><i class="mdi mdi-toilet"></i>{{inmueble.banos}} baños</span>
                    	<span *ngIf="inmueble.oficinas"><i class="mdi mdi-desk-lamp"></i>{{inmueble.oficinas}} oficinas</span>
                    	<span *ngIf="inmueble.cocheras"><i class="mdi mdi-car"></i>{{inmueble.cocheras}} cocheras</span>
                    	<span *ngIf="inmueble.estado"><i class="mdi mdi-sort-descending"></i>Estado: {{inmueble.estado}}</span>
                    </ng-container>
                    <!-- Detalles del tipo local -->
                    <ng-container *ngIf="inmueble.id_tipo_inmueble == 6">
                        <span *ngIf="inmueble.sup_cubierta && inmueble.sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.sup_cubierta}} m²</span>
                        <span *ngIf="inmueble.sup_descubierta && inmueble.sup_descubierta > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. descubierta: {{inmueble.sup_descubierta}} m²</span>
                        <span *ngIf="inmueble.long_frente && inmueble.long_frente > 0"><i class="mdi mdi-vector-line"></i>Long.frente:  {{inmueble.long_frente}} </span>
                    	<span *ngIf="inmueble.long_fondo && inmueble.long_fondo > 0"><i class="mdi mdi-vector-line"></i>Long.fondo:  {{inmueble.long_fondo}}</span>
                    	<span *ngIf="inmueble.banos"><i class="mdi mdi-toilet"></i>{{inmueble.banos}} baños</span>
                    	<span *ngIf="inmueble.cocheras"><i class="mdi mdi-car"></i>{{inmueble.cocheras}} cocheras</span>
                    	<span *ngIf="inmueble.plantas"><i class="mdi mdi-stairs"></i>Plantas: {{inmueble.plantas}}</span>
                    	<span *ngIf="inmueble.estado"><i class="mdi mdi-sort-descending"></i>Estado: {{inmueble.estado}} </span>
                    	<span *ngIf="inmueble.tipo_local"><i class="mdi mdi-store"></i>Tipo local: {{inmueble.tipo_local}}</span>
                    </ng-container>
                    <!-- Detalles del tipo obra nueva -->
                    <ng-container *ngIf="inmueble.id_tipo_inmueble == 7">
                        <span *ngIf="inmueble.estado_construccion"><i class="mdi mdi-chart-gantt"></i>Estado: {{inmueble.estado_construccion}}</span>
                    	<span *ngIf="inmueble.construye"><i class="mdi mdi-office-building"></i>Construye: {{inmueble.construye}}</span>
                    	<span *ngIf="inmueble.dirige"><i class="mdi mdi-teach"></i>Dirige: {{inmueble.dirige}}</span>
                    	<span *ngIf="inmueble.administra"><i class="mdi mdi-site-map"></i>Administra: {{inmueble.administra}}</span>
                    	<span *ngIf="inmueble.f_fecha_inicio"><i class="mdi mdi-calendar-clock"></i>Inicio: {{inmueble.f_fecha_inicio}}</span>
                    	<span *ngIf="inmueble.f_fecha_entrega"><i class="mdi mdi-calendar-check"></i>Entrega: {{inmueble.f_fecha_entrega}}</span>
                    </ng-container>
                    <!-- Detalles del tipo oficina -->
                    <ng-container *ngIf="inmueble.id_tipo_inmueble == 8">
                        <span *ngIf="inmueble.sup_oficina && inmueble.sup_oficina > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. oficina: {{inmueble.sup_oficina}} m²</span>
                        <span *ngIf="inmueble.sup_deposito && inmueble.sup_deposito > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. deposito: {{inmueble.sup_deposito}} m²</span>
                        <span *ngIf="inmueble.ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.ambientes}} ambientes</span>
                        <span *ngIf="inmueble.banos"><i class="mdi mdi-shower"></i>{{inmueble.banos}} baños</span>
                    	<span *ngIf="inmueble.toilettes"><i class="mdi mdi-toilet"></i>{{inmueble.toilettes}} toilettes</span>
                    	<span *ngIf="inmueble.cocheras"><i class="mdi mdi-car"></i>{{inmueble.cocheras}} cocheras</span>
                    	<span *ngIf="inmueble.estado"><i class="mdi mdi-sort-descending"></i>Estado: {{inmueble.estado}} </span>
                    	<span *ngIf="inmueble.expensas && inmueble.expensas > 0"><i class="mdi mdi-cash-usd"></i>Expensas: {{inmueble.expensas}}</span>
                    </ng-container>
                    <!-- Detalles del quinta -->
                    <ng-container *ngIf="inmueble.id_tipo_inmueble == 9">
                        <span *ngIf="inmueble.ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.ambientes}} ambientes</span>
                    	<span *ngIf="inmueble.dormitorios"><i class="mdi mdi-bed"></i>{{inmueble.dormitorios}} dormitorios</span>
                        <span *ngIf="(inmueble.id_tipo_operacion == 3 || inmueble.id_tipo_operacion == 4 ) && inmueble.huespedes"><i class="mdi mdi-account-multiple"></i>Cant. Huéspedes: {{inmueble.huespedes}}</span>
                    	<span *ngIf="inmueble.banos"><i class="mdi mdi-shower"></i>{{inmueble.banos}} baños</span>
                    	<span *ngIf="inmueble.toilettes"><i class="mdi mdi-toilet"></i>{{inmueble.toilettes}} toilettes</span>
                    	<span *ngIf="inmueble.cocheras"><i class="mdi mdi-car"></i>{{inmueble.cocheras}} cocheras</span>
                    	<span *ngIf="inmueble.plantas"><i class="mdi mdi-stairs"></i>Plantas: {{inmueble.plantas}}</span>
                    	<span *ngIf="inmueble.sup_cubierta && inmueble.sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.sup_cubierta}} m²</span>
                    	<span *ngIf="inmueble.sup_terreno && inmueble.sup_terreno > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. terreno: {{inmueble.sup_terreno}} m²</span>
                    	<span *ngIf="inmueble.long_frente && inmueble.long_frente > 0"><i class="mdi mdi-vector-line"></i>Long.frente:  {{inmueble.long_frente}} </span>
                    	<span *ngIf="inmueble.long_fondo && inmueble.long_fondo > 0"><i class="mdi mdi-vector-line"></i>Long.fondo:  {{inmueble.long_fondo}}</span>
                        <span *ngIf="inmueble.tipo_acceso"><i class="mdi mdi-car-traction-control"></i>Tipo de acceso: {{inmueble.tipo_acceso}}</span>
                    	<span *ngIf="inmueble.estado"><i class="mdi mdi-sort-descending"></i>Estado: {{inmueble.estado}} </span>
                    	<span *ngIf="inmueble.tipo_techo"><i class="mdi mdi-home-outline"></i>Tipo techo: {{inmueble.tipo_techo}}</span>
                    	<span *ngIf="inmueble.tipo_piso"><i class="mdi mdi-format-vertical-align-bottom"></i>Tipo piso: {{inmueble.tipo_piso}}</span>
                        <span *ngIf="(inmueble.id_tipo_operacion == 3 || inmueble.id_tipo_operacion == 4 ) && inmueble.admite_mascotas"><i class="mdi mdi-dog-side"></i>Se admiten mascotas</span>
                    </ng-container>
                    <!-- Detalles del terreno -->
                    <ng-container *ngIf="inmueble.id_tipo_inmueble == 10">
                        <span *ngIf="inmueble.sup_terreno && inmueble.sup_terreno > 0"><i class="mdi mdi-vector-difference-ab"></i>Superficie: {{inmueble.sup_terreno}} m²</span>
                        <span *ngIf="inmueble.sup_construible && inmueble.sup_construible > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. construible: {{inmueble.sup_construible}} m²</span>
                        <span *ngIf="inmueble.long_frente && inmueble.long_frente > 0"><i class="mdi mdi-vector-line"></i>Long.frente:  {{inmueble.long_frente}} </span>
                    	<span *ngIf="inmueble.long_fondo && inmueble.long_fondo > 0"><i class="mdi mdi-vector-line"></i>Long.fondo:  {{inmueble.long_fondo}}</span>
                    	<span *ngIf="inmueble.fot && inmueble.fot > 0"><i class="mdi mdi-wall"></i>FOT: {{inmueble.fot}}</span>
                    	<span *ngIf="inmueble.zonificacion"><i class="mdi mdi-map-search-outline"></i>Zonificacion: {{inmueble.zonificacion}}</span>
                    </ng-container>
                    <!-- Detalles del PH Duplex -->
                    <ng-container *ngIf="inmueble.id_tipo_inmueble == 11">
                        <span *ngIf="inmueble.ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.ambientes}} ambientes</span>
                    	<span *ngIf="inmueble.dormitorios"><i class="mdi mdi-bed"></i>{{inmueble.dormitorios}} dormitorios</span>
                        <span *ngIf="(inmueble.id_tipo_operacion == 3 || inmueble.id_tipo_operacion == 4 ) && inmueble.huespedes"><i class="mdi mdi-account-multiple"></i>Cant. Huéspedes: {{inmueble.huespedes}}</span>
                    	<span *ngIf="inmueble.banos"><i class="mdi mdi-shower"></i>{{inmueble.banos}} baños</span>
                    	<span *ngIf="inmueble.toilettes"><i class="mdi mdi-toilet"></i>{{inmueble.toilettes}} toilettes</span>
                    	<span *ngIf="inmueble.cocheras"><i class="mdi mdi-car"></i>{{inmueble.cocheras}} cocheras</span>
                    	<span *ngIf="inmueble.plantas"><i class="mdi mdi-stairs"></i>Plantas: {{inmueble.plantas}}</span>
                    	<span *ngIf="inmueble.sup_cubierta && inmueble.sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.sup_cubierta}} m²</span>
                    	<span *ngIf="inmueble.sup_descubierta && inmueble.sup_descubierta > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. descubierta: {{inmueble.sup_descubierta}} m²</span>
                    	<span *ngIf="inmueble.estado"><i class="mdi mdi-sort-descending"></i>Estado: {{inmueble.estado}} </span>
                        <span *ngIf="(inmueble.id_tipo_operacion == 3 || inmueble.id_tipo_operacion == 4 ) && inmueble.admite_mascotas"><i class="mdi mdi-dog-side"></i>Se admiten mascotas</span>
                    </ng-container>
                    <!-- Detalles del Campo -->
                    <ng-container *ngIf="inmueble.id_tipo_inmueble == 12">
                        <span *ngIf="inmueble.tipo_campo"><i class="mdi mdi-tractor-variant"></i>Tipo de campo:  {{inmueble.tipo_campo}} </span>
                        <span *ngIf="inmueble.sup_terreno && inmueble.sup_terreno > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. total: {{inmueble.sup_terreno}} m²</span>
                        <span *ngIf="inmueble.sup_cubierta && inmueble.sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.sup_cubierta}} m²</span>
                        <span *ngIf="inmueble.cant_hectareas && inmueble.cant_hectareas > 0"><i class="mdi mdi-axis-arrow"></i>Cant. hectáreas: {{inmueble.cant_hectareas}} ha</span>
                        <span *ngIf="inmueble.tipo_acceso"><i class="mdi mdi-car-traction-control"></i>Tipo de acceso: {{inmueble.tipo_acceso}}</span>
                        <span *ngIf="inmueble.distancia_al_asfalto"><i class="mdi mdi-format-vertical-align-bottom"></i>Distancia al asfalto: {{inmueble.distancia_al_asfalto}}</span>
                        <span *ngIf="inmueble.forma_del_terreno"><i class="mdi mdi-wave"></i>Forma del terreno: {{inmueble.forma_del_terreno}}</span>
                        <span *ngIf="inmueble.casa_principal"><i class="mdi mdi-home-floor-1"></i>Tiene Casco o casa principal: Sí</span>
                        <span *ngIf="inmueble.casa_caseros"><i class="mdi mdi-home-floor-2"></i>Tiene casa de caseros: Sí</span>
                        <span *ngIf="inmueble.ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.ambientes}} ambientes</span>
                        <span *ngIf="inmueble.dormitorios"><i class="mdi mdi-bed"></i>{{inmueble.dormitorios}} dormitorios</span>
                        <span *ngIf="inmueble.banos"><i class="mdi mdi-shower"></i>{{inmueble.banos}} baños</span>
                        <span *ngIf="inmueble.estado"><i class="mdi mdi-sort-descending"></i>Estado: {{inmueble.estado}} </span>
                    </ng-container>
                    <!-- Detalles del Hotel -->
                    <ng-container *ngIf="inmueble.id_tipo_inmueble == 13">
                        <span *ngIf="inmueble.tipo_hotel"><i class="mdi mdi-office-building-outline"></i>Tipo de hotel:  {{inmueble.tipo_hotel}} </span>
                        <span *ngIf="inmueble.sup_terreno && inmueble.sup_terreno > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. total: {{inmueble.sup_terreno}} m²</span>
                        <span *ngIf="inmueble.sup_cubierta && inmueble.sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.sup_cubierta}} m²</span>
                        <span *ngIf="inmueble.estrellas && inmueble.estrellas > 0"><i class="mdi mdi-star"></i>Cant. estrellas: {{inmueble.estrellas}}</span>
                        <span *ngIf="inmueble.habitaciones && inmueble.habitaciones > 0"><i class="mdi mdi-bed"></i>Habitaciones: {{inmueble.habitaciones}}</span>
                        <span *ngIf="inmueble.pisos && inmueble.pisos > 0"><i class="mdi mdi-stairs"></i>Pisos: {{inmueble.pisos}}</span>
                        <span *ngIf="inmueble.habitaciones_x_piso && inmueble.habitaciones_x_piso > 0"><i class="mdi mdi-bed"></i>Habitaciones por piso: {{inmueble.habitaciones_x_piso}}</span>
                        <span *ngIf="inmueble.cant_plazas && inmueble.cant_plazas > 0"><i class="mdi mdi-bed-empty"></i>Cantidad de plazas: {{inmueble.cant_plazas}}</span>
                        <span *ngIf="inmueble.cant_cubiertos && inmueble.cant_cubiertos > 0"><i class="mdi mdi-silverware-fork-knife"></i>Cantidad de cubiertos: {{inmueble.cant_cubiertos}}</span>
                        <span *ngIf="inmueble.cant_personal && inmueble.cant_personal > 0"><i class="mdi mdi-account-multiple"></i>Cantidad de personal: {{inmueble.cant_personal}}</span>
                        <span *ngIf="inmueble.estado"><i class="mdi mdi-sort-descending"></i>Estado: {{inmueble.estado}} </span>
                    </ng-container>
				</div>

				<div class="description">
					<p [innerHtml]="inmueble.descripcion"></p>
				</div>

				<div class="checks" *ngIf="ambientes.length > 0">
					<h4>Ambientes</h4>

					<ng-container *ngFor="let prop of ambientes">

                        <span>
                            <i class="mdi mdi-check icon"></i> {{ checkboxCampos.get(prop.key) }}
                        </span>

					</ng-container>
				</div>

				<div class="checks" *ngIf="instalaciones.length > 0">
					<h4>Instalaciones</h4>
					<ng-container *ngFor="let prop of instalaciones">

                        <span>
						    <i class="mdi mdi-check icon"></i> {{ checkboxCampos.get(prop.key) }}
                        </span>

					</ng-container>
				</div>

				<div class="checks" *ngIf="servicios.length > 0">
					<h4>Servicios</h4>
					<ng-container *ngFor="let prop of servicios">

                        <span>
						    <i class="mdi mdi-check icon"></i> {{ checkboxCampos.get(prop.key) }}
                        </span>

					</ng-container>
				</div>

				<div class="checks" *ngIf="edificio.length > 0">
					<h4>Edificio</h4>
					<ng-container *ngFor="let prop of edificio">

						<span>
							<i class="mdi mdi-check icon"></i> {{ checkboxCampos.get(prop.key) }}
						</span>

					</ng-container>
				</div>

                <div class="wrap-video" *ngIf="inmueble.url_video">
					<span class="title">video</span>

                    <iframe class="reproductor-video" [src]="inmueble.url_video | urlSegura">
                    </iframe>
				</div>

				<div class="ubication">
					<span class="title">Ubicación</span>

                    <div class="map">
                        <app-mapa-inmuebles #mapainmuebles
                            [marker]="inmueble">
                        </app-mapa-inmuebles>
                    </div>

					<div class="footer">
						<div>
							<label>Dirección: </label>
							<span> {{ inmueble.calle }} <ng-container *ngIf="inmueble.numero">{{ inmueble.numero }}</ng-container> </span>
						</div>
                        <div *ngIf="inmueble.id_ciudad != inmobiliaria.id_ciudad">
							<label> Localidad: </label>
							<span *ngIf="inmueble.id_provincia != inmobiliaria.id_provincia "> {{ inmueble.provincia }}</span><span>{{ inmueble.ciudad }} </span>
						</div>
						<div *ngIf="inmueble.id_barrio">
							<label> Barrio: </label>
							<span> {{ inmueble.barrio_nombre }} </span>
						</div>
					</div>
				</div>
			</div>
		</article>

		<div class="consult" *ngIf="inmobiliaria">
			<h2 class="title">Consultenos</h2>

			<div class="text">
				<p class="">Para consultas sobre esta propiedad, contactenos a los siguientes telefonos o completando el formulario y a la brevedad nos pondremos en contacto con usted. Muchas gracias!</p>
			</div>

			<div class="contacts">
				<ul class="">
					<li *ngIf="inmobiliaria.telefonos">
                        <a href="tel:+54{{ inmobiliaria.telefonos }}">
                            <i class="mdi mdi-phone"></i>{{ inmobiliaria.telefonos }}
                        </a>
                    </li>
                    <li *ngIf="inmobiliaria.celulares">
                        <a href="tel:+54{{ inmobiliaria.celulares }}">
                            <i class="mdi mdi-cellphone"></i>{{ inmobiliaria.celulares }}
                        </a>
                    </li>
                    <li *ngIf="inmobiliaria.email">
                        <a href="mailto:{{ inmobiliaria.email }}">
                            <i class="mdi mdi-email"></i>{{ inmobiliaria.email }}
                        </a>
                    </li>
				</ul>
			</div>

			<div class="form">
				<form [formGroup]="formConsulta">

					<div class="form-group">
						<input type="text" formControlName="nombre" placeholder="Nombre" class="form-control">
						<small *ngIf="formConsulta.get('nombre').invalid && (formConsulta.get('nombre').dirty || formConsulta.get('nombre').touched)" class="form-control-feedback error">
							Por favor, ingrese su nombre.
						</small>
					</div>
					<div class="form-group">
						<input type="text" formControlName="email" placeholder="Email" class="form-control" (keydown)="deleteEspaciosVacios($event)">
						<ng-container *ngIf="(formConsulta.get('email').dirty || formConsulta.get('email').touched)">
	                        <small *ngIf="formConsulta.get('email').errors?.required" class="form-control-feedback error">
	                            Por favor, ingrese su correo electrónico.
	                        </small>
	                        <small *ngIf="formConsulta.get('email').errors?.email" class="form-control-feedback error">
	                            El campo correo electrónico no es valido.
	                        </small>
	                    </ng-container>
	                </div>
                    <div class="form-group">
                        <input type="text" formControlName="celular" class="form-control" placeholder="Num. celular">
                    </div>
	                <div class="form-group">
						<textarea class="form-control form-textarea" formControlName="mensaje" placeholder="Consulta"></textarea>
						<small *ngIf="formConsulta.get('mensaje').invalid && (formConsulta.get('mensaje').dirty || formConsulta.get('mensaje').touched)" class="form-control-feedback error">
							Por favor, ingrese su consulta.
						</small>
					</div>

					<button type="button" class="form-button" (click)="validateConsulta()">Enviar Consulta</button>
				</form>
			</div>

            <div class="mensaje color-success" *ngIf="show_mensaje === 'success'">
                <i class="mdi mdi-check-circle-outline"></i>
                La consulta se ha enviado correctamente.
            </div>

            <div class="mensaje color-error" *ngIf="show_mensaje === 'error'">
                <i class="mdi mdi-close-circle-outline"></i>
                Ocurrió un error al intentar enviar la consulta. Por favor, intente nuevamente.
            </div>

            <app-spinner *ngIf="show_spinner" [titulo]="'Enviando ..'"></app-spinner>
		</div>

        <div class="agents" *ngIf="web.staff_inmuebles && agentes">
            <h2 class="title">{{ web.staff_titulo }}</h2>

            <div class="text">
                <p class="">Información de los agentes que responden al inmueble. Si algún agente se encuentra conectado, podrá realizar su consulta de manera online.</p>
            </div>

            <ng-container *ngFor="let agente of agentes">

                <div class="agente">

                    <figure (click)="openChat(agente)">

                        <ng-template [ngIf]="agente.imagen" [ngIfElse]="imagenAgenteDefault">
                            <img src="{{ url_images_agentes + agente.imagen }}" alt="Agente inmobiliario">
                        </ng-template>

                        <ng-template #imagenAgenteDefault>
                            <img src="{{ imagen_default_agente }}" alt="Agente inmobiliario">
                        </ng-template>

                        <span class="agente-tooltip-invitacion-consulta">
                            Haga click para abrir la ventana de chat del agente.
                        </span>

                        <span class="agente-mensajes-no-leidos" *ngIf="agente.mensajes_no_leidos">
                            {{ agente.mensajes_no_leidos }}
                            <span class="agente-tooltip-mensajes-no-leidos">
                                Ud. tiene {{ agente.mensajes_no_leidos }} mensajes sin leer de {{ agente.nombre }} {{ agente.apellido }}.
                            </span>
                        </span>

                        <span class="agente-status online" *ngIf="agente.conectado"></span>
                    </figure>

                    <span class="nombre">{{ agente.nombre }} {{ agente.apellido }}</span>

                    <span class="rol" *ngIf="agente.registro">Martillero y Corredor Público Reg. {{ agente.registro }}</span>

                    <a title="email" class="email" href="mailto:{{agente.email}}">{{ agente.email }}</a>

                    <a *ngIf="agente.celular" href="https://api.whatsapp.com/send?phone=54{{agente.celular}}" target="_blank" class="whatsapp" (click)="openWhatsApp(agente)">
                        <img src="../../../assets/images/logo-whatsapp.png">Enviar WhatsApp
                    </a>
                </div>

            </ng-container>
        </div>

<!--
		<section class="inmuebles-similares">

            <app-inmuebles-ads #inmuebles_similares>
            </app-inmuebles-ads>

		</section>

        <section class="inmuebles-importantes">

            <app-inmuebles-ads #inmuebles_importantes>
            </app-inmuebles-ads>

        </section>
-->
	</div>

</div>

<gallery
  [pathDirThumbnails]="url_thumbnails_inmuebles"
  [pathDirImages]="url_images_inmuebles"
  [images]="imagenes"
  [show_thumbs]="true"
  [show_epigrafe]="true"
>
</gallery>
