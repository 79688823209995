import { Component, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, forkJoin } from 'rxjs';

// Components
import { CarouselImagenesComponent } from '../globals/carousel-imagenes/carousel-imagenes.component';

// Servicios
import { GLOBAL } from '../../services/global';
import { ScrollService } from '../../services/scroll.service';
import { ConfigService } from '../../services/config.service';
import { ChatService } from '../../services/chat.service';
import { InmobiliariasService } from '../../services/inmobiliarias.service';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
	selector: 'T001-nosotros',
	templateUrl: './nosotros.component.html',
	styleUrls: ['./nosotros.component.css', '../../../assets/css/agente.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ AnalyticsService ]
})

export class T001NosotrosComponent {
	
	public identity: any;
	public web: any;
	public inmobiliaria: any;
	public imagenes: Array<any>;
	public agentes: Array<any>;

	public url_web_inmobiliaria: string;	
	public url_images_agentes: string;

	public imagen_default_agente: string;
	public imagen_default_nosotros: string;

	private subscriptionChangeEstadoAgente: Subscription;
	private subscriptionChangeCantidadDeMensajesNoLeidos: Subscription;

	private observador_de_intersecciones_agentes: any;

	@ViewChild(CarouselImagenesComponent, { static: false }) carousel: CarouselImagenesComponent;

	constructor(private _router: Router,
				private _cdRef: ChangeDetectorRef,
                private _scrollService: ScrollService,
				private _configService: ConfigService,
				private _chatService: ChatService,
				private _inmobiliariasService: InmobiliariasService,
                private _analyticsService: AnalyticsService ) {

		this.identity = GLOBAL.identity;
		this.inmobiliaria = GLOBAL.inmobiliaria;
		this.web = GLOBAL.web;
		this.imagenes = [];
		this.agentes = [];

		this.url_web_inmobiliaria = GLOBAL.url_webs + GLOBAL.inmobiliaria.id + '/';
		this.url_images_agentes = GLOBAL.url_images_agentes;

		this.imagen_default_agente = this._configService.imagen_default_agente;
		this.imagen_default_nosotros = this._configService.imagen_default_nosotros;
		
		this.observador_de_intersecciones_agentes = null;
	}

	ngOnInit(){
		console.log('T001NosotrosComponent component cargado ... ');

		this.getDatosNosotros().subscribe(
			(response)=>{

				this.updateEstadoAgentes();
				this.updateMensajesNoLeidosPorAgente();

				this.setSubscripciones();
				
				this.irArribaDeLaPagina();
			},
			(error)=>{
				this._router.navigate(['/']);
			});
	}

	ngOnDestroy(){
    
    	this.subscriptionChangeEstadoAgente.unsubscribe();
    	this.subscriptionChangeCantidadDeMensajesNoLeidos.unsubscribe();

    	if ( this.observador_de_intersecciones_agentes )
			this.observador_de_intersecciones_agentes.disconnect();
    } 

	setSubscripciones(){

		// Me suscribo a cualquier cambio de estado de los agentes de la inmobiliaria
		this.subscriptionChangeEstadoAgente = this._chatService.changeEstadoAgente$.subscribe((estado)=>{
                                              
	                                              this.updateEstadoAgentes();
	                                            });

		this.subscriptionChangeCantidadDeMensajesNoLeidos = this._chatService.changeCantidadDeMensajesNoLeidos$.subscribe(()=>{
                                      
				                                              	this.updateMensajesNoLeidosPorAgente();
				                                            });
	}

	irArribaDeLaPagina(){
		
        this._scrollService.scrollTo({ y:0, duration: 0 });
	}

    getDatosNosotros(): Observable<any>{	

    	return new Observable((observer)=>{

			forkJoin([
				this._inmobiliariasService.getImagesBySeccion(this.inmobiliaria.id, 'nosotros'),
				this._inmobiliariasService.getAgentsByInmobiliaria(this.inmobiliaria.id)
            ]).subscribe((response)=>{
				//console.log(response);

				this.imagenes = response[0].imagenes;
				this.agentes = response[1].agentes;

				observer.next();
				observer.complete();
			},
			(error)=>{
				observer.error(error);
				observer.complete();
			});
		});
	}

    updateEstadoAgentes(){

	    this.agentes.forEach((agente)=>{
	      agente.conectado = this._chatService.isAgenteConectado(agente.id);
	    });

	    this._cdRef.detectChanges();
  	}  	

	updateMensajesNoLeidosPorAgente(){

	    this.agentes.forEach((agente)=>{
	      	agente.mensajes_no_leidos = this._chatService.getCantidadDeMensajesNoLeidosDelAgente(agente.id);
	    });

	    this._cdRef.detectChanges();

	    this.setObservadorDeInterseccionesMensajesNoLeidosDeAgentes();
  	}

  	setObservadorDeInterseccionesMensajesNoLeidosDeAgentes(){

		const options = {
		  root: null,
		  rootMargin: '0px',
		  threshold: [0.0, 0.5] // Se ejecuta el callback cuando no se vea, o cuando se vea la mitad del elemento
		}

		// Cuando llega a verse los agentes
		this.observador_de_intersecciones_agentes = new IntersectionObserver(this.toggleTooltipNuevoMensajesDeAgente, options);

		this.addObservablesAlObservador(this.observador_de_intersecciones_agentes, '.agente-tooltip-mensajes-no-leidos');
	}
	
	addObservablesAlObservador(observer, observable_select=null){

		if ( observable_select && typeof observable_select === 'string' ) {
			const targets = document.querySelectorAll(observable_select);

			targets.forEach((target) => {
				observer.observe(target);
			});
		}
	}

	toggleTooltipNuevoMensajesDeAgente(entries, observer) {

		entries.forEach((entry)=>{

			if ( entry.isIntersecting )
				entry.target.classList.add('showTooltipMensajesNoleidos');
			else
				entry.target.classList.remove('showTooltipMensajesNoleidos');
		});
	}

  	openChat(agente) {

		this._chatService.openChat(agente);

        this._analyticsService.sendEvento('abrir_chat_agente '+ agente.nombre +' '+ agente.apellido , 'comunicacion', 'El usuario abrió la ventana de chat del agente '+ agente.nombre +' '+ agente.apellido);
	}

    openWhatsApp(agente) {
		
        this._analyticsService.sendEvento('abrir_whatsapp_agente '+ agente.nombre +' '+ agente.apellido, 'comunicacion', 'El usuario abrió el whatsapp del agente '+ agente.nombre +' '+ agente.apellido);
	}
}