import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';

// Components
import { GalleryComponent } from '../globals/gallery/gallery.component';

// Servicios
import { GLOBAL } from '../../services/global';
import { ScrollService } from '../../services/scroll.service';
import { ConfigService } from '../../services/config.service';
import { ChatService } from '../../services/chat.service';
import { InmueblesService } from '../../services/inmuebles.service';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
	selector: 'T001-emprendimiento',
	templateUrl: './emprendimiento.component.html',
	styleUrls: [
		'./emprendimiento.component.css',
		'../../../assets/css/form.css',
		'../../../assets/css/agente.css'
	],
	providers: [ InmueblesService,  AnalyticsService ]
})

export class T001EmprendimientoComponent {

	public identity: any;
	public inmobiliaria: any;
    public web: any;
    public dominio: string;
	public id_inmueble: number;
	public inmueble: any;
	public agentes: Array<any>;
	public ambientes: Array<any>;
	public instalaciones: Array<any>;
	public servicios: Array<any>;
	public edificio: Array<any>;
	public imagenes: Array<any>;
    public imagenes_caratula: Array<any>;
    public unidades: Array<any>;

	public imagen_default_inmueble: string;
	public imagen_default_agente: string;

	public url_images_inmuebles: string;
	public url_thumbnails_inmuebles: string;
	public url_images_agentes: string;

	public checkboxCampos;

	public url_inmueble: string;

	public formConsulta: UntypedFormGroup;
	private consulta: any;
	public show_spinner: boolean;
	public show_mensaje: 'none' | 'success' | 'error' | string;

	private subscriptionChangeEstadoAgente: Subscription;
	private subscriptionChangeCantidadDeMensajesNoLeidos: Subscription;

	private observador_de_intersecciones_agentes: any;
    public campos: any;

	@ViewChild(GalleryComponent, { static: true }) gallery: GalleryComponent;

	constructor(
				private _cdRef: ChangeDetectorRef,
				private _fb: UntypedFormBuilder,
                private _recaptchaV3Service: ReCaptchaV3Service,
                private _scrollService: ScrollService,
				private _configService: ConfigService,
				private _chatService: ChatService,
				private _inmueblesService: InmueblesService,
                private _analyticsService: AnalyticsService ) {

		this.identity = GLOBAL.identity;
		this.inmobiliaria = GLOBAL.inmobiliaria;
		this.web = GLOBAL.web;
        this.dominio =  GLOBAL.dominio;
		this.id_inmueble = null;
		this.inmueble = {};
		this.agentes = [];

		this.ambientes = [];
		this.instalaciones = [];
		this.servicios = [];
		this.edificio = [];
		this.imagenes = [];
        this.imagenes_caratula = [];
        this.unidades = [];

		this.imagen_default_inmueble = this._configService.imagen_default_inmueble;
		this.imagen_default_agente = this._configService.imagen_default_agente;

		this.url_images_inmuebles = GLOBAL.url_images_inmuebles;
		this.url_thumbnails_inmuebles = GLOBAL.url_thumbnails_inmuebles;
		this.url_images_agentes = GLOBAL.url_images_agentes;

		this.checkboxCampos = new Map();

		this.url_inmueble = null;

		this.consulta = {};
		this.show_spinner = false;
		this.show_mensaje = 'none';

		this.observador_de_intersecciones_agentes = null;

        this.createFormConsulta();
	}

	ngOnInit(){
		console.log('T001EmprendimientoComponent component cargado ... ');
	
	}

	ngOnDestroy(){

        if ( this.subscriptionChangeEstadoAgente )
        	this.subscriptionChangeEstadoAgente.unsubscribe();

        if ( this.subscriptionChangeCantidadDeMensajesNoLeidos )
    	    this.subscriptionChangeCantidadDeMensajesNoLeidos.unsubscribe();

    	if ( this.observador_de_intersecciones_agentes )
			this.observador_de_intersecciones_agentes.disconnect();
    }

    setPropiedades(propiedades) {
        
        if ( !propiedades.inmueble ) return;

        this.campos = propiedades.campos;
        this.inmueble = propiedades.inmueble;
        this.imagenes = propiedades.imagenes;

        for(let i=0; i< this.imagenes.length; i++) {

            if ( i < 3)
                this.imagenes_caratula.push(this.imagenes[i]);
            else
                break;
        }
         
        this.agentes = propiedades.agentes;
        this.unidades = propiedades.unidades ? propiedades.unidades : [];

        this.setComponente();
    }

    setComponente() {

        this.setCheckboxCampos(this.campos);

        this.separarCheckboxsDelInmueble(this.inmueble);

        this.setURLInmueble();

        this.updateEstadoAgentes();
        this.updateMensajesNoLeidosPorAgente();

        this.setSubscripciones();

        this.irArribaDeLaPagina();
    }

    setURLInmueble() {

        if ( !this.dominio ) return;

        this.url_inmueble = 'https://www.' + this.dominio + '/inmueble/'+ this.inmueble.id;
    }

    setSubscripciones(){

		// Me suscribo a cualquier cambio de estado de los agentes de la inmobiliaria
		this.subscriptionChangeEstadoAgente = this._chatService.changeEstadoAgente$.subscribe((estado)=>{

	                                              this.updateEstadoAgentes();
	                                            });

		this.subscriptionChangeCantidadDeMensajesNoLeidos = this._chatService.changeCantidadDeMensajesNoLeidos$.subscribe(()=>{

				                                              	this.updateMensajesNoLeidosPorAgente();
				                                            });
	}

	irArribaDeLaPagina(){
		
        this._scrollService.scrollTo({ y:0, duration: 0 });
	}

    

	setCheckboxCampos(campos=null){

		for(let i=0; i < campos.length; i++) {
			this.checkboxCampos.set(campos[i].campo, campos[i].nombre);
		}
	}

	separarCheckboxsDelInmueble(inmueble=null){

		for( const prop in inmueble ) {

			if ( prop.startsWith('amb_') && inmueble[prop] )
				this.ambientes.push({ key: prop, value: inmueble[prop] });

			if ( prop.startsWith('ins_') && inmueble[prop] )
				this.instalaciones.push({ key: prop, value: inmueble[prop] });

			if ( prop.startsWith('ser_') && inmueble[prop] )
				this.servicios.push({ key: prop, value: inmueble[prop] });

			if ( prop.startsWith('edi_') && inmueble[prop] )
				this.edificio.push({ key: prop, value: inmueble[prop] });
		}
	}

    updateEstadoAgentes(){

	    this.agentes.forEach((agente)=>{
	      agente.conectado = this._chatService.isAgenteConectado(agente.id);
	    });

	    this._cdRef.detectChanges();
  	}

	createFormConsulta(){
		this.formConsulta = this._fb.group({
			nombre: [null, Validators.required],
			email: [null, [ Validators.required, Validators.email] ],
			celular: null,
			mensaje: [null, Validators.required]
		});
	}

    deleteEspaciosVacios(event){

        if ( event.key == " " || event.keyCode == 32 || event.code == "Space" ) {
            
            event.preventDefault();
            return;
        }
    }

	validateConsulta(){

		const errores = [];

		//console.log( this.formConsulta );

		if ( this.formConsulta.invalid ) {
			// controls es un objeto donde cada nombre de propiedad corresponde
			// al nombre del formControl

			for(const name_control in this.formConsulta.controls){

				if ( this.formConsulta.controls[name_control].status == "INVALID"){

					//errores.push(GLOBAL.descriptionFields[name_control]);
					errores.push(name_control);

					// Lo marcamos como touched, para que los controles
					// se pongan con el estilo error
					this.formConsulta.controls[name_control].markAsTouched();
				}
			}
		}

		if ( !errores.length ) {

            this.show_spinner = true;

            this._recaptchaV3Service.execute('importantAction')
            .subscribe((token: string) => {

                // Si obtenemos el token significa que el bot no hace clic en el botón y es un usuario válido.
                if ( token )
                    this.enviarConsulta();
            });
        }
	}

	enviarConsulta(){

		this.consulta = {
			id_inmobiliaria : this.inmobiliaria.id,
			id_inmueble: this.inmueble.id,
			href: this.url_inmueble,
			origen: GLOBAL.IS_WEB ? this.dominio : 'App movil inmobiliaria',
			asunto: "Consulta por el inmueble código "+this.inmueble.id,
			nombre: this.formConsulta.get('nombre').value,
			email: this.formConsulta.get('email').value,
			celular: this.formConsulta.get('celular').value,
			mensaje: this.formConsulta.get('mensaje').value
		};

		this._inmueblesService.sendConsultaInmueble(this.inmueble.id, this.consulta).subscribe(
			(response)=>{
				this.limpiarFormConsulta();
				this.showMensaje('success');
				this.show_spinner = false;

                this._analyticsService.sendEvento('enviar_consulta_inmueble', 'comunicacion', 'El usuario envió una consulta por el inmueble '+ this.inmueble.id);
			},
			(error)=>{
				console.log(error);
				this.showMensaje('error');
				this.show_spinner = false;
			});
	}

	limpiarFormConsulta(){

		this.formConsulta.reset();
	}

	showMensaje(valor='none') {

		this.show_mensaje = valor;
	}

	compartirInmueble(red_social=null){

	    let url, url_and_title = "";
	    let window_size = "width=585,height=511";

	    switch(red_social) {
	        case "facebook":
	        	url = "https://www.facebook.com/sharer.php?u="
	        	url += this.url_inmueble;
	        	url_and_title = "&t=";
	            window_size = "width=600,height=368";
	            break;
	        case "twitter":
	        	url = "https://twitter.com/intent/tweet?url="
	        	url += this.url_inmueble;
	        	url_and_title = "&text=";
	            window_size = "width=800,height=300";
	            break;
	        case "pinterest":
	        	url = "https://pinterest.com/pin/create/button/?url=";

	        	url += this.url_inmueble;

	        	if ( this.inmueble.imagen_principal )
	        	   	url += "&media="+ this.url_images_inmuebles + this.inmueble.imagen_principal;

	        	url += "&description="+this.inmueble.titulo+" - "+this.inmueble.descripcion.substring(0, 449);

	        	window_size = "width=585,height=261";
	        	break;
	        case "whatsapp":
	        	url = "whatsapp://send?text=";
	        	url += this.inmueble.titulo;
	        	url += " " + this.url_inmueble;
	        	url += "?utm_source=whatsapp"; // Esto sirve para saber cuantas visitas recibimos por alguien que compartio el enlace en google analytics
	        	break;
	        default:
	        	return false;
	        	break;
	    }

	    if ( url_and_title.length > 0 )
		    url += url_and_title + this.inmueble.titulo;

	    window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,status=yes' + window_size);

        this._analyticsService.sendEvento('compartir_inmueble '+ red_social, 'redes_sociales', `El usuario compartió el inmueble `+this.inmueble.id+` por `+ red_social );

	    return false;
	}

	updateMensajesNoLeidosPorAgente(){

	    this.agentes.forEach((agente)=>{
	      	agente.mensajes_no_leidos = this._chatService.getCantidadDeMensajesNoLeidosDelAgente(agente.id);
	    });

	    this._cdRef.detectChanges();

	    this.setObservadorDeInterseccionesMensajesNoLeidosDeAgentes();
  	}

  	setObservadorDeInterseccionesMensajesNoLeidosDeAgentes(){

		const options = {
		  root: null,
		  rootMargin: '0px',
		  threshold: [0.0, 0.5] // Se ejecuta el callback cuando no se vea, o cuando se vea la mitad del elemento
		}

		// Cuando llega a verse los agentes
		this.observador_de_intersecciones_agentes = new IntersectionObserver(this.toggleTooltipNuevoMensajesDeAgente, options);

		this.addObservablesAlObservador(this.observador_de_intersecciones_agentes, '.agente-tooltip-mensajes-no-leidos');
	}

	addObservablesAlObservador(observer, observable_select=null){

		if ( observable_select && typeof observable_select === 'string' ) {
			const targets = document.querySelectorAll(observable_select);

			targets.forEach((target) => {
				observer.observe(target);
			});
		}
	}

	toggleTooltipNuevoMensajesDeAgente(entries, observer) {

		entries.forEach((entry)=>{

			if ( entry.isIntersecting )
				entry.target.classList.add('showTooltipMensajesNoleidos');
			else
				entry.target.classList.remove('showTooltipMensajesNoleidos');
		});
	}

  	openChat(agente) {

        this._chatService.openChat(agente);

        this._analyticsService.sendEvento('abrir_chat_agente '+ agente.nombre +' '+ agente.apellido , 'comunicacion', 'El usuario abrió la ventana de chat del agente '+ agente.nombre +' '+ agente.apellido);
    }

    openWhatsApp(agente) {
        
        this._analyticsService.sendEvento('abrir_whatsapp_agente '+ agente.nombre +' '+ agente.apellido, 'comunicacion', 'El usuario abrió el whatsapp del agente '+ agente.nombre +' '+ agente.apellido);
    }

    openRedSocialAgente(agente, red_social=null) {

        if ( ! red_social ) return;

        this._analyticsService.sendEvento('ver_red_social_agente '+ agente.nombre +' '+ agente.apellido + ' '+ red_social, 'redes_sociales', 'El usuario hizo clic en un link de la red social '+ red_social +' del agente '+ agente.nombre +' '+ agente.apellido);
    }

	showGallery(event){

		if ( this.imagenes.length === 0 ) return;

		this.gallery.setSelectedImage(event.index);
		this.gallery.show();
	}
}
