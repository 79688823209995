import { Component, Input } from '@angular/core';
import QRCode from 'qrcode';


@Component({
  selector: 'codigo-qr',
  templateUrl: './codigo-qr.component.html',
  styleUrls: ['./codigo-qr.component.css']
})

export class CodigoQRComponent {

	@Input() texto = '';
	@Input() width = 256;
	@Input() colordark = '#000000';
  	@Input() colorlight = '#ffffff';

	constructor(){
	}

	ngOnChanges(changes) {
    
		this.crearCodigoQR();
	}

	crearCodigoQR() {

		try {
			const elem = document.getElementsByClassName('codigo-qr-component')[0];

			if ( ! elem )
				throw new Error('No se encontro el elemento canvas en el DOM.');

			if ( ! this.isValidoElTextoACodificar(this.texto) )
				throw new Error('El texto a codificar esta vacio o no es valido.');

			const options = {
				width: this.width,
				color: {
					dark: this.colordark,
					light: this.colorlight
				}
			};

			QRCode.toCanvas(elem, this.texto, options, (error, elemento)=>{

				if ( error )
					throw new Error(error);

				//console.log('elemento canvas del DOM: ', elemento);
			});
		} catch (e) {
	      	console.error('Error en el componente codigo-qr: ' + e.message);
	    }
	}

	isValidoElTextoACodificar(value) {

		return !(typeof value === 'undefined' || value === '');
	}
}