import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from  '@angular/common/http';
import { Observable } from 'rxjs';


import { GLOBAL } from './global';


@Injectable({
  providedIn: 'root',
})

export class InmobiliariasService {
	public url: string;

	constructor(private _http: HttpClient){
		this.url = GLOBAL.url_back_end + 'inmobiliarias/';
	}

	getInmobiliaria(id=null): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+id, {headers: headers});
	}

	getAgentsByInmobiliaria(id=null): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+id+'/agentes', {headers: headers});
	}

	getDatosDeLaInmobiliariaYSuWebByDominio(dominio=null): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'web/dominio/'+dominio, {headers: headers});
	}

	getWebByIdInmobiliaria(id_inmobiliaria=null): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+id_inmobiliaria+'/web', {headers: headers});
	}

	getImagesBySeccion(id_inmobiliaria=null, seccion=null, cantidad:any = ''): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+id_inmobiliaria+'/web/seccion/'+seccion+'/imagenes/'+cantidad, {headers: headers});
	}

	getServiciosByInmobiliaria(id_inmobiliaria=null, cantidad:any = ''): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+id_inmobiliaria+'/web/servicios/'+cantidad, {headers: headers});
	}

	sendConsultaInmobiliaria(id_inmobiliaria=null, consulta=null): Observable<any> {

		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+id_inmobiliaria+'/enviar-consulta', consulta, {headers: headers});
	}
}