import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// Servicios
import { GLOBAL } from '../../services/global';
import { ScrollService } from '../../services/scroll.service';
import { ConfigService } from '../../services/config.service';
import { InmobiliariasService } from '../../services/inmobiliarias.service';


@Component({
	selector: 'T001-servicios',
	templateUrl: './servicios.component.html',
	styleUrls: ['./servicios.component.css']
})

export class T001ServiciosComponent {
	
	public identity: any;
	public web: any;
	public inmobiliaria: any;
	public servicios: Array<any>;

	public url_web_inmobiliaria: string;
	public imagen_default_servicios: string;

	constructor(private _router: Router,
                private _scrollService: ScrollService,
				private _configService: ConfigService,
				private _inmobiliariasService: InmobiliariasService) {

		this.identity = GLOBAL.identity;
		this.inmobiliaria = GLOBAL.inmobiliaria;
		this.web = GLOBAL.web;
		this.servicios = [];
		
		this.url_web_inmobiliaria =  GLOBAL.url_webs + GLOBAL.inmobiliaria.id + '/';
		this.imagen_default_servicios = this._configService.imagen_default_servicios;
	}

	ngOnInit(){
		
		console.log('T001ServiciosComponent component cargado ... ');

		this.getDatosServicios();
		this.irArribaDeLaPagina();
	}

	irArribaDeLaPagina(){
    
        this._scrollService.scrollTo({ y:0, duration: 0 });
	}

	getDatosServicios(){
		
		this._inmobiliariasService.getServiciosByInmobiliaria(this.inmobiliaria.id).subscribe(
			(response)=>{
				//console.log(response);

				this.servicios = response.servicios;
			},
			(error)=>{
				this._router.navigate(['/']);
			});
	}

	irPaginaContactos(){
		this._router.navigate(['/contactos']);
	}
}