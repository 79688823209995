<header>
	<div class="container encabezado">

		<div class="logo">
			<img src="{{ url_web_inmobiliaria + web.logotipo }}" alt="{{inmobiliaria.nombre}}" [style.background]="web.logotipo_background ? web.logotipo_background_color : 'transparent'">
		</div>

		<ul class="menu">
			<li>
				<div class="boton-menu" (click)="toggleMobileMenu()">
					<span></span>
					<span></span>
					<span></span>
				</div>

				<nav id="nav" class="nav">
					<ul>
						<li>
							<a [routerLink]="['/']"
							 [routerLinkActive]="['selected']" [routerLinkActiveOptions]="{exact: true}" (click)="toggleMobileMenu()">Inicio</a>
						</li>
						<li *ngIf="web.nosotros">
							<a [routerLink]="['/nosotros']"
							 [routerLinkActive]="['selected']" [routerLinkActiveOptions]="{exact: true}" (click)="toggleMobileMenu()">{{ web.nosotros_nombre }}</a>
						</li>
						<li>
							<a [routerLink]="['/buscador']"
							 [routerLinkActive]="['selected']" (click)="toggleMobileMenu()">Buscador</a>
						</li>
                        <li *ngIf="web.emprendimientos">
							<a [routerLink]="['/emprendimientos']"
							 [routerLinkActive]="['selected']" (click)="toggleMobileMenu()">{{ web.emprendimientos_nombre }}</a>
						</li>
						<li>
							<a class="hidden"
							 [routerLink]="['/inmueble']"
							 [routerLinkActive]="['selected', 'show']" (click)="toggleMobileMenu()">Inmueble</a>
						</li>
						<li *ngIf="web.servicios">
							<a [routerLink]="['/servicios']"
							 [routerLinkActive]="['selected']" [routerLinkActiveOptions]="{exact: true}" (click)="toggleMobileMenu()">{{ web.servicios_nombre }}</a>
						</li>
						<li>
							<a [routerLink]="['/contactos']"
							 [routerLinkActive]="['selected']" [routerLinkActiveOptions]="{exact: true}" (click)="toggleMobileMenu()">Contacto</a>
						</li>
					</ul>
				</nav>
			</li>
		</ul>

	</div>

</header>

<router-outlet></router-outlet>

<!--
<app-chat-management #chats></app-chat-management>
-->

<footer>

	<div class="container footer">

		<div class="footer-column">
			<div class="logotipo">
				<img src="{{ url_web_inmobiliaria + web.logotipo }}" alt="{{inmobiliaria.nombre}}" [style.background]="web.logotipo_background ? web.logotipo_background_color : 'transparent'">
			</div>
		</div>

		<div class="footer-column">
			<h3 class="title">Mapa del sitio</h3>

			<div class="map-site">

				<ul>
					<li><i class="mdi mdi-menu-right"></i><a [routerLink]="['/']">Inicio</a></li>
					<li *ngIf="web.nosotros"><i class="mdi mdi-menu-right"></i><a [routerLink]="['/nosotros']">{{ web.nosotros_nombre }}</a></li>
					<li><i class="mdi mdi-menu-right"></i><a [routerLink]="['/buscador']">Buscador</a></li>
                    <li *ngIf="web.emprendimientos" ><i class="mdi mdi-menu-right"></i><a [routerLink]="['/emprendimientos']">{{ web.emprendimientos_nombre }}</a></li>
					<li *ngIf="web.servicios" ><i class="mdi mdi-menu-right"></i><a [routerLink]="['/servicios']">{{ web.servicios_nombre }}</a></li>
					<li><i class="mdi mdi-menu-right"></i><a [routerLink]="['/contactos']">Contacto</a></li>
				</ul>

			</div>
		</div>

		<div class="footer-column">
			<h3 class="title">Contactos</h3>

			<div class="contact-site">

				<ul>
					<li *ngIf="inmobiliaria.calle">
						<i class="mdi mdi-map-marker"></i>
						{{ inmobiliaria.calle }} {{ inmobiliaria.numero }} {{ inmobiliaria.piso }} {{ inmobiliaria.oficina }}
					</li>
					<li *ngIf="inmobiliaria.telefonos">
                        <a href="tel:+54{{ inmobiliaria.telefonos }}">
                            <i class="mdi mdi-phone"></i>
                            {{ inmobiliaria.telefonos }}
                        </a>
					</li>
					<li *ngIf="inmobiliaria.celulares">
                        <a href="tel:+54{{ inmobiliaria.celulares }}">
                            <i class="mdi mdi-cellphone"></i>
                            {{ inmobiliaria.celulares }}
                        </a>
					</li>
					<li>
                        <a href="mailto:{{ inmobiliaria.email }}">
                            <i class="mdi mdi-email"></i>
                            {{ inmobiliaria.email }}
                        </a>
					</li>
                    <li *ngIf="inmobiliaria.perfil_facebook">
                        <a href="https://www.facebook.com/{{inmobiliaria.perfil_facebook}}" target="_blank" (click)="openRedSocialInmobiliaria('facebook')">
						    <i class="mdi mdi-facebook"></i>
                            {{ inmobiliaria.perfil_facebook }}
                        </a>
					</li>
                    <li *ngIf="inmobiliaria.perfil_instagram">
                        <a href="https://www.instagram.com/{{inmobiliaria.perfil_instagram}}" target="_blank" (click)="openRedSocialInmobiliaria('instagram')">
                            <i class="mdi mdi-instagram"></i>
                            {{ inmobiliaria.perfil_instagram }}
                        </a>
					</li>
				</ul>

			</div>
		</div>

		<div class="footer-bottom">
			<div class="izq">
				<p class="copyright">{{ inmobiliaria.nombre }} <span *ngIf="inmobiliaria.registro">Reg. {{ inmobiliaria.registro }}</span></p>
			</div>

            <div *ngIf="web.data_fiscal" [innerHTML]="web.data_fiscal" class="data_fiscal"></div>

			<div class="der">
				<p>Desarrollado por <a href="https://www.mardelinmueble.com" target="_blank">mardelinmueble.com</a></p>
			</div>
		</div>

	</div>

</footer>

<!-- SECCION FIJA DEL BOTON DEL WHATSAPP -->
<app-button-whatsapp
    [visible]="web.boton_whatsapp && inmobiliaria.whatsapp"
    [position]="web.boton_whatsapp_position"
    [number]="inmobiliaria.whatsapp"
    [tooltip]="web.boton_whatsapp_tooltip"
    (eventClic)="openWhatsAppInmobiliaria()"
>
</app-button-whatsapp>