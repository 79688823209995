<!-- Componente slider-imagenes -->
<div class="slider">

    <!-- Slider Main -->
    <div class="slider-inner">

        <!-- Slide -->
        <div class="slide" *ngFor="let imagen of imagenes; index as i" id="slide_{{i+1}}">
            <div class="slide-bg">
                <img src="{{ url_imagenes + imagen.nombre }}">
            </div>

            <div class="slide-inner" *ngIf="imagen.texto">
                <div class="slide-texto animated">
                    {{ imagen.texto }}
                </div>
            </div>
        </div>

    </div>

    <div class="footer" *ngIf="show_buttons_nav && ( slidesCount > 1 ) || show_indicators ">
        
        <button *ngIf="show_buttons_nav && ( slidesCount > 1 )" class="button previous" (click)="previous()">
            <i class="mdi mdi-chevron-left"></i>
        </button>

        <ol class="slider-indicators" *ngIf="show_indicators && imagenes.length > 1">
            <li *ngFor="let indicador of imagenes; index as i" [ngClass]="{ active: i+1 == currentSlide }">
            </li>
        </ol>

        <button *ngIf="show_buttons_nav && ( slidesCount > 1 )" class="button next" (click)="next()">
            <i class="mdi mdi-chevron-right"></i>
        </button>
        
    </div>

</div>
