<section class="section">

    <div class="container-full emprendimiento-portada">

        <div class="emprendimiento-slider">

            <ng-template [ngIf]="inmueble.imagen_principal" [ngIfElse]="imagenInmuebleDefault">
                <app-slider-imagenes
                    [url_imagenes]="url_images_inmuebles"
                    [imagenes]="imagenes_caratula"
                    [show_buttons_nav]="false"
                    [show_indicators]="false"
                ></app-slider-imagenes>
            </ng-template>

            <ng-template #imagenInmuebleDefault>
                <img src="{{ imagen_default_inmueble }}">
            </ng-template>

            <div class="titulo">
                {{ inmueble.titulo }}
            </div>

        </div>

        <ng-template [ngIf]="inmueble.estado_construccion === 'Terminado'" [ngIfElse]="showFechaEntrega">
            <div class="fecha-entrega">
                Entrega <br>
                inmediata
            </div>
        </ng-template>

        <ng-template #showFechaEntrega>
            <div class="fecha-entrega" *ngIf="inmueble.f_fecha_entrega">
                Entrega <br> {{inmueble.f_fecha_entrega}}
            </div>
        </ng-template>

    </div>

</section>

<section class="section">
    <div class="container">
        <div class="emprendimiento-top">
            <div class="codigo-qr">
                <codigo-qr *ngIf="url_inmueble"
                    [texto]="url_inmueble"
                    [width]="100"
                >
                </codigo-qr>
            </div>

            <!--
            <span class="codigo">Código: {{ inmueble.id }}</span>
            -->

            <div class="compartir-redes-sociales" *ngIf="url_inmueble">

                <a (click)="compartirInmueble('facebook')" class="compartir facebook" title="Compartir en Facebook">
                    <i class="mdi mdi-facebook"></i>
                </a>

                <a (click)="compartirInmueble('twitter')" class="compartir twitter" title="Compartir en Twitter">
                    <i class="mdi mdi-twitter"></i>
                </a>

                <a (click)="compartirInmueble('pinterest')" class="compartir pinterest" title="Compartir en Pinterest">
                    <i class="mdi mdi-pinterest"></i>
                </a>

                <a (click)="compartirInmueble('whatsapp')" class="compartir whatsapp" title="Compartir en WhatsApp">
                    <i class="mdi mdi-whatsapp"></i>
                </a>

            </div>
        </div>

    </div>
</section>

<section class="section">

    <div class="container emprendimiento">

        <div class="emprendimiento-informacion">

            <div class="texto">
                <h2 class="subtitulo"><span>Información</span></h2>

                <p [innerHTML]="inmueble.descripcion_resumida"></p>
            </div>

            <div class="iconos">

                <div class="icono" *ngIf="inmueble.construye">
                    <span><i class="mdi mdi-crane"></i></span>
                    <span class="valor">{{ inmueble.construye }}</span>
                    <span class="campo">Construye</span>
                </div>

                <div class="icono" *ngIf="inmueble.estado_construccion">
                    <span><i class="mdi mdi-chart-bell-curve-cumulative"></i></span>
                    <span class="valor">{{ inmueble.estado_construccion }}</span>
                    <span class="campo">Estado</span>
                </div>

                <div class="icono" *ngIf="inmueble.f_fecha_entrega">
                    <i class="mdi mdi-calendar-month"></i>
                    <span class="valor">{{ inmueble.f_fecha_entrega }}</span>
                    <span class="campo">Entrega</span>
                </div>

                <div class="icono" *ngIf="inmueble.pisos">
                    <i class="mdi mdi-stairs"></i>
                    <span class="valor">{{ inmueble.pisos }}</span>
                    <span class="campo">Pisos</span>
                </div>

                <div class="icono" *ngIf="inmueble.departamentos_por_piso">
                    <i class="mdi mdi-office-building-outline"></i>
                    <span class="valor">{{ inmueble.departamentos_por_piso }}</span>
                    <span class="campo">Deptos. por piso</span>
                </div>

                <div class="icono" *ngIf="inmueble.departamentos">
                    <i class="mdi mdi-floor-plan"></i>
                    <span class="valor">{{ inmueble.departamentos }}</span>
                    <span class="campo">Departamentos</span>
                </div>

                <div class="icono" *ngIf="inmueble.ascensores">
                    <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="-41 0 512 512">
                        <path d="m410 0h-390c-11.03125 0-20 8.96875-20 20v472c0 11.03125 8.96875 20 20 20h390c11.03125 0 20-8.96875 20-20v-472c0-11.03125-8.96875-20-20-20zm-160.730469 492h-79.269531v-183c0-5.519531-4.480469-10-10-10s-10 4.480469-10 10v183h-80.730469v-332h80.730469v70c0 5.519531 4.480469 10 10 10s10-4.480469 10-10v-70h79.269531zm160.730469 0h-140.730469v-342c0-5.519531-4.480469-10-10-10h-200c-5.53125 0-10 4.480469-10 10v342h-29.269531v-472h390zm0 0"></path>
                        <path d="m260 50h-200c-5.519531 0-10 4.480469-10 10v40c0 5.519531 4.480469 10 10 10h200c5.519531 0 10-4.480469 10-10v-40c0-5.519531-4.480469-10-10-10zm-110 40h-80v-20h80zm100 0h-80v-20h80zm0 0"></path>
                        <path d="m369 311h-57.734375c-3.574219 0-6.875-1.90625-8.660156-5-1.789063-3.09375-1.789063-6.90625 0-10l28.867187-50c1.785156-3.09375 5.085938-5 8.660156-5 3.570313 0 6.875 1.90625 8.660157 5l28.867187 50c1.785156 3.09375 1.785156 6.90625 0 10s-5.085937 5-8.660156 5zm-40.414062-20h23.09375l-11.546876-20zm0 0"></path>
                        <path d="m340.132812 411c-3.574218 0-6.875-1.90625-8.660156-5l-28.867187-50c-1.789063-3.09375-1.789063-6.90625 0-10 1.785156-3.09375 5.085937-5 8.660156-5h57.734375c3.574219 0 6.875 1.90625 8.660156 5s1.785156 6.90625 0 10l-28.867187 50c-1.785157 3.09375-5.089844 5-8.660157 5zm-11.546874-50 11.546874 20 11.546876-20zm0 0"></path>
                        <path d="m160.019531 279c-5.523437 0-10.003906-4.476562-10.003906-10s4.472656-10 9.996094-10h.007812c5.523438 0 10 4.476562 10 10s-4.476562 10-10 10zm0 0"></path>
                    </svg>
                    <span class="valor">{{ inmueble.ascensores }}</span>
                    <span class="campo">Ascensores</span>
                </div>

                <div class="icono" *ngIf="inmueble.oficinas">
                    <i class="mdi mdi-door-open"></i>
                    <span class="valor">{{ inmueble.oficinas }}</span>
                    <span class="campo">Oficinas</span>
                </div>

                <div class="icono" *ngIf="inmueble.locales">
                    <i class="mdi mdi-storefront-outline"></i>
                    <span class="valor">{{ inmueble.locales }}</span>
                    <span class="campo">Locales</span>
                </div>

            </div>

        </div>

        <div class="emprendimiento-caracteristicas" *ngIf="edificio.length > 0">

            <h2 class="subtitulo"><span>Características</span></h2>

            <div class="checks">
                <ng-container *ngFor="let prop of edificio">

                    <span>
                        <i class="mdi mdi-check icon"></i> {{ checkboxCampos.get(prop.key) }}
                    </span>

                </ng-container>
            </div>

        </div>

        <div class="emprendimiento-descripcion">

            <h2 class="subtitulo"><span>Descripción</span></h2>

            <p [innerHTML]="inmueble.descripcion"></p>
        </div>

        <div class="emprendimiento-unidades" *ngIf="unidades && unidades.length > 0">

            <h2 class="subtitulo">Unidades</h2>

            <div class="unidades">

                <div class="unidad" *ngFor="let unidad of unidades">

                    <div class="item" *ngIf="unidad.sup_total">
                        <span><i class="mdi mdi-vector-difference-ba"></i></span>
                        <span class="valor">{{ unidad.sup_total }}</span>
                        <span>Sup.Total</span>
                    </div>

                    <div class="item" *ngIf="unidad.ambientes">
                        <span><i class="mdi mdi-floor-plan"></i></span>
                        <span class="valor">{{ unidad.ambientes }}</span>
                        <span>Ambientes</span>
                    </div>

                    <div class="item" *ngIf="unidad.dormitorios">
                        <span><i class="mdi mdi-bed"></i></span>
                        <span class="valor">{{ unidad.dormitorios }}</span>
                        <span>Dormitorios</span>
                    </div>

                    <div class="item" *ngIf="unidad.banos">
                        <span><i class="mdi mdi-shower"></i></span>
                        <span class="valor">{{ unidad.banos }}</span>
                        <span>Baños</span>
                    </div>

                    <div class="item" *ngIf="unidad.cocheras">
                        <span><i class="mdi mdi-car"></i></span>
                        <span class="valor">{{ unidad.cocheras }}</span>
                        <span>Cocheras</span>
                    </div>

                    <div class="item" *ngIf="unidad.precio">
                        <span><i class="mdi mdi-cash-usd-outline"></i></span>
                        <span class="valor">{{ unidad.precio }}</span>
                        <span>Valor</span>
                    </div>

                </div>

            </div>
        </div>

    </div>

</section>

<section class="section section-inmueble-padding-top" *ngIf="imagenes.length > 0">

    <div class="container">

        <div class="emprendimiento-imagenes">
            <h2 class="titulo">Imágenes</h2>

            <div class="wrap-carousel">
                <app-carousel-imagenes
                    [pathDirImages]="url_images_inmuebles"
                    [images]="imagenes"
                    [width]="'100%'"
                    [height]="'100%'"
                    [show_zoom_button]="true"
                    (onZoomImagen)="showGallery($event)"
                ></app-carousel-imagenes>
            </div>
        </div>

    </div>

</section>

<section class="section section-inmueble-padding-top" *ngIf="inmueble.url_video">

    <div class="container">

        <div class="emprendimiento-video">
            <h2 class="titulo">Video</h2>

            <div class="wrap-video">
                    
                <iframe class="reproductor-video" [src]="inmueble.url_video | urlSegura">
                </iframe>

            </div>

        </div>

    </div>

</section>

<section class="section section-inmueble-padding-top">

    <div class="container">

        <div class="emprendimiento-ubicacion">

            <div class="datos">
                <h2 class="titulo">Ubicación</h2>
                <span class="direccion"> {{ inmueble.calle }} <ng-container *ngIf="inmueble.numero"> {{ inmueble.numero }} </ng-container> 
                <ng-container *ngIf="inmueble.id_provincia != inmobiliaria.id_provincia"> {{ inmueble.provincia }} </ng-container>
                <ng-container *ngIf="inmueble.id_ciudad != inmobiliaria.id_ciudad"> {{ inmueble.ciudad }} </ng-container>
                </span>
            </div>

            <div class="mapa">
                <app-mapa-inmuebles #mapainmuebles
                    [marker]="inmueble">
                </app-mapa-inmuebles>
            </div>            

        </div>

    </div>

</section>

<section class="section section-inmueble-padding-top">

    <div class="container">

        <div class="emprendimiento-consulta" *ngIf="inmobiliaria">

            <h2 class="titulo">Consultas</h2>

            <div class="contenido">
                <div class="contactos">
                    <div class="contacto" *ngIf="inmobiliaria.calle">
                        <i class="mdi mdi-map-marker"></i>
                        <span class="valor">{{ inmobiliaria.calle }} {{ inmobiliaria.numero }} {{ inmobiliaria.piso }} {{ inmobiliaria.oficina }}</span>
                        <span>Dirección</span>
                    </div>

                    <div class="contacto" *ngIf="inmobiliaria.telefonos">
                        <i class="mdi mdi-phone"></i>
                        <a class="valor" href="tel:+54{{ inmobiliaria.telefonos }}">
                            {{ inmobiliaria.telefonos }}
                        </a>
                        <span>Teléfonos</span>
                    </div>
                    <div class="contacto" *ngIf="inmobiliaria.celulares">
                        <i class="mdi mdi-cellphone"></i>
                        <a class="valor" href="tel:+54{{ inmobiliaria.telefonos }}">
                            {{ inmobiliaria.celulares }}
                        </a>
                        <span>Celulares</span>
                    </div>

                    <div class="contacto">
                        <i class="mdi mdi-email"></i>
                        <a class="valor" href="mailto:{{ inmobiliaria.email }}">
                            {{ inmobiliaria.email }}
                        </a>
                        <span>Correo</span>
                    </div>
                </div>

                <form [formGroup]="formConsulta" class="form-consulta">

                    <div class="form-group">
                        <input type="text" formControlName="nombre" placeholder="Nombre" class="form-control">
                        <small *ngIf="formConsulta.get('nombre').invalid && (formConsulta.get('nombre').dirty || formConsulta.get('nombre').touched)" class="form-control-feedback error">
                            Por favor, ingrese su nombre.
                        </small>
                    </div>
                    <div class="form-group">
                        <input type="text" formControlName="email" placeholder="Email" class="form-control" (keydown)="deleteEspaciosVacios($event)">
                        <ng-container *ngIf="(formConsulta.get('email').dirty || formConsulta.get('email').touched)">
                            <small *ngIf="formConsulta.get('email').errors?.required" class="form-control-feedback error">
                                Por favor, ingrese su correo electrónico.
                            </small>
                            <small *ngIf="formConsulta.get('email').errors?.email" class="form-control-feedback error">
                                El campo correo electrónico no es valido.
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <input type="text" formControlName="celular" class="form-control" placeholder="Num. celular">
                    </div>
                    <div class="form-group">
                        <textarea class="form-control form-textarea" formControlName="mensaje" placeholder="Consulta"></textarea>
                        <small *ngIf="formConsulta.get('mensaje').invalid && (formConsulta.get('mensaje').dirty || formConsulta.get('mensaje').touched)" class="form-control-feedback error">
                            Por favor, ingrese su consulta.
                        </small>
                    </div>

                    <button type="button" class="form-button" (click)="validateConsulta()">Enviar consulta</button>
                </form>

                <div class="mensaje color-success" *ngIf="show_mensaje === 'success'">
                    <i class="mdi mdi-check-circle-outline"></i>
                    La consulta se ha enviado correctamente.
                </div>

                <div class="mensaje color-error" *ngIf="show_mensaje === 'error'">
                    <i class="mdi mdi-close-circle-outline"></i>
                    Ocurrió un error al intentar enviar la consulta.
                </div>

                <app-spinner *ngIf="show_spinner" [titulo]="'Enviando ..'"></app-spinner>
            </div>
        </div>
    </div>
</section>

<section class="section section-inmueble-padding-top section-padding-bottom" *ngIf="web.staff_inmuebles">
    <div class="container">
        <div class="emprendimiento-agentes" *ngIf="agentes">

            <h2 class="titulo">{{ web.staff_titulo }}</h2>
            <p class="texto">Información de los agentes que responden al inmueble. Si algún agente se encuentra conectado, puede hacer su consulta de manera online.</p>

            <div class="contenido">
                <ng-container *ngFor="let agente of agentes">

                    <div class="agente">

                        <figure (click)="openChat(agente)">

                            <ng-template [ngIf]="agente.imagen" [ngIfElse]="imagenAgenteDefault">
                                <img src="{{ url_images_agentes + agente.imagen }}" alt="Agente inmobiliario">
                            </ng-template>

                            <ng-template #imagenAgenteDefault>
                                <img src="{{ imagen_default_agente }}" alt="Agente inmobiliario">
                            </ng-template>

                            <span class="agente-tooltip-invitacion-consulta">
                                Haga click para abrir la ventana de chat del agente.
                            </span>

                            <span class="agente-mensajes-no-leidos" *ngIf="agente.mensajes_no_leidos">
                                {{ agente.mensajes_no_leidos }}
                                <span class="agente-tooltip-mensajes-no-leidos">
                                    Ud. tiene {{ agente.mensajes_no_leidos }} mensajes sin leer de {{ agente.nombre }} {{ agente.apellido }}.
                                </span>
                            </span>

                            <span class="agente-status online" *ngIf="agente.conectado"></span>
                        </figure>

                        <span class="nombre">{{ agente.nombre }} {{ agente.apellido }}</span>

                        <span class="rol" *ngIf="agente.registro">Martillero y Corredor Público Reg. {{ agente.registro }}</span>

                        <a title="email" class="email" href="mailto:{{agente.email}}">{{ agente.email }}</a>

                        <a *ngIf="agente.celular" href="https://api.whatsapp.com/send?phone=54{{agente.celular}}" target="_blank" class="whatsapp" (click)="openWhatsApp(agente)">
                            <img src="../../../assets/images/logo-whatsapp.png">Enviar WhatsApp
                        </a>
                    </div>

                </ng-container>
            </div>

        </div>

    </div>

</section>


<gallery
  [pathDirThumbnails]="url_thumbnails_inmuebles"
  [pathDirImages]="url_images_inmuebles"
  [images]="imagenes"
  [show_thumbs]="true"
  [show_epigrafe]="true"
>
</gallery>
