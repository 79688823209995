<div class="container p-t-20 p-b-20">		
		
	<div class="page-wrapper">

        <div class="services" *ngIf="servicios.length > 0">

            <div class="service" *ngFor="let servicio of servicios">

                <h1>{{ servicio.titulo }}</h1>

                <figure>
                    <ng-template [ngIf]="servicio.imagen" [ngIfElse]="imagenServicioDefault">
                        <img src="{{ url_web_inmobiliaria + servicio.imagen }}" alt="Servicio inmobiliario">
                    </ng-template>

                    <ng-template #imagenServicioDefault>
                        <img src="{{ imagen_default_servicios }}" alt="Servicio inmobiliario">
                    </ng-template>
                </figure>

                <p [innerHtml]="servicio.descripcion"></p>

                <button type="button" class="button" (click)="irPaginaContactos()">Contáctenos</button>
                
            </div>
        </div>

	</div>	
	
</div>