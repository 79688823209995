
<div class="images-slider">
 
    <app-carousel-imagenes *ngIf="imagenes_seccion_inicio.length > 0"
        [pathDirImages]="url_imagenes_seccion_inicio"
        [images]="imagenes_seccion_inicio"
        [width]="'100%'"
        [height]="'100%'"
        [tiempoPausa]="10000"
        [object_fit]="'cover'"
        [show_zoom_button]="false"
        [show_nav_buttons]="false"
        [show_indicators]="false"
        [show_pause_button]="false"
    ></app-carousel-imagenes>

    <div class="inicio-text">
        <h1>{{ web.inicio_titulo }}</h1>
        <h2 [innerHtml]="web.inicio_texto"></h2>
    </div>

</div>

<div class="container p-b-20">

	<!-- Buscador -->
	<div class="row search">

		<div class="head">
			<i class="mdi mdi-search"></i>
			Buscador
		</div>

        <div class="col-lg-3 form-group">
            <label class="control-label">Tipo de operación</label>

            <select [formControl]="fcTipoOperacion" class="form-control custom-select">
                <option value="">Todos</option>
                <option *ngFor="let tipo of tipos_operacion" value="{{tipo.id}}" >
                    {{tipo.nombre}}
                </option>
            </select>
        </div>
        <div class="col-lg-3 form-group">
            <label class="control-label">Tipo de inmueble</label>
            <select [formControl]="fcTipoInmueble" class="form-control custom-select">
                <option value="">Todos</option>
                <option *ngFor="let tipo of tipos_inmueble" value="{{tipo.id}}">
                    {{tipo.nombre}}
                </option>
            </select>
        </div>

        <div class="col-lg-2 form-group" *ngIf="web.buscador_x_precio === 'predefinidos' && fcTipoOperacion.value > 0">
            <label class="control-label">Precios</label>

            <select [formControl]="fcPrecioVenta" class="form-control custom-select" *ngIf="fcTipoOperacion.value==1">
                <option value="">Seleccione</option>

                <option *ngFor="let precio of precios_venta" value="{{precio.id}}">
                    {{precio.nombre}}
                </option>
            </select>

            <select [formControl]="fcPrecioAlquiler" class="form-control custom-select" *ngIf="fcTipoOperacion.value>1">
                <option value="">Seleccione</option>

                <option *ngFor="let precio of precios_alquiler" value="{{precio.id}}">
                    {{precio.nombre}}
                </option>
            </select>
        </div>

        <div class="col-lg-2 form-group" *ngIf="web.buscador_x_precio === 'libre' && fcTipoOperacion.value > 0">
            <label class="control-label">Precio desde</label>
            <input type="text" [formControl]="fcPrecioDesde" class="form-control precios" (keypress)="allowOnlyInteger($event)" placeholder="desde">
        </div>
        <div class="col-lg-2 form-group" *ngIf="web.buscador_x_precio === 'libre' && fcTipoOperacion.value > 0">        
            <label class="control-label">Precio hasta</label>
            <input type="text" [formControl]="fcPrecioHasta" class="form-control precios" (keypress)="allowOnlyInteger($event)" placeholder="hasta...">
        </div>

        <!-- Casa -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcAmbientes">
            <label class="control-label">Ambientes</label>

            <select [formControl]="fcAmbientes" class="form-control custom-select">
                <option value="">Todos</option>
                <option value="1">1 ambiente</option>
                <option value="2">2 ambientes</option>
                <option value="3">3 ambientes</option>
                <option value="4">4 ambientes</option>
                <option value="5">5 ambientes</option>
                <option value="6">6 ambientes</option>
                <option value="7">7 ambientes</option>
                <option value="8">8 ambientes</option>
                <option value="9">9 ambientes</option>
                <option value="10">+9 ambientes</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcDormitorios">
            <label class="control-label">Dormitorios</label>

            <select [formControl]="fcDormitorios" class="form-control custom-select">
                <option value="">Todos</option>
                <option value="1">1 dormitorio</option>
                <option value="2">2 dormitorios</option>
                <option value="3">3 dormitorios</option>
                <option value="4">4 dormitorios</option>
                <option value="5">5 dormitorios</option>
                <option value="6">6 dormitorios</option>
                <option value="7">7 dormitorios</option>
                <option value="8">8 dormitorios</option>
                <option value="9">9 dormitorios</option>
                <option value="10">+9 dormitorios</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcBanos">
            <label class="control-label">Baños</label>

            <select [formControl]="fcBanos" class="form-control custom-select">
                <option value="">Todas</option>
                <option value="1">1 baño</option>
                <option value="2">2 baños</option>
                <option value="3">3 baños</option>
                <option value="4">4 baños</option>
                <option value="5">5 baños</option>
                <option value="6">6 baños</option>
                <option value="7">7 baños</option>
                <option value="8">8 baños</option>
                <option value="9">9 baños</option>
                <option value="10">+9 baños</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcCocheras">
            <label class="control-label">Cocheras</label>

            <select [formControl]="fcCocheras" class="form-control custom-select">
                <option value="">Todas</option>
                <option value="1">1 cochera</option>
                <option value="2">2 cocheras</option>
                <option value="3">+2 cocheras</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcPlantas">
            <label class="control-label">Plantas</label>

            <select [formControl]="fcPlantas" class="form-control custom-select">
                <option value="">Todas</option>
                <option value="1">1 planta</option>
                <option value="2">2 plantas</option>
                <option value="3">+2 plantas</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcDependencia">
            <label class="control-label">Dependencia</label>

            <select [formControl]="fcDependencia" class="form-control custom-select">
                <option value="">Seleccione</option>
                <option value="true">Con dependencia</option>
                <option value="false">Sin dependencia</option>
            </select>
        </div>
        <!-- Si es Cochera -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcSupCochera">
            <label class="control-label">Sup.Cubierta</label>

            <select [formControl]="fcSupCochera" class="form-control custom-select">
                <option value="">Todas</option>
                <option *ngFor="let sup of superficies_cochera" value="{{sup.id}}">{{sup.nombre}}</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcTipoCobertura">
            <label class="control-label">Cobertura</label>

            <select [formControl]="fcTipoCobertura" class="form-control custom-select">
                <option value="">Todas</option>
                <option value="Cubierta">Cubierta</option>
                <option value="Descubierta">Descubierta</option>
                <option value="Semicubierta">Semicubierta</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcTipoCoche">
            <label class="control-label">Tipo coche</label>

            <select [formControl]="fcTipoCoche" class="form-control custom-select">
                <option value="">Todos</option>
                <option value="Chico">Chico</option>
                <option value="Mediano">Mediano</option>
                <option value="Grande">Grande</option>
                <option value="Doble">Doble</option>
            </select>
        </div>
        <!-- Comercios -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcSupLocal">
            <label class="control-label">Sup. local</label>

            <select [formControl]="fcSupLocal" class="form-control custom-select">
                <option value="">Todas</option>
                <option *ngFor="let sup of superficies_local" value="{{sup.id}}">{{sup.nombre}}</option>
            </select>
        </div>
        <!-- Departamentos -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcDisposicion">
            <label class="control-label">Disposición</label>

            <select [formControl]="fcDisposicion" class="form-control custom-select">
                <option value="">Todas</option>
                <option value="Frente">Frente</option>
                <option value="Contrafrente">Contrafrente</option>
                <option value="Lateral">Lateral</option>
                <option value="Interno">Interno</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcExpensas">
            <label class="control-label">Expensas</label>

            <select [formControl]="fcExpensas" class="form-control custom-select">
                <option value="">Todas</option>
                <option *ngFor="let expensa of expensas" value="{{expensa.id}}">{{expensa.nombre}}</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcAptoProfesional">
            <label class="control-label">Apto profesional</label>

            <select [formControl]="fcAptoProfesional" class="form-control custom-select">
                <option value="">Todos</option>
                <option value="true">Si</option>
                <option value="false">No</option>
            </select>
        </div>
        <!-- galpon -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcSupGalpon">
            <label class="control-label">Sup. Cubierta</label>

            <select [formControl]="fcSupGalpon" class="form-control custom-select">
                <option value="">Todas</option>
                <option *ngFor="let sup of superficies_galpon" value="{{sup.id}}">{{sup.nombre}}</option>
            </select>
        </div>
        <!-- Locales -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcSupCubiertaLocal">
            <label class="control-label">Sup. Cubierta</label>

            <select [formControl]="fcSupCubiertaLocal" class="form-control custom-select">
                <option value="">Todas</option>
                <option *ngFor="let sup of superficies_local" value="{{sup.id}}">{{sup.nombre}}</option>
            </select>
        </div>
        <!-- terreno -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcSupTerreno">
            <label class="control-label">Sup. terreno</label>

            <select [formControl]="fcSupTerreno" class="form-control custom-select">
                <option value="">Todas</option>
                <option *ngFor="let sup of superficies_terreno" value="{{sup.id}}">{{sup.nombre}}</option>
            </select>
        </div>

        <div class="col-lg-3 form-group" *ngIf="searchFields.fcCiudad">
            <label class="control-label">Localidad</label>
            <select #ciudad [formControl]="fcCiudad" class="form-control custom-select" (change)="changeSelectCiudad(ciudad.value)">
                <option value="">Todas</option>
                <option *ngFor="let ciudad of ciudades" value="{{ciudad.id}}">
                    {{ciudad.nombre}}
                </option>
            </select>
        </div>

        <div class="col-lg-2 form-group" *ngIf="searchFields.fcBarrios">
            <label class="control-label">Barrio</label>

            <select [formControl]="fcBarrios" class="form-control custom-select">
                <option value="">Todos</option>
                <option *ngFor="let barrio of barrios" value="{{barrio.id}}">{{barrio.nombre}}</option>
            </select>
        </div>

        <div class="col-lg-2 form-group f-a-b">
            <button type="button" class="form-button" (click)="doSearch()">
				Buscar
			</button>
    	</div>

	</div>

	<div class="destacados" *ngIf="importantes">

    <h3>Propiedades Destacadas</h3>

		<app-carousel-inmuebles
			[url_imagenes_inmuebles]="url_thumbnails_inmuebles"
			[inmuebles]="importantes"
    ></app-carousel-inmuebles>
	</div>
</div>
