<!-- Section / Emprendimientos -->
<section class="section emprendimientos-slider-wraper" *ngIf="imagenes.length > 0">

    <ng-template [ngIf]="imagenes.length > 1" [ngIfElse]="soloImagen">
        <app-carousel-imagenes
            [pathDirImages]="url_web_inmobiliaria"
            [images]="imagenes"
            [width]="'100%'"
            [height]="'100%'"
            [tiempoPausa]="10000"
            [object_fit]="'cover'"
            [show_zoom_button]="false"
            [show_nav_buttons]="false"
            [show_indicators]="false"
            [show_pause_button]="false"
            [show_progress_bar]="false"
            [show_image_text]="true"
        ></app-carousel-imagenes>
    </ng-template>

    <ng-template #soloImagen>

        <img src="{{ url_web_inmobiliaria + imagenes[0].nombre }}">

    </ng-template>

</section>

<!-- Seccion emprendimientos -->
<section class="section section-padding-top section-padding-bottom">

    <div class="container">

        <div class="seccion-emprendimientos">
            <div class="titulo">{{ web.emprendimientos_titulo }}</div>

            <p [innerHtml]="web.emprendimientos_texto"></p>

            <div class="emprendimientos">
                <article class="emprendimiento" *ngFor="let inmueble of inmuebles">

                    <a [routerLink]="['/inmueble/', inmueble.id]" title="Ver detalles">
                        <figure class="emprendimiento-imagen">

                            <ng-template [ngIf]="inmueble.imagen_principal" [ngIfElse]="imagenInmuebleDefault">
                                <img src="{{ url_thumbnails_inmuebles + inmueble.imagen_principal }}">
                            </ng-template>

                            <ng-template #imagenInmuebleDefault>
                                <img src="{{ imagen_default_inmueble }} ">
                            </ng-template>

                        </figure>

                        <h3 class="emprendimiento-titulo">
                            {{ inmueble.titulo }}
                        </h3>

                        <span class="emprendimiento-estado" *ngIf="inmueble.obra_estado_construccion">{{ inmueble.obra_estado_construccion }}</span>
                    </a>
                </article>
            </div>

        </div>

    </div>

</section>
