import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


// Gestos para mobile
// aquí puede agregar su propia configuración de gestos Hammer usando la clase HammerGestureConfig y HAMMER_GESTURE_CONFIG 
import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

/* PROBLEMA NO RESUELTO

EXPLICACION: uso dos librerias hammerjs
---------------------------------------
@egjs/hammerjs : esta no trae el problema : window is not defined
y por eso lo declaro aca, pero no puedo usarla en los componentes como por carousel, porque
no me toma los gestos, para ampliar y desplazar etc ..
por eso tengo que y usar el hammerjs original en los componentes
*/
import * as Hammer from '@egjs/hammerjs';

import { Injectable, APP_ID } from '@angular/core';

@Injectable({providedIn: 'root'})
export class MyHammerConfig extends HammerGestureConfig {

    overrides = <any>{
        'swipe': {direction: Hammer.DIRECTION_ALL}, // override default settings
        'pan': {direction: Hammer.DIRECTION_ALL}
    };
}

// Google reCaptcha
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { environment } from '../environments/environment';

// Pipe para sanitizar una URL
import { UrlSeguraPipe } from './pipes/url-segura.pipe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Componentes externos
//import { AgmCoreModule } from '@agm/core';

// Componentes
import { T001MainComponent } from './components/main/main.component';
import { T001InicioComponent } from './components/inicio/inicio.component';
import { T001BuscadorComponent } from './components/buscador/buscador.component';
import { T001EmprendimientosComponent } from './components/emprendimientos/emprendimientos.component';
import { T001NosotrosComponent } from './components/nosotros/nosotros.component';
import { T001ServiciosComponent } from './components/servicios/servicios.component';
import { T001ContactosComponent } from './components/contactos/contactos.component';
import { T001InmuebleComponent } from './components/inmueble/inmueble.component';
import { T001InmuebleStandardComponent } from './components/inmueble-standard/inmueble-standard.component';
import { T001EmprendimientoComponent } from './components/emprendimiento/emprendimiento.component';

// Componentes independientes
import { CarouselInmueblesComponent } from './components/globals/carousel-inmuebles/carousel-inmuebles.component';
import { CarouselImagenesComponent } from './components/globals/carousel-imagenes/carousel-imagenes.component';
import { GalleryComponent } from './components/globals/gallery/gallery.component';
import { MapaInmueblesComponent } from './components/globals/mapa-inmuebles/mapa-inmuebles.component';
import { PaginationComponent } from './components/globals/pagination/pagination.component';
//import { InmueblesAdsComponent } from './components/globals/inmuebles-ads/inmuebles-ads.component';
//import { ChatComponent } from './components/globals/chat/chat.component';
//import { ChatManagementComponent } from './components/globals/chat-management/chat-management.component';
import { SpinnerComponent } from './components/globals/spinner/spinner.component';
import { CodigoQRComponent } from './components/globals/codigo-qr/codigo-qr.component';
import { SliderImagenesComponent } from './components/globals/slider-imagenes/slider-imagenes.component';
import { ButtonWhatsAppComponent } from './components/globals/button-whatsapp/button-whatsapp.component';


@NgModule({
  declarations: [
    UrlSeguraPipe,
  	AppComponent,
  	T001MainComponent,
  	T001InicioComponent,
  	T001BuscadorComponent,
    T001EmprendimientosComponent,
  	T001InmuebleComponent,
    T001InmuebleStandardComponent,
    T001EmprendimientoComponent,
  	T001NosotrosComponent,
  	T001ServiciosComponent,
  	T001ContactosComponent,
  	CarouselInmueblesComponent,
  	CarouselImagenesComponent,
    GalleryComponent,
  	MapaInmueblesComponent,
  	PaginationComponent,
  	//InmueblesAdsComponent,
  	//ChatComponent,
  	//ChatManagementComponent,
    SpinnerComponent,
    CodigoQRComponent,
    SliderImagenesComponent,
    ButtonWhatsAppComponent
  ],
  imports: [
  	HttpClientModule,
  	ReactiveFormsModule,
    RecaptchaV3Module,
    //BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserModule,
    AppRoutingModule,			
	/*AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCSEOl4UiF58WuPrLjTMvhzprkHE79rRVE'
    }),*/
    HammerModule
  ],
  providers: [
    Title, // Por recomendacion de angular.io, dice que es mejor registrar el servicio desde el arranque
    { 
      provide: HAMMER_GESTURE_CONFIG, 
      useClass: MyHammerConfig 
    },
    {
        provide: RECAPTCHA_V3_SITE_KEY,
        useValue: environment.recaptcha.siteKey,
    },
    { provide: APP_ID, useValue: 'serverApp' }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}