<div class="container p-t-20 p-b-20">		
		
	<div class="page-wrapper">

        <div class="consult">
            <h2>envie su consulta</h2>
            
            <p>Por consultas o mensajes, complete el formulario a continuación o por mail a <a title="Email de la inmobiliaria" class="email" href="mailto:{{ inmobiliaria.email }}">{{ inmobiliaria.email }}</a> y a la brevedad nos pondremos en contacto con usted.
            Muchas gracias!</p>

            
            <form [formGroup]="formConsulta" class="form">
                
                <div class="form-group">
                    <input type="text" formControlName="nombre" placeholder="Nombre" class="form-control">
                    <small *ngIf="formConsulta.get('nombre').invalid && (formConsulta.get('nombre').dirty || formConsulta.get('nombre').touched)" class="form-control-feedback error"> 
                        Por favor, ingrese su nombre.
                    </small>
                </div>
                <div class="form-group">
                    <input type="text" formControlName="email" placeholder="Email" class="form-control" (keydown)="deleteEspaciosVacios($event)">
                    <ng-container *ngIf="(formConsulta.get('email').dirty || formConsulta.get('email').touched)">
                        <small *ngIf="formConsulta.get('email').errors?.required" class="form-control-feedback error">
                            Por favor, ingrese su correo electrónico.
                        </small>
                        <small *ngIf="formConsulta.get('email').errors?.email" class="form-control-feedback error">
                            El campo correo electrónico no es valido.
                        </small>
                    </ng-container>
                </div>
                <div class="form-group">
                    <input type="text" formControlName="asunto" placeholder="Asunto" class="form-control">
                </div>
                <div class="form-group">
                    <textarea class="form-control form-textarea" formControlName="mensaje" placeholder="Mensaje" cols="40" rows="10"></textarea>
                    <small *ngIf="formConsulta.get('mensaje').invalid && (formConsulta.get('mensaje').dirty || formConsulta.get('mensaje').touched)" class="form-control-feedback error"> 
                        Por favor, ingrese su consulta.
                    </small>
                </div>

                <button type="button" class="form-button" (click)="validateConsulta()">Enviar consulta</button>
            </form>
            
            <div class="mensaje color-success" *ngIf="show_mensaje === 'success'">
                <i class="mdi mdi-check-circle-outline"></i>
                La consulta se ha enviado correctamente.
            </div>

            <div class="mensaje color-error" *ngIf="show_mensaje === 'error'">
                <i class="mdi mdi-close-circle-outline"></i>
                Ocurrió un error al intentar enviar la consulta. Por favor, intente nuevamente.
            </div>

            <app-spinner *ngIf="show_spinner" [titulo]="'Enviando ..'"></app-spinner>

        </div>

        <div class="info">

            <ng-container *ngIf="inmobiliaria.latitud && inmobiliaria.longitud">
                <h2>Donde estamos ?</h2>
                <div class="map">
                    <app-mapa-inmuebles [marker]="inmobiliaria">
                    </app-mapa-inmuebles>
                </div>
            </ng-container>       

            <h2>Información de Contacto</h2>
            <div class="contacts">
                <span *ngIf="inmobiliaria.calle"><i class="mdi mdi-map-marker"></i>{{ inmobiliaria.calle }} {{ inmobiliaria.numero }} {{ inmobiliaria.piso }} {{ inmobiliaria.oficina }}</span>
                <a href="tel:+54{{ inmobiliaria.telefonos }}" *ngIf="inmobiliaria.telefonos"><i class="mdi mdi-phone"></i>{{ inmobiliaria.telefonos }}</a>
                <a href="tel:+54{{ inmobiliaria.celulares }}" *ngIf="inmobiliaria.celulares"><i class="mdi mdi-cellphone"></i>{{ inmobiliaria.celulares }}</a>
                <a href="mailto:{{ inmobiliaria.email }}"><i class="mdi mdi-email"></i>{{ inmobiliaria.email }}</a>
            </div>

            <ng-container *ngIf="inmobiliaria.horarios">
                <h2>Horario de Atención</h2>

                <p [innerHTML]="inmobiliaria.horarios"></p>
            </ng-container>

        </div>

	</div>	
	
</div>