import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, forkJoin } from 'rxjs';

// Servicios
import { GLOBAL } from '../../services/global';
import { ScrollService } from '../../services/scroll.service';
import { ConfigService } from '../../services/config.service';
import { InmobiliariasService } from '../../services/inmobiliarias.service';
import { InmueblesService } from '../../services/inmuebles.service';


@Component({
	selector: 'T001-emprendimientos',
	templateUrl: './emprendimientos.component.html',
	styleUrls: [ './emprendimientos.component.css' ],
	providers: [ InmobiliariasService, InmueblesService ],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class T001EmprendimientosComponent {
	
	public identity: any;
	public web: any;
	public inmobiliaria: any;
	public imagenes: Array<any>;
	public inmuebles: Array<any>;

    public url_web_inmobiliaria: string;

    public url_images_inmuebles: string;
	public url_thumbnails_inmuebles: string;
	public imagen_default_emprendimientos: string;
	public imagen_default_inmueble: string;

	constructor(private _router: Router,
				private _cdRef: ChangeDetectorRef,
                private _scrollService: ScrollService,
                private _configService: ConfigService,
				private _inmobiliariasService: InmobiliariasService,
                private _inmueblesService: InmueblesService ) {

		this.identity = GLOBAL.identity;
		this.inmobiliaria = GLOBAL.inmobiliaria;
		this.web = GLOBAL.web;
		this.imagenes = [];
		this.inmuebles = [];

		this.url_web_inmobiliaria = GLOBAL.url_webs + GLOBAL.inmobiliaria.id + '/';
        this.url_thumbnails_inmuebles = GLOBAL.url_thumbnails_inmuebles;
        this.imagen_default_inmueble = this._configService.imagen_default_inmueble;
	}

	ngOnInit(){
		console.log('T001EmprendimientosComponent component cargado ... ');

		this.getDatosDeEmprendimientos().subscribe(
			(response)=>{
                this._cdRef.detectChanges();
				this.irArribaDeLaPagina();
			},
			(error)=>{
				this._router.navigate(['/']);
			});
	}

    irArribaDeLaPagina(){
		
        this._scrollService.scrollTo({ y:0, duration: 0 });
	}

    getDatosDeEmprendimientos(): Observable<any> {

    	return new Observable((observer)=>{

			forkJoin([
                this._inmueblesService.getEmprendimientos(this.inmobiliaria.id),
				this._inmobiliariasService.getImagesBySeccion(this.inmobiliaria.id, 'emprendimientos')
            ]).subscribe((response)=>{
				//console.log(response);
                this.inmuebles = response[0].emprendimientos;
				this.imagenes = response[1].imagenes;

				observer.next();
				observer.complete();
			},
			(error)=>{
				observer.error(error);
				observer.complete();
			});
		});
	}
}