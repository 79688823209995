import { Component, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { Observable, Subscription, forkJoin } from 'rxjs';

// Servicios
import { GLOBAL, TIPO_VISTA } from '../../services/global';
import { ScrollService } from '../../services/scroll.service';
import { ConfigService } from '../../services/config.service';
import { LocalStorageService } from '../../services/localStorage.service';
import { InmueblesService } from '../../services/inmuebles.service';

// Components
import { PaginationComponent } from '../globals/pagination/pagination.component';

// Validators
import { CustomValidators } from '../../validators/custom.validators';

@Component({
	selector: 'T001-buscador',
	templateUrl: './buscador.component.html',
	styleUrls: ['./buscador.component.css', 
				'./result.css', 
				'../../../assets/css/form.css'],
	providers: [InmueblesService],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class T001BuscadorComponent {

	public identity: any;
	public inmobiliaria: any;
	public web: any;
	
	public url_thumbnails_inmuebles: string;
	public imagen_default_inmueble: string;
	
    // El componente puede recibir como parametro un string, con
	// los campos de busqueda
	public query: any;
	public irAlTopDeLaPagina: boolean;

	public searchPropertys:any;
    public esperandoRespuestaDelBuscador: boolean;
	public inmuebles: Array<any>;

	public tipos_operacion: Array<any>;
	public tipos_inmueble: Array<any>;
    public ciudades: Array<any>;
	public barrios: Array<any>;
	public precios_venta: Array<any>;
	public precios_alquiler: Array<any>;
	public superficies_cochera: Array<any>
	public superficies_local: Array<any>;
	public expensas: Array<any>;
	public superficies_galpon: Array<any>;
	public superficies_terreno: Array<any>;
	public searchFields: any;
	public dataResultQuery: any;
	public showDataResult: boolean;
    public tipo_vista: string;

	// Selects
	public fcTipoOperacion: UntypedFormControl;
	public fcTipoInmueble: UntypedFormControl;
    public fcCiudad: UntypedFormControl;
	public fcBarrios: UntypedFormControl;
	public fcPrecioVenta: UntypedFormControl;
	public fcPrecioAlquiler: UntypedFormControl;
    public fcPrecioDesde: UntypedFormControl;
    public fcPrecioHasta: UntypedFormControl;
	public fcAmbientes: UntypedFormControl;
	public fcDormitorios: UntypedFormControl;
	public fcBanos: UntypedFormControl;
	public fcCocheras: UntypedFormControl;
	public fcPlantas: UntypedFormControl;
	public fcDependencia: UntypedFormControl;
	public fcSupCochera: UntypedFormControl;
	public fcTipoCobertura: UntypedFormControl;
	public fcTipoCoche: UntypedFormControl;
	public fcSupLocal: UntypedFormControl;
	public fcDisposicion: UntypedFormControl;
	public fcExpensas: UntypedFormControl;
	public fcAptoProfesional: UntypedFormControl;	
	public fcSupGalpon: UntypedFormControl;
	public fcSupCubiertaLocal: UntypedFormControl;
	public fcSupTerreno: UntypedFormControl;
	
	private subTipoInmueble: Subscription;

	@ViewChild('pagination', { static: true }) pagination: PaginationComponent;

	constructor(private _route: ActivatedRoute,
				private _router: Router,
				private _cdRef: ChangeDetectorRef,
                private _scrollService: ScrollService,
				private _configService: ConfigService,
				private _localStorateService: LocalStorageService,
				private _inmueblesService: InmueblesService ) {

		this.identity = GLOBAL.identity;
		this.inmobiliaria = GLOBAL.inmobiliaria;
		this.web = GLOBAL.web;

        this.query = {};
		this.irAlTopDeLaPagina = true;
		this.inmuebles = [];

		this.url_thumbnails_inmuebles = GLOBAL.url_thumbnails_inmuebles;
		this.imagen_default_inmueble = this._configService.imagen_default_inmueble;

		// Selects
		this.fcTipoOperacion = new UntypedFormControl('');
		this.fcTipoInmueble = new UntypedFormControl('');
        this.fcCiudad = new UntypedFormControl('');
		this.fcBarrios = new UntypedFormControl('');
		this.fcPrecioVenta = new UntypedFormControl('');
		this.fcPrecioAlquiler = new UntypedFormControl('');
        this.fcPrecioDesde = new UntypedFormControl('');
        this.fcPrecioHasta = new UntypedFormControl('');
		this.fcAmbientes = new UntypedFormControl('');
		this.fcDormitorios = new UntypedFormControl('');
		this.fcBanos =  new UntypedFormControl('');
		this.fcCocheras = new UntypedFormControl('');
		this.fcPlantas = new UntypedFormControl('');
		this.fcDependencia = new UntypedFormControl('');
		this.fcSupCochera = new UntypedFormControl('');
		this.fcTipoCobertura = new UntypedFormControl('');
		this.fcTipoCoche = new UntypedFormControl('');
		this.fcSupLocal = new UntypedFormControl('');
		this.fcDisposicion = new UntypedFormControl('');
		this.fcExpensas = new UntypedFormControl('');
		this.fcAptoProfesional = new UntypedFormControl('');
		this.fcSupGalpon = new UntypedFormControl('');
		this.fcSupCubiertaLocal = new UntypedFormControl('');
		this.fcSupTerreno = new UntypedFormControl('');

		this.tipos_operacion = [];
		this.tipos_inmueble = [];
		this.precios_venta = [];
		this.precios_alquiler = [];
		this.superficies_cochera = [];
		// Este array lo uso para comercios y locales
		this.superficies_local = [];
		this.expensas = [];
		this.superficies_galpon = [];
		this.superficies_terreno = [];

		this.searchFields = {};		

		this.showDataResult = false;
		this.dataResultQuery = {
				items_desde: 0,
				items_hasta: 0,
				items_total: 0
		};

		this.searchPropertys = {
						page: 1,
						items_x_page: 10
		};

        this.tipo_vista = TIPO_VISTA.GRILLA;
	}

	ngOnInit(){
		console.log('T001BuscadorComponent component cargado ... ');

		this.getDatosParaControlesDelBuscador().subscribe(
			(response)=>{

				this.detectChanges();
				this.suscribeFormControlTipoInmueble();

                // Una vez que tenga todos los datos para llenar los filtros
                this.getFiltrosDeLaURL();
			},
            (error)=>{
                console.log(error);
            });
	}

	ngOnDestroy(){

		this.subTipoInmueble.unsubscribe();
	}

	detectChanges(){

		this._cdRef.detectChanges();
	}

	// obtengo todos los datos de los formControls de la busqueda
	getDatosParaControlesDelBuscador(): Observable<any>{

	    return new Observable((observer)=>{

	      	forkJoin([
	      		this._inmueblesService.getTiposOperacion(),
		        this._inmueblesService.getTiposInmueble(),
                this._inmueblesService.getCiudades(),
		        this._inmueblesService.getNombresBarrios(),
		        this._inmueblesService.getPreciosVenta(),
		        this._inmueblesService.getPreciosAlquiler(),
		        this._inmueblesService.getSupCochera(),
		        this._inmueblesService.getSupLocal(),
		        this._inmueblesService.getExpensas(),
		        this._inmueblesService.getSupGalpon(),
		        this._inmueblesService.getSupTerreno()
              ]).subscribe((response)=>{
	      		//console.log('response: ', response);

	      		this.tipos_operacion = response[0].tipos_operacion;
	      		this.tipos_inmueble = response[1].tipos_inmueble;

                this.ciudades = response[2].ciudades;

                if ( this.ciudades.length == 1 ) {

                    this.searchFields.fcCiudad = false;
                    // Si la unica ciudad que hay es Mar del Plata
                    this.searchFields.fcBarrios = this.ciudades[0].id == 1 ? true : false;
                } else if ( this.ciudades.length > 1 )
                    this.searchFields.fcCiudad = true;

	      		this.barrios = response[3].barrios;
	      		this.precios_venta = response[4].precios_venta;
	      		this.precios_alquiler = response[5].precios_alquiler;
	      		this.superficies_cochera = response[6].superficies;
	      		this.superficies_local = response[7].superficies;
	      		this.expensas = response[8].expensas;
	      		this.superficies_galpon = response[9].superficies;
		        this.superficies_terreno = response[10].superficies;

		        observer.next();
		        observer.complete();
	      	},
	      	(error)=>{
		        observer.error(error);
		        observer.complete();
	      	});
	    });
	}

    getFiltrosDeLaURL() {

        // Cada vez que se realice un cambio en la URL tomo el parametro
        // autoria y realizo la consulta
        this._route.paramMap.subscribe((params) => {

			// el parametro query, debe venir en formato JSON con las 
			// propiedades de busqueda deseadas
            const query_string = params.has('query') ? params.get('query') : null;

            if ( !query_string ) { // Si no vienen parametros
                this.searchPropertys = {               
                    page: 1,
                    items_x_page: 10
                };
            } else {

                this.query = JSON.parse(query_string);

                // Aca puede ser que venga sin datos en page y items_x_page porque viene desde el mapa
                this.searchPropertys = {               
                    page: this.query.page ? this.query.page : undefined,
                    items_x_page: this.query.page ? this.query.page : undefined
                };

                Object.assign(this.searchPropertys, this.query);
            }

            this.setParametrosDeEntradaEnElBuscador();

            // Esto podria hacerlo cuando tengo los datos de la respuesta
            this.irAlTopDeLaPagina = params.has('query') ? false : true;
		});
    }

    async setParametrosDeEntradaEnElBuscador() {

        //console.log('setParametrosDeEntradaEnElBuscador ', this.searchPropertys);
        
        // SEGUN EL TIPO DE INMUEBLES que muestre los filtros necesarios
        await this.mostrarCamposDeBusquedaSegunTipoInmuebleSeleccionado(this.searchPropertys.id_tipo_inmueble)
                .then(async()=>{

                    await this.setearTodosLosFiltrosDelBuscadorSegunQueryDeEntrada();

                    // realizar la consulta
                    this.doSearch();
                });
    }

	suscribeFormControlTipoInmueble(){
		// Subcripciones a los cambios de los FormControls
		this.subTipoInmueble = this.fcTipoInmueble.valueChanges.subscribe(
								(value)=>{

									this.mostrarCamposDeBusquedaSegunTipoInmuebleSeleccionado(value);
								});
	}

	mostrarCamposDeBusquedaSegunTipoInmuebleSeleccionado(id_tipo_inmueble): Promise<void>{

		return new Promise((resolve)=>{

            this.searchFields = {
                            fcCiudad: this.searchFields.fcCiudad ? true : false,
                            fcAmbientes: false,
                            fcDormitorios: false,
                            fcBanos: false,
                            fcCocheras: false,
                            fcPlantas: false,
                            fcDependencia: false,
                            fcSupCochera: false,
                            fcTipoCobertura: false,
                            fcTipoCoche: false,
                            fcSupLocal: false,
                            fcDisposicion: false,
                            fcExpensas: false,
                            fcAptoProfesional: false,
                            fcSupGalpon: false,
                            fcSupCubiertaLocal: false,
                            fcSupTerreno: false,
                            fcBarrios: this.searchFields.fcBarrios ? true : false
                        };

            let updateSearchFields = {};

            switch(+id_tipo_inmueble){

                case 1: // Casa
                        updateSearchFields = {
                            fcAmbientes: true,
                            fcDormitorios: true,
                            fcBanos: true,
                            fcCocheras: true,
                            fcPlantas: true,
                            fcSupTerreno: true,
                            fcDependencia: true
                        };
                break;
                case 2: // Cochera
                        updateSearchFields = {
                            fcSupCochera: true,
                            fcTipoCobertura: true,
                            fcTipoCoche: true
                        };
                break;
                case 3: // Comercio
                        updateSearchFields = {
                            fcSupLocal: true
                        };
                break;
                case 4: // Departamento
                        updateSearchFields = {
                            fcAmbientes: true,
                            fcDormitorios: true,
                            fcBanos: true,
                            fcCocheras: true,
                            fcDisposicion: true,
                            fcExpensas: true,
                            fcAptoProfesional: true
                        };
                break;
                case 5: // Galpon
                        updateSearchFields = {
                            fcSupGalpon: true
                        };
                break;
                case 6: // Local
                        updateSearchFields = {
                            fcSupCubiertaLocal: true
                        };
                break;
                case 8: // Oficina
                        updateSearchFields = {
                            fcAmbientes: true,
                            fcBanos: true,
                            fcCocheras: true,
                            fcExpensas: true
                        };
                break;
                case 9: // Quinta
                        updateSearchFields = {
                            fcAmbientes: true,
                            fcDormitorios: true,
                            fcBanos: true,
                            fcCocheras: true,
                            fcPlantas: true,
                            fcDependencia: true
                        };
                break;
                case 10: // Terreno
                        updateSearchFields = {
                            fcSupTerreno: true
                        };
                break;
                case 11: // PH - Duplex
                        updateSearchFields = {
                            fcAmbientes: true,
                            fcDormitorios: true,
                            fcBanos: true,
                            fcCocheras: true
                        };
                break;
            }

            Object.assign( this.searchFields, updateSearchFields);

            this.resetearFormControlsDeBusqueda();

            return resolve();
        });
	}

	resetearFormControlsDeBusqueda(){

		// Reseteo los formControls no visibles
		for( const nombre_control in this.searchFields ) {

			if ( ! this.searchFields[nombre_control] )
				this.resetearFormControl(nombre_control);
		}
	}

    resetearFormControl(nombre_control=null){
        
		switch(nombre_control){

			case 'fcPrecioVenta': this.fcPrecioVenta.setValue(''); break;
			case 'fcPrecioAlquiler': this.fcPrecioAlquiler.setValue(''); break;
            case 'fcPrecioDesde': this.fcPrecioDesde.setValue(''); break;
            case 'fcPrecioHasta': this.fcPrecioHasta.setValue(''); break;
			case 'fcAmbientes': this.fcAmbientes.setValue(''); break;
			case 'fcDormitorios': this.fcDormitorios.setValue(''); break;
			case 'fcBanos': this.fcBanos.setValue(''); break;
			case 'fcCocheras': this.fcCocheras.setValue(''); break;
			case 'fcPlantas': this.fcPlantas.setValue(''); break;
			case 'fcDependencia': this.fcDependencia.setValue(''); break;
			case 'fcSupCochera': this.fcSupCochera.setValue(''); break;
			case 'fcTipoCobertura': this.fcTipoCobertura.setValue(''); break;
			case 'fcTipoCoche': this.fcTipoCoche.setValue(''); break;
			case 'fcSupLocal': this.fcSupLocal.setValue(''); break;
			case 'fcDisposicion': this.fcDisposicion.setValue(''); break;
			case 'fcExpensas': this.fcExpensas.setValue(''); break;
			case 'fcAptoProfesional': this.fcAptoProfesional.setValue(''); break;
			case 'fcSupGalpon': this.fcSupGalpon.setValue(''); break;
			case 'fcSupCubiertaLocal': this.fcSupCubiertaLocal.setValue(''); break;
			case 'fcSupTerreno': this.fcSupTerreno.setValue(''); break;
		}
	}

    setearTodosLosFiltrosDelBuscadorSegunQueryDeEntrada(): Promise<void>{

		return new Promise(async(resolve)=>{
            
            this.fcTipoOperacion.setValue(this.searchPropertys.id_tipo_operacion ? this.searchPropertys.id_tipo_operacion : '');
            this.fcTipoInmueble.setValue( this.searchPropertys.id_tipo_inmueble ? this.searchPropertys.id_tipo_inmueble : '' );

            this.fcCiudad.setValue(this.searchPropertys.id_ciudad ? this.searchPropertys.id_ciudad : '');

            // Si NO esta visible el combo de ciudad y ... la unica ciudad es en el array de ciudades
            // es "Mar del Plata" es porque la inmo solo tiene inmuebles en mardel
            // Entonces no muestro el combo ciudad y solo muestro los barrios
            if ( !this.searchFields.fcCiudad && this.searchFields.fcBarrios )
                this.fcBarrios.setValue(this.searchPropertys.id_barrio ? this.searchPropertys.id_barrio : '');
            else {
                // Si el combo de ciudad esta visible y ..
                // Solo si la ciudad seleccionada es "Mar del Plata" muestro los barrios
                this.searchFields.fcBarrios = this.searchPropertys.id_ciudad == 1 ? true : false;

                setTimeout(()=>{
                        this.fcBarrios.setValue(this.searchPropertys.id_barrio ? this.searchPropertys.id_barrio : '');
                 }, 100);
            }

            this.fcPrecioVenta.setValue(this.searchPropertys.precio_venta ? this.searchPropertys.precio_venta : '');
            this.fcPrecioAlquiler.setValue(this.searchPropertys.precio_alquiler ? this.searchPropertys.precio_alquiler : '');
            this.fcPrecioDesde.setValue(this.searchPropertys.precio_desde ? this.searchPropertys.precio_desde : '');
            this.fcPrecioHasta.setValue(this.searchPropertys.precio_hasta ? this.searchPropertys.precio_hasta : '');
            this.fcAmbientes.setValue(this.searchPropertys.ambientes ? this.searchPropertys.ambientes : '');
            this.fcDormitorios.setValue(this.searchPropertys.dormitorios ? this.searchPropertys.dormitorios : '');
            this.fcBanos.setValue(this.searchPropertys.banos ? this.searchPropertys.banos : '');
            this.fcCocheras.setValue(this.searchPropertys.cocheras ? this.searchPropertys.cocheras : '');
            this.fcPlantas.setValue(this.searchPropertys.plantas ? this.searchPropertys.plantas : '');
            this.fcDependencia.setValue(this.searchPropertys.dependencia ? this.searchPropertys.dependencia : '');

            this.fcSupCochera.setValue(this.searchPropertys.sup_cochera ? this.searchPropertys.sup_cochera : '');
            this.fcTipoCobertura.setValue(this.searchPropertys.tipo_cobertura ? this.searchPropertys.tipo_cobertura : '');
            this.fcTipoCoche.setValue(this.searchPropertys.tipo_coche ? this.searchPropertys.tipo_coche : '');

            this.fcSupLocal.setValue(this.searchPropertys.sup_local ? this.searchPropertys.sup_local : '');

            this.fcDisposicion.setValue(this.searchPropertys.disposicion ? this.searchPropertys.disposicion : '');
            this.fcExpensas.setValue(this.searchPropertys.expensas ? this.searchPropertys.expensas : '');
            this.fcAptoProfesional.setValue(this.searchPropertys.apto_profesional ? this.searchPropertys.apto_profesional : '');
            
            this.fcSupGalpon.setValue(this.searchPropertys.sup_galpon ? this.searchPropertys.sup_galpon : '');
            this.fcSupCubiertaLocal.setValue(this.searchPropertys.sup_local ? this.searchPropertys.sup_local : '');
            this.fcSupTerreno.setValue(this.searchPropertys.sup_terreno ? this.searchPropertys.sup_terreno : '');

            resolve();
        });
    }

    changeSelectCiudad(id_ciudad=null) {

        // Solo si la ciudad seleccionada es "Mar del Plata" muestro los barrios
        this.searchFields.fcBarrios = id_ciudad == 1 ? true : false;
        this.fcBarrios.setValue('');
    }

    allowOnlyInteger(event, setPoints=false){

		CustomValidators.allowOnlyInteger(event, {setPoints: setPoints});
	}

	getDatosConsulta(): Promise<any>{

		return new Promise((resolve)=>{

            const consulta : any = {
				id_tipo_operacion: this.fcTipoOperacion.value ? this.fcTipoOperacion.value : undefined,
				id_tipo_inmueble: this.fcTipoInmueble.value ? this.fcTipoInmueble.value : undefined,
                id_ciudad: this.fcCiudad.value ? this.fcCiudad.value : undefined,
				id_barrio: this.fcBarrios.value ? this.fcBarrios.value : undefined,
				precio_venta: this.fcPrecioVenta.value ? this.fcPrecioVenta.value : undefined,
				precio_alquiler: this.fcPrecioAlquiler.value ? this.fcPrecioAlquiler.value : undefined,
                precio_desde: this.fcPrecioDesde.value ? this.fcPrecioDesde.value : undefined,
                precio_hasta: this.fcPrecioHasta.value ? this.fcPrecioHasta.value : undefined,
				ambientes: this.fcAmbientes.value ? this.fcAmbientes.value : undefined,
				dormitorios: this.fcDormitorios.value ? this.fcDormitorios.value : undefined,
				banos: this.fcBanos.value ? this.fcBanos.value : undefined,
				cocheras: this.fcCocheras.value ? this.fcCocheras.value : undefined,
				plantas: this.fcPlantas.value ? this.fcPlantas.value : undefined,
				dependencia: this.fcDependencia.value ? this.fcDependencia.value : undefined,
				sup_cochera: this.fcSupCochera.value ? this.fcSupCochera.value : undefined,
				tipo_cobertura: this.fcTipoCobertura.value ? this.fcTipoCobertura.value : undefined,
				tipo_coche: this.fcTipoCoche.value ? this.fcTipoCoche.value : undefined,
				sup_local: this.fcSupLocal.value ? this.fcSupLocal.value : undefined,
				disposicion: this.fcDisposicion.value ? this.fcDisposicion.value : undefined,
				expensas: this.fcExpensas.value ? this.fcExpensas.value : undefined,
				apto_profesional: this.fcAptoProfesional.value ? this.fcAptoProfesional.value : undefined,
				sup_galpon: this.fcSupGalpon.value ? this.fcSupGalpon.value : undefined,
				sup_cubierta: this.fcSupCubiertaLocal.value ? this.fcSupCubiertaLocal.value : undefined,
				sup_terreno: this.fcSupTerreno.value ? this.fcSupTerreno.value : undefined,
                solo_datos_para_mapa: this.searchPropertys.solo_datos_para_mapa ? this.searchPropertys.solo_datos_para_mapa : undefined
			};

			resolve(consulta);
		});
	}

    doSearchConParametros(): Promise<void> {

        return new Promise(async(resolve)=>{

            // La idea es que siempre que se realice una nueva busqueda sea page = 1
            // pero si viene desde la paginacion se setea el numero de la pagina
            if ( this.tipo_vista != TIPO_VISTA.MAPA && !this.searchPropertys.page )
                this.searchPropertys.page = 1;

            //console.log('viejo searchPropertys: ', this.searchPropertys);

            const consulta = await this.getDatosConsulta();
            //console.log('consulta: ', consulta);

            Object.assign(this.searchPropertys, consulta);
            //console.log('nuevo searchPropertys: ', this.searchPropertys);

            this._router.navigate(['/buscador/', JSON.stringify(this.searchPropertys)]);
        });
	}

    // En este metodo directamente mando al servidor los filtros que estan en searchPropertys
    doSearch(): Promise<void> {

        return new Promise(async(resolve)=>{

            this.esperandoRespuestaDelBuscador = true;
            this.inmuebles = [];
            this.detectChanges();

            // Se lo agrego siempre antes de enviarlo para que valla en el query del request a la API
            // y no se vea en la url cada vez que se hace una consulta
            const filtros = Object.assign({ id_inmobiliaria: this.inmobiliaria.id }, this.searchPropertys);
            
            this._inmueblesService.searchInmuebles(this.inmobiliaria.id, filtros).subscribe(
                (response)=>{
                    //console.log('response: ', response);
                    if ( response.success ) {

                        this.inmuebles = response.inmuebles;
                        this.showDataItems(response.total);
                    } else {

                        this.inmuebles = [];
                        this.showDataItems(0);
                    }

                    this.scrollPagina();
                    resolve();
                },
                (error)=>{
                    console.log(error);
                    this.esperandoRespuestaDelBuscador = false;
                }
            );
        });
	}

	showDataItems(total){

        this.esperandoRespuestaDelBuscador = false;

		// Seteos los datos de cantidades
		this.dataResultQuery.items_desde = (( this.searchPropertys.page - 1 ) * this.searchPropertys.items_x_page)+1;
		this.dataResultQuery.items_hasta = (this.dataResultQuery.items_desde-1) + this.inmuebles.length;
		this.dataResultQuery.items_total = total;

		this.showDataResult = this.inmuebles.length ? true : false;
		
		// Componente Paginacion
		const config = {
			page: this.searchPropertys.page,
			items_total: total,
			items_x_page: this.searchPropertys.items_x_page
		};

		this.pagination.show(config);

		this.detectChanges();
	}

	scrollPagina(){

		if ( this.irAlTopDeLaPagina )
			this._scrollService.scrollTo({ y:0, duration: 0 });
		else
			this.goSeccion('resultado');

		this.irAlTopDeLaPagina = false;
	}

	goSeccion(id=null) {

		const elem = document.getElementById(id) || document.getElementsByName(id)[0];
		let top = 0;

		if (!elem) return;

		top = elem.offsetTop;

        this._scrollService.scrollTo({ y: top, duration: 800 });
	}

	eventChangePagination(e){

        this.searchPropertys.page = e.page;
		this.doSearchConParametros();
	}
}