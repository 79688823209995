import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import io from 'socket.io-client';

// Services
import { LocalStorageService } from './localStorage.service';
import { GLOBAL } from './global';

// Models
import { Mensaje } from '../models/mensaje';

@Injectable({
  providedIn: 'root'
})

export class ChatService { 

	private identity;
	public socket;
	public agentes_conectados;
	public mensajes_no_leidos;

	// Observable del estado del servicio de chat ( conectado, no conectado) necesita se provisto de un valor por defecto
	private changeEstadoConexionServicioChatSubject = new BehaviorSubject<boolean>(false);
	public changeEstadoConexionServicioChat$ = this.changeEstadoConexionServicioChatSubject.asObservable();

	// Observable de los mensajes recibidos
	private mensajeReceivedSubject = new Subject<any>();
	public mensajeReceived$ = this.mensajeReceivedSubject.asObservable();

	// Observable de los cambios de estado de los mensajes
	private changeEstadoMensajeSubject = new Subject<any>();
	public changeEstadoMensaje$ = this.changeEstadoMensajeSubject.asObservable();

	// Observable de los estados de los agentes
	private changeEstadoAgenteSubject = new Subject<any>();
	public changeEstadoAgente$ = this.changeEstadoAgenteSubject.asObservable();

	// Observable de los estados de los agentes
	private changeCantidadDeMensajesNoLeidosSubject = new Subject<boolean>();
	public changeCantidadDeMensajesNoLeidos$ = this.changeCantidadDeMensajesNoLeidosSubject.asObservable();

	// Observable para abrir una ventana de chat
	private openChatSubject = new Subject<any>();
	public openChat$ = this.openChatSubject.asObservable();

	constructor(private _localStorageService: LocalStorageService ) {

		this.identity = this._localStorageService.getIdentity();
		this.socket = null;
		this.agentes_conectados = new Set();
		this.mensajes_no_leidos = new Map();
	}

	connect(){

		if ( this.isServicioChatConectado() || !this.identity || !this.identity.usuario )
			return;

		// Cuando se crea un socket, aunque este no se conecte con el servidor,
		// seguira intentando conectarse con el servidor.
		// Y si se conecta, y luego se produce una desconexion con el servidor, por alguna causa
		// el socket intentara continuamente ( tras un intervalo de tiempo) 
		// volver a conectarse
		this.socket = io(GLOBAL.url_server_chat,
                          {
                          	forceNew: true, // Esto fuerza otra conexion, pero como el servicio es singleton, usa siempre la misma
                            query: {
                              usuario: this.identity.usuario,
                              id_inmobiliaria: GLOBAL.inmobiliaria.id
                            } 
                          }
                        );
		
		this.socket.on('connect', () => {
			//console.log('La app inmobiliaria se conecto al servidor de chat. ID: ', this.socket.id);

			this.socketConectado();
		});

		this.socket.on('disconnect', () => {
			//console.log('La app inmobiliaria se desconecto al servidor de chat.');

			this.socketDesconectado();
		});

		this.socket.on('receiveMensaje', (mensaje, callback) => {
			//console.log('chat.service recibirMensaje: ', mensaje);

			this.mensajeReceivedSubject.next(mensaje);

			if ( callback )
				callback({ ack: true });
		});

		this.socket.on('changeEstadoMensaje', (estado, callback) => {
			//console.log('chat.service changeEstadoMensaje: ', estado);
			this.changeEstadoMensajeSubject.next(estado);
		});

		this.socket.on('changeEstadoAgenteByInmobiliaria', (estado) => {
			//console.log('chat.service changeEstadoAgenteByInmobiliaria: ', estado);
			this.updateAgentesConectados(estado);				
		});
	}

	socketConectado(){

		this.getAgentesConectados();
		this.getCantidadMensajesNoLeidosEnviadosPorAgentesDeLaInmobiliariaAlUsuario();
		this.setEstadoConexionServicioChat(true);
	}

	socketDesconectado(){

		this.clearAgentesConectados();
		//this.clearMensajesNoLeidos();
		this.setEstadoConexionServicioChat(false);
	}

	setEstadoConexionServicioChat(conectado){

		this.changeEstadoConexionServicioChatSubject.next(conectado);
	}

	isServicioChatConectado(){

		return this.socket && this.socket.connected ? true : false;
	}

	getIdentity(){
		return this.identity;
	}	

	sendMensaje(mensaje: Mensaje): Observable<any>{

		return new Observable((observer) => {
		
			// Completo los datos del objecto Mensaje
			mensaje.estado = 1; // Enviado
			mensaje.fecha_creacion = new Date();

			if ( this.isServicioChatConectado() ) {

				this.socket.emit('sendMensaje', mensaje, (response)=>{
					
					if ( response.ack ) {
						observer.next({ mensaje: response.mensaje });
						observer.complete();
					} else {
						observer.error({ error: true, mensaje: 'Ocurrió un error al intentar enviar el mensaje al servidor.' });
						observer.complete();
					}
				});

			} else {
				observer.error({ error: true, mensaje: '4No hay conexión con el servidor de chat.' });
				observer.complete();
			}
		});
	}

	getMensajesDelChatConElReceptor(tipo_receptor=null, receptor=null, options: any = {}): Observable<any> {

		return new Observable((observer) => {
			
			if ( !tipo_receptor || !receptor ) {
				observer.error([{ error: true, texto: 'Solicitud incorrecta. Faltan parámetros requeridos.' }]);
				observer.complete();
			}

			if ( !this.isServicioChatConectado() ) {
                observer.error([{ error: true, conectado: false, texto: 'No hay conexión con el servidor de chat.' }]);
				observer.complete();
			} else {
            
                const { page, limit } = options;

                this.socket.emit('getMensajesDelChatConElReceptor', { tipo_receptor, receptor, page, limit }, (response)=>{
                    
                    if ( response.mensajes ) {

                        observer.next(response);
                        observer.complete();
                    } else {
                        
                        observer.error([{ error: true, texto: 'Ocurrió un error al intentar obtener los mensajes del receptor.' }]);
                        observer.complete();
                    }
                });
            }
		});
	}

	getAgentesConectados(){

		if ( this.socket ) {
			
			this.socket.emit('getAgentesConectadosByInmobiliaria', (conectados)=>{
				
				if ( conectados )
					this.setAgentesConectados(conectados);
			});
		}
	}

	getCantidadMensajesNoLeidosEnviadosPorAgentesDeLaInmobiliariaAlUsuario(){

		if ( this.socket ) {
			
			this.socket.emit('getCantidadMensajesNoLeidosEnviadosPorAgentesDeLaInmobiliariaAlUsuario', (resultado)=>{
				
				//console.log('getCantidadMensajesNoLeidosEnviadosPorAgentesDeLaInmobiliariaAlUsuario: ', resultado);

				if ( resultado && resultado.length > 0 ) 
					this.setMensajesNoLeidos(resultado);	
				else
					this.clearMensajesNoLeidos();				
			});
		}
	}

	setMensajesNoLeidos(resultado=[]) {

		resultado.forEach((agente, index, array)=>{

			this.mensajes_no_leidos.set(agente.emisor, agente.total);

			if ( index == array.length - 1 )
				this.changeCantidadDeMensajesNoLeidosSubject.next(true);	
		});
	}

	clearMensajesNoLeidos() {

		this.mensajes_no_leidos.clear();
		this.changeCantidadDeMensajesNoLeidosSubject.next(true);
	}

	getCantidadDeMensajesNoLeidosDelAgente(id_agente=null){

		return this.mensajes_no_leidos.has(id_agente) ? this.mensajes_no_leidos.get(id_agente) : 0;
	}

	deleteMensajesNoLeidosDelAgente(id_agente=null) {
		
		if ( this.mensajes_no_leidos && this.mensajes_no_leidos.delete(id_agente) )
			this.changeCantidadDeMensajesNoLeidosSubject.next(true);
	}

	addOneInMensajesNoLeidosDelAgente(id_agente=null) {
		
		const cant = this.getCantidadDeMensajesNoLeidosDelAgente(id_agente) + 1;

		this.mensajes_no_leidos.set(id_agente, cant);

		this.changeCantidadDeMensajesNoLeidosSubject.next(true);
	}

	setAgentesConectados(conectados) {

		this.agentes_conectados = new Set(conectados);

		// Doy aviso por el Observable de que se actualizo la lista de agentes amigos conectados
		this.changeEstadoAgenteSubject.next(true);
	}

	updateAgentesConectados(estado:any={}){

		if ( estado.conectado )
          this.agentes_conectados.add(estado.id_agente);
        else
          this.agentes_conectados.delete(estado.id_agente);

        // Por medio del Observable aviso a todos los subscriptores
        // de que se a cambiado el estado de un agente de la inmobiliaria
        this.changeEstadoAgenteSubject.next(estado);
	}

	isAgenteConectado(id_agente=null) {
		
		return this.agentes_conectados.has(id_agente) ? true : false;
	}

	clearAgentesConectados(){

		this.agentes_conectados.clear();
	}

	openChat(agente=null){

		if ( !agente ) return;

		// Doy aviso por el Observable de que se desea abrir una ventana de chat
		this.openChatSubject.next(agente);		
	}

	setEstadoMensajesDelEmisorAVisto(tipo_emisor=null, emisor=null) {

		if ( this.socket && tipo_emisor && emisor )
			this.socket.emit('setEstadoMensajesDelEmisorAVisto', { tipo_emisor, emisor });
	}
}