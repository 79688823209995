
export class CustomValidators {

	public static allowOnlyInteger(event, opciones = {setPoints: false}){

		if ( event.type ==  "keypress" || event.type == 'keydown' ) {

			const aKeys = ['Backspace', 'Delete', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

			// Si no es algunas de las teclas del array aKeys, evito el evento
			if  ( aKeys.indexOf(event.key) === -1 ) {
				event.preventDefault();
				return;
			}

			// Si esta opciones es true, genero una mascara para que 
			// le agrege los puntos a los miles
			if ( opciones.setPoints && event.key != 'Tab' && event.key != 'Enter' ) {
				let str = event.target.value;
				const aOutput = [];

				// Saco los puntos del array
				str = str.replace(/[.]/g, '');

				// Agrego el nuevo caracter a la cadena del input
				if ( event.key == 'Backspace' )
					str = str.substr(0, str.length-1);
				else
					str = str + event.key;

				// Recorro la cadena y le agrego los puntos
				for(let j = str.length-1, i=0; j >= 0; j--, i++){
					
					if ( ( i !== 0 ) && ( i % 3  === 0 ) )
						aOutput.unshift('.');
					
					aOutput.unshift(str[j]);
				}

				// Cancelo el evento, porque le asigno el nuevo valor 
				event.preventDefault();
				event.target.value = aOutput.join('');
			}
		}
	}
}