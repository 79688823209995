import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';

// Servicios
import { GLOBAL } from '../../services/global';
import { ScrollService } from '../../services/scroll.service';
import { InmobiliariasService } from '../../services/inmobiliarias.service';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
	selector: 'T001-contactos',
	templateUrl: './contactos.component.html',
	styleUrls: ['./contactos.component.css', '../../../assets/css/form.css'],
    providers: [ AnalyticsService ]
})

export class T001ContactosComponent {
	
	public identity: any;
	public inmobiliaria: any;
    public web: any;
    public dominio: string;

	public formConsulta: UntypedFormGroup;
	private consulta: any;
	public show_spinner: boolean;
	public show_mensaje: 'none' | 'success' | 'error' | string;

	constructor(private _fb: UntypedFormBuilder,
                private _recaptchaV3Service: ReCaptchaV3Service,
                private _scrollService: ScrollService,
				private _inmobiliariasService: InmobiliariasService,
                private _analyticsService: AnalyticsService ) {

		this.identity = GLOBAL.identity;
		this.inmobiliaria = GLOBAL.inmobiliaria;
        this.web = GLOBAL.web;
        this.dominio =  GLOBAL.dominio;

		this.consulta = {};
		this.show_spinner = false;
		this.show_mensaje = 'none';
	}

	ngOnInit(){
		console.log('T001ContactosComponent component cargado ... ');

		this.createForm();
		this.irArribaDeLaPagina();
	}

	irArribaDeLaPagina(){

		this._scrollService.scrollTo({ y:0, duration: 0 });
	}

	createForm(){
		this.formConsulta = this._fb.group({
			nombre: [null, Validators.required],
			email: [null, [ Validators.required, Validators.email] ],
			asunto: null,
			mensaje: [null, Validators.required]
		});		
	}

    deleteEspaciosVacios(event){

        if ( event.key == " " || event.keyCode == 32 || event.code == "Space" ) {
            
            event.preventDefault();
            return;
        }
    }

	validateConsulta(){

		const errores = [];

		//console.log( this.formConsulta );

		if ( this.formConsulta.invalid ) {
			// controls es un objeto donde cada nombre de propiedad corresponde
			// al nombre del formControl			

			for(const name_control in this.formConsulta.controls){

				if ( this.formConsulta.controls[name_control].status == "INVALID"){

					//errores.push(GLOBAL.descriptionFields[name_control]);
					errores.push(name_control);

					// Lo marcamos como touched, para que los controles
					// se pongan con el estilo error
					this.formConsulta.controls[name_control].markAsTouched();
				}				
			}
		} 

		if ( !errores.length ) {

            this.show_spinner = true;

            this._recaptchaV3Service.execute('importantAction')
            .subscribe((token: string) => {

                // Si obtenemos el token significa que el bot no hace clic en el botón y es un usuario válido.
                if ( token )
                    this.enviarConsulta();
            });
        }
	}

	enviarConsulta(){

		this.consulta = {
			id_inmobiliaria : this.inmobiliaria.id,
			href: window.location.href,
			origen: GLOBAL.IS_WEB ? this.dominio : 'App movil inmobiliaria',
			asunto: this.formConsulta.get('asunto').value ? this.formConsulta.get('asunto').value : 'Consulta desde la pagina de contactos',
			nombre: this.formConsulta.get('nombre').value,
			email: this.formConsulta.get('email').value,
			celular: '-',
			mensaje: this.formConsulta.get('mensaje').value
		};

		this._inmobiliariasService.sendConsultaInmobiliaria(this.inmobiliaria.id, this.consulta).subscribe(
			(response)=>{
				this.limpiarFormConsulta();
				this.showMensaje('success');
				this.show_spinner = false;

                this._analyticsService.sendEvento('enviar_consulta_inmobiliaria', 'comunicacion', 'El usuario envió una consulta a la inmobiliaria');
			},
			(error)=>{
				console.log(error);
				this.showMensaje('error');
				this.show_spinner = false;
			});
	}

	limpiarFormConsulta(){

		this.formConsulta.reset();
	}

	showMensaje(valor='none') {

		this.show_mensaje = valor;
	}
}