import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

declare let gtag: any; // Declare ga as a function

const paginas = [
                    { nombre: 'buscador', titulo: 'Buscador' },
                    { nombre: 'nosotros', titulo: 'Nosotros' },
                    { nombre: 'servicios', titulo: 'Servicios' },
                    { nombre: 'contactos', titulo: 'Contactos' },
                    { nombre: 'inmueble', titulo: 'Inmueble' }
                ];

@Injectable()

export class AnalyticsService {

    private subscriptionEventosRouter: Subscription;
    private active: boolean;
    private id_analytics: string;

	constructor(private _router: Router) {

        this.active = false;
        this.id_analytics = null;
    }

    activate(id_analytics=null) {

        if ( ! id_analytics ) return;

        this.id_analytics = id_analytics;
        this.active = true;

        this.insertTagScriptGoogleAnalytics()
			.then((success)=>{

				return success ? this.insertTagScriptToHTMLConfigGoogleAnalytics() : false;
			})
			.then((success)=>{

				if ( success )
					this.setSubscripcionDeLosCambioEnElRouter();
			})
			.catch((error)=>{
				console.log(error);
			});
    }

    disactivate() {

        this.active = false;
    }

    isActive(){

        return this.active;
    }

    insertTagScriptGoogleAnalytics(): Promise<boolean>{

        return new Promise((resolve, reject)=>{

            if ( !this.id_analytics )
                reject(new Error('No existe el id de seguimiento de Google Analytics.'));

            const node = document.createElement('script');
            node.src = 'https://www.googletagmanager.com/gtag/js?id='+this.id_analytics;
            node.type = 'text/javascript';
            node.async = true;
            node.charset = 'utf-8';

            document.getElementsByTagName('head')[0].appendChild(node);

            resolve(true);
	    });
	}

	insertTagScriptToHTMLConfigGoogleAnalytics(): Promise<boolean>{

        return new Promise((resolve, reject)=>{
        
            if ( !this.id_analytics )
                reject(new Error('No existe el id de seguimiento de Google Analytics.'));


            const script = document.createElement('script');

            script.innerHTML =
                ` window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${this.id_analytics}', { 'send_page_view': false }); `;

            // gtag('config', '${id_analytics}', { 'app_name': 'inmobiliaria_${this.inmobiliaria.id}' }); `;

            // Si no quieres que el fragmento envíe un hit de página vista a Google Analytics, puedes asignar el valor false al parámetro send_page_view:
            // hice esto porque sino mandaba siempre el titulo de la pagina
            // y asi lo hago manualmente cuando entra a la pagina y cuando cambia el router

            document.getElementsByTagName('head')[0].appendChild(script);

            resolve(true);
		});
	}

    setSubscripcionDeLosCambioEnElRouter(){

        if ( this.isActive() && !this.subscriptionEventosRouter ) {

            // Le mando la primer pagina, para que la envie a Google Analytics
            this.sendPageView(this._router.url);

            // Subscripcion a los cambios del router para enviar las paginas vistas a Google Analytics
            this.subscriptionEventosRouter = this._router.events.subscribe(event => {
                                                
                                                if ( event instanceof NavigationEnd )
                                                    this.sendPageView(event.url);                                                
                                            });
        }
    }

    unsubscribeCambiosDelRouter() {

        if ( this.subscriptionEventosRouter ) {
            // --- clear our observable subscription
            this.subscriptionEventosRouter.unsubscribe();
        }
    }

    /**
    * Crea una vista de pagina personalizada para enviar a Google Analytics
    * @param url la url del router
    */
    sendPageView(url: string = null) {
        
        this.getTituloDeLaPagina(url)
            .then((titulo)=>{

                gtag('config', this.id_analytics, {
                    'page_title': titulo,
                    'page_path': url
                });
            });

        // Si es App podria mandar este evento
        // gtag('event', 'screen_view', { 'screen_name': event.urlAfterRedirects });
    }

    getTituloDeLaPagina(url: string = null): Promise<string>{

        return new Promise((resolve)=>{

            if ( url == '/' )
                resolve('Página de inicio');

            for(let i=0; i < paginas.length; i++) {

                const pagina = paginas[i];

                if ( url.indexOf(pagina.nombre) >= 0 ) {

                    resolve(pagina.titulo);
                    break;
                }
            }
        });
    }

    /**
    * Crear un evento personalizado para enviar a Google Analytics
    * @param eventAction es la cadena correspondiente a la acción del evento en los informes "Eventos" de Google Analytics.
    * @param eventCategory es la cadena correspondiente a la categoría del evento.
    * @param eventLabel es la cadena correspondiente a la etiqueta del evento.
    * @param eventValue es un número entero positivo que corresponde al valor del evento.
    */
    sendEvento(eventAction: string, eventCategory: string = null, eventLabel: string = null, eventValue: number = null) {
       
        if ( !this.active ) return;

        const eventParameters = {
            'event_category': eventCategory ? eventCategory : undefined,
            'event_label': eventLabel ? eventLabel : undefined,
            'value': eventValue ? eventValue : undefined
        };

        gtag('event', eventAction, eventParameters);
    }
}