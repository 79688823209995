import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';


@Component({
	selector: 'app-slider-imagenes',
	templateUrl: './slider-imagenes.component.html',
	styleUrls: ['./slider-imagenes.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class SliderImagenesComponent{

	@Input() imagenes: Array<any>;
	@Input() url_imagenes: string;
	@Input() tiempoPausa: number;
	@Input() velocidadEfecto: number;
	@Input() activateCarousel: 'true' | 'false' | boolean;
	@Input() show_buttons_nav: 'true' | 'false' | boolean;
	@Input() show_indicators: 'true' | 'false' | boolean;

	private timerRef: any = null;
	public pause: boolean;

	public currentSlide: number;
	public previousSlide: number;
	public slidesCount: number;

	constructor(private _cdRef: ChangeDetectorRef){

		//VALORES POR DEFECTO
		this.imagenes = [];
		this.pause = false;

		this.currentSlide = 1;
		this.slidesCount = 1;

		this.tiempoPausa = 10; // segundos
		this.velocidadEfecto = 1000;
		this.activateCarousel = true;
		this.show_buttons_nav = true;
		this.show_indicators = true;
	}

	ngOnChanges(change){

		if ( typeof this.activateCarousel == 'string' )
			this.activateCarousel =  this.activateCarousel == 'true' ? true : false;

		if ( typeof this.show_buttons_nav == 'string' )
			this.show_buttons_nav =  this.show_buttons_nav == 'true' ? true : false;

		if ( typeof this.show_indicators == 'string' )
			this.show_indicators =  this.show_indicators == 'true' ? true : false;

		this.slidesCount = this.imagenes.length;
		this.playCarousel();
	}

    ngAfterViewInit(){

        const slide_activo = '#slide_'+this.currentSlide;

        let sli = document.querySelector(slide_activo);
        sli?.classList.add('slide-active');

        let txt = document.querySelector(slide_activo +' .slide-texto');
        txt?.classList.add('fadeInUp');
    }

	ngOnDestroy(){

		this.clearInterval();
	}

	detectChanges(){

		this._cdRef.detectChanges();
	}

	isTouchDevice(){

		return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
	}

	playCarousel(){

		if ( this.activateCarousel && this.imagenes.length > 1 ) {

			this.clearInterval();

			this.activarSlide();

			// Ahora creo la rutina
			// --------------------
			this.timerRef = setInterval(()=>{

				if ( !this.pause && document.visibilityState == "visible" ) {
					// Paso a la siguiente slide, si llego al ultimo, vuelvo al principio
					this.previousSlide = this.currentSlide;
					this.currentSlide = (this.currentSlide + 1 > this.slidesCount ) ? 1 : this.currentSlide + 1;

					this.detectChanges();

					this.activarSlide();
				}

		    }, this.tiempoPausa * 1000);
		}
	}

    activarSlide(){

        let spa = document.querySelector('.slider-inner .slide.slide-previous-active');
        spa?.classList.add('clase-temp');
        let sa = document.querySelector('.slider-inner .slide.slide-active');
        sa?.classList.add('slide-previous-active');

        let sct = document.querySelector('.slider-inner .slide.clase-temp');
        sct?.classList.remove('slide-previous-active');
        sct?.classList.remove('clase-temp');

        let st = document.querySelector('.slider-inner .slide .slide-texto');
        st?.classList.remove('fadeInUp');
        sa?.classList.remove('slide-active');

        const slide_activo = '#slide_'+this.currentSlide;

        let act = document.querySelector(slide_activo);
        act?.classList.add('slide-active');

        let acttex = document.querySelector(slide_activo+' .slide-texto');
        acttex?.classList.add('fadeInUp');
    }

    previous(){

    	this.previousSlide = this.currentSlide;
		this.currentSlide = (this.currentSlide - 1) < 1  ? this.slidesCount : this.currentSlide -1;

    	this.detectChanges();
		this.playCarousel();
	}

	next(){

		this.previousSlide = this.currentSlide;
		this.currentSlide = (this.currentSlide + 1 > this.slidesCount ) ? 1 : this.currentSlide + 1;

		this.detectChanges();
		this.playCarousel();
	}

	private clearInterval(){

	    if (this.timerRef) {
	      	clearInterval(this.timerRef);
	      	this.timerRef = null;
	    }
	}
}
