// Funcion que devuelve una cadena, que describe el error que llega por parametro
// e es un objeto de tipo error, los cuales devuelve las solicitudes http
// de los diferentes servicios.
function getMessageError(e){

	const msg_error = e && e.error && e.error.message ? e.error.message : 'Status: '+ e.status +'  '+ e.statusText +', Mensaje: '+ e.message;

	return msg_error;
}

const URL_SERVER = 'https://api.mardelinmueble.com';

const ESTADO_MENSAJE_ENVIADO = 1;
const ESTADO_MENSAJE_RECIBIDO = 2;
const ESTADO_MENSAJE_VISTO = 3;

const TIPO_AGENTE = 1;
const TIPO_USUARIO = 2;

export enum TIPO_VISTA {
	GRILLA = 'grilla',
	LISTA = 'lista',
	MAPA = 'mapa'
}

export var identity: any;
export var inmobiliaria: any;
export var web: any;
export var dominio: string;
export var IS_WEB: boolean;

const CAMPOS_CHECKBOX = [];
const TIPOS_OPERACION = [];
const TIPOS_INMUEBLE = [];
const CIUDADES = [];
const NOMBRES_BARRIOS = [];
const PRECIOS_VENTA = [];
const PRECIOS_ALQUILER = [];
const SUPERFICIES_COCHERA = [];
const SUPERFICIES_LOCAL = [];
const SUPERFICIES_GALPON = [];
const SUPERFICIES_TERRENO = [];
const EXPENSAS = [];

export var GLOBAL = {
	identity: identity,
	inmobiliaria: inmobiliaria,
	web: web,
	dominio: dominio,
	url_server_chat: 'https://chat.mardelinmueble.com',
	url_server: URL_SERVER,
	url_back_end: URL_SERVER + '/v3/',
	url_images_agentes: URL_SERVER + '/uploads/agentes/',
	url_images_inmobiliarias: URL_SERVER + '/uploads/inmobiliarias/',
	url_images_inmuebles: URL_SERVER + '/uploads/inmuebles/',
	url_thumbnails_inmuebles: URL_SERVER + '/uploads/inmuebles/thumbnails/',
	url_webs: URL_SERVER + '/uploads/webs/',
	url_webs_default: URL_SERVER + '/uploads/webs/default/',
	getMessageError,
	cantMaximaCaracteresTitulo: 50,
	TIPO_AGENTE,
	TIPO_USUARIO,
	ESTADO_MENSAJE_ENVIADO,
	ESTADO_MENSAJE_RECIBIDO,
	ESTADO_MENSAJE_VISTO,
    IS_WEB: IS_WEB,
    CAMPOS_CHECKBOX,
    TIPOS_OPERACION,
    TIPOS_INMUEBLE,
    CIUDADES,
    NOMBRES_BARRIOS,
    PRECIOS_VENTA,
    PRECIOS_ALQUILER,
    SUPERFICIES_COCHERA,
    SUPERFICIES_LOCAL,
    SUPERFICIES_GALPON,
    SUPERFICIES_TERRENO,
    EXPENSAS
}