<div class="container p-t-20 p-b-20">		
		
	<div class="page-wrapper">

        <div class="images">

            <ng-template [ngIf]="imagenes && imagenes.length > 0" [ngIfElse]="imagenNosotrosDefault">
                <app-carousel-imagenes 
                    [pathDirImages]="url_web_inmobiliaria"
                    [images]="imagenes"
                    [width]="'100%'"
                    [height]="'100%'"
                    [object_fit]="'cover'"
                    [show_zoom_button]="false"
                    [show_nav_buttons]="false"
                    [show_indicators]="true"
                ></app-carousel-imagenes>
            </ng-template>

            <ng-template #imagenNosotrosDefault>
                <img src="{{ imagen_default_nosotros }}" alt="Nosotros">
            </ng-template>

        </div>
        
        <div class="data">
            <h1>{{ web.nosotros_titulo }}</h1>

            <p [innerHtml]="web.nosotros_texto"></p>
        </div>

        <div class="staff" *ngIf="web.staff_nosotros && agentes.length > 0">
            <h1>{{ web.staff_titulo }}</h1>

            <div class="agente" *ngFor="let agente of agentes">

                <figure (click)="openChat(agente)">

                    <ng-template [ngIf]="agente.imagen" [ngIfElse]="imagenAgenteDefault">
                        <img src="{{ url_images_agentes + agente.imagen }}" alt="Agente inmobiliario">
                    </ng-template>

                    <ng-template #imagenAgenteDefault>
                        <img src="{{ imagen_default_agente }}" alt="Agente inmobiliario">
                    </ng-template>

                    <span class="agente-tooltip-invitacion-consulta">
                        Haga click para abrir la ventana de chat del agente.
                    </span>

                    <span class="agente-mensajes-no-leidos" *ngIf="agente.mensajes_no_leidos">
                        {{ agente.mensajes_no_leidos }}
                        <span class="agente-tooltip-mensajes-no-leidos">
                            Ud. tiene {{ agente.mensajes_no_leidos }} mensajes sin leer de {{ agente.nombre }} {{ agente.apellido }}.
                        </span>
                    </span>

                    <span class="agente-status online" *ngIf="agente.conectado"></span>
                </figure>

                <span class="nombre">{{ agente.nombre }} {{ agente.apellido }}</span>

                <span class="rol" *ngIf="agente.registro">Martillero y Corredor Público Reg. {{ agente.registro }}</span>

                <a title="email" class="email" href="mailto:{{agente.email}}">{{ agente.email }}</a>
                
                <a *ngIf="agente.celular" href="https://api.whatsapp.com/send?phone=54{{agente.celular}}" target="_blank" class="whatsapp" (click)="openWhatsApp(agente)">
                    <img src="../../../assets/images/logo-whatsapp.png">Enviar WhatsApp
                </a>
            </div>
        </div>

	</div>	
	
</div>