<div class="mapa-inmuebles" [ngStyle]="{ 'width': width, 'height': height }">
<!--
    <agm-map #mapa
    	[latitude]="centroLatitud" 
    	[longitude]="centroLongitud"
        [mapTypeId]="mapa_tipo"
        [zoom]="zoom"
        [fitBounds]="con_limites"
        [ngClass]="'agm-map'"
        (mapReady)="mapaInicializado()"
        >

        <agm-marker *ngIf="marker"
            [latitude]="marker.latitud"
            [longitude]="marker.longitud"
            [markerDraggable]="false"
        >        
        </agm-marker>

    </agm-map>
-->
</div>