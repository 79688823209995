<div class="container p-t-20 p-b-20">

	<!-- Buscador -->
	<div class="row search">

		<div class="col-lg-3 head">
			<i class="mdi mdi-search"></i>
			Buscador
		</div>

        <div class="col-lg-3 form-group">
            <label class="control-label">Tipo de operación</label>

            <select [formControl]="fcTipoOperacion" class="form-control custom-select">
                <option value="">Todos</option>
                <option *ngFor="let tipo of tipos_operacion" value="{{tipo.id}}" >
                    {{tipo.nombre}}
                </option>
            </select>
        </div>
        <div class="col-lg-3 form-group">
            <label class="control-label">Tipo de inmueble</label>
            <select [formControl]="fcTipoInmueble" class="form-control custom-select">
                <option value="">Todos</option>
                <option *ngFor="let tipo of tipos_inmueble" value="{{tipo.id}}">
                    {{tipo.nombre}}
                </option>
            </select>
        </div>

        <div class="col-lg-2 form-group" *ngIf="web.buscador_x_precio === 'predefinidos' && fcTipoOperacion.value > 0">
            <label class="control-label">Precios</label>

            <select [formControl]="fcPrecioVenta" class="form-control custom-select" *ngIf="fcTipoOperacion.value==1">
                <option value="">Seleccione</option>

                <option *ngFor="let precio of precios_venta" value="{{precio.id}}">
                    {{precio.nombre}}
                </option>
            </select>

            <select [formControl]="fcPrecioAlquiler" class="form-control custom-select" *ngIf="fcTipoOperacion.value>1">
                <option value="">Seleccione</option>

                <option *ngFor="let precio of precios_alquiler" value="{{precio.id}}">
                    {{precio.nombre}}
                </option>
            </select>
        </div>

        <div class="col-lg-2 form-group" *ngIf="web.buscador_x_precio === 'libre' && fcTipoOperacion.value > 0">
            <label class="control-label">Precio desde</label>
            <input type="text" [formControl]="fcPrecioDesde" class="form-control precios" (keypress)="allowOnlyInteger($event)" placeholder="desde">
        </div>
        <div class="col-lg-2 form-group" *ngIf="web.buscador_x_precio === 'libre' && fcTipoOperacion.value > 0">        
            <label class="control-label">Precio hasta</label>
            <input type="text" [formControl]="fcPrecioHasta" class="form-control precios" (keypress)="allowOnlyInteger($event)" placeholder="hasta...">
        </div>

        <!-- Casa -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcAmbientes">
            <label class="control-label">Ambientes</label>

            <select [formControl]="fcAmbientes" class="form-control custom-select">
                <option value="">Todos</option>
                <option value="1">1 ambiente</option>
                <option value="2">2 ambientes</option>
                <option value="3">3 ambientes</option>
                <option value="4">4 ambientes</option>
                <option value="5">5 ambientes</option>
                <option value="6">6 ambientes</option>
                <option value="7">7 ambientes</option>
                <option value="8">8 ambientes</option>
                <option value="9">9 ambientes</option>
                <option value="10">+9 ambientes</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcDormitorios">
            <label class="control-label">Dormitorios</label>

            <select [formControl]="fcDormitorios" class="form-control custom-select">
                <option value="">Todos</option>
                <option value="1">1 dormitorio</option>
                <option value="2">2 dormitorios</option>
                <option value="3">3 dormitorios</option>
                <option value="4">4 dormitorios</option>
                <option value="5">5 dormitorios</option>
                <option value="6">6 dormitorios</option>
                <option value="7">7 dormitorios</option>
                <option value="8">8 dormitorios</option>
                <option value="9">9 dormitorios</option>
                <option value="10">+9 dormitorios</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcBanos">
            <label class="control-label">Baños</label>

            <select [formControl]="fcBanos" class="form-control custom-select">
                <option value="">Todas</option>
                <option value="1">1 baño</option>
                <option value="2">2 baños</option>
                <option value="3">3 baños</option>
                <option value="4">4 baños</option>
                <option value="5">5 baños</option>
                <option value="6">6 baños</option>
                <option value="7">7 baños</option>
                <option value="8">8 baños</option>
                <option value="9">9 baños</option>
                <option value="10">+9 baños</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcCocheras">
            <label class="control-label">Cocheras</label>

            <select [formControl]="fcCocheras" class="form-control custom-select">
                <option value="">Todas</option>
                <option value="1">1 cochera</option>
                <option value="2">2 cocheras</option>
                <option value="3">+2 cocheras</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcPlantas">
            <label class="control-label">Plantas</label>

            <select [formControl]="fcPlantas" class="form-control custom-select">
                <option value="">Todas</option>
                <option value="1">1 planta</option>
                <option value="2">2 plantas</option>
                <option value="3">+2 plantas</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcDependencia">
            <label class="control-label">Dependencia</label>

            <select [formControl]="fcDependencia" class="form-control custom-select">
                <option value="">Seleccione</option>
                <option value="true">Con dependencia</option>
                <option value="false">Sin dependencia</option>
            </select>
        </div>
        <!-- Si es Cochera -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcSupCochera">
            <label class="control-label">Sup.Cubierta</label>

            <select [formControl]="fcSupCochera" class="form-control custom-select">
                <option value="">Todas</option>
                <option *ngFor="let sup of superficies_cochera" value="{{sup.id}}">{{sup.nombre}}</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcTipoCobertura">
            <label class="control-label">Cobertura</label>

            <select [formControl]="fcTipoCobertura" class="form-control custom-select">
                <option value="">Todas</option>
                <option value="Cubierta">Cubierta</option>
                <option value="Descubierta">Descubierta</option>
                <option value="Semicubierta">Semicubierta</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcTipoCoche">
            <label class="control-label">Tipo coche</label>

            <select [formControl]="fcTipoCoche" class="form-control custom-select">
                <option value="">Todos</option>
                <option value="Chico">Chico</option>
                <option value="Mediano">Mediano</option>
                <option value="Grande">Grande</option>
                <option value="Doble">Doble</option>
            </select>
        </div>
        <!-- Comercios -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcSupLocal">
            <label class="control-label">Sup. local</label>

            <select [formControl]="fcSupLocal" class="form-control custom-select">
                <option value="">Todas</option>
                <option *ngFor="let sup of superficies_local" value="{{sup.id}}">{{sup.nombre}}</option>
            </select>
        </div>
        <!-- Departamentos -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcDisposicion">
            <label class="control-label">Disposición</label>

            <select [formControl]="fcDisposicion" class="form-control custom-select">
                <option value="">Todas</option>
                <option value="Frente">Frente</option>
                <option value="Contrafrente">Contrafrente</option>
                <option value="Lateral">Lateral</option>
                <option value="Interno">Interno</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcExpensas">
            <label class="control-label">Expensas</label>

            <select [formControl]="fcExpensas" class="form-control custom-select">
                <option value="">Todas</option>
                <option *ngFor="let expensa of expensas" value="{{expensa.id}}">{{expensa.nombre}}</option>
            </select>
        </div>
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcAptoProfesional">
            <label class="control-label">Apto profesional</label>

            <select [formControl]="fcAptoProfesional" class="form-control custom-select">
                <option value="">Todos</option>
                <option value="true">Si</option>
                <option value="false">No</option>
            </select>
        </div>
        <!-- galpon -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcSupGalpon">
            <label class="control-label">Sup. Cubierta</label>

            <select [formControl]="fcSupGalpon" class="form-control custom-select">
                <option value="">Todas</option>
                <option *ngFor="let sup of superficies_galpon" value="{{sup.id}}">{{sup.nombre}}</option>
            </select>
        </div>
        <!-- Locales -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcSupCubiertaLocal">
            <label class="control-label">Sup. Cubierta</label>

            <select [formControl]="fcSupCubiertaLocal" class="form-control custom-select">
                <option value="">Todas</option>
                <option *ngFor="let sup of superficies_local" value="{{sup.id}}">{{sup.nombre}}</option>
            </select>
        </div>
        <!-- terreno -->
        <div class="col-lg-2 form-group" *ngIf="searchFields.fcSupTerreno">
            <label class="control-label">Sup. terreno</label>

            <select [formControl]="fcSupTerreno" class="form-control custom-select">
                <option value="">Todas</option>
                <option *ngFor="let sup of superficies_terreno" value="{{sup.id}}">{{sup.nombre}}</option>
            </select>
        </div>

        <div class="col-lg-3 form-group" *ngIf="searchFields.fcCiudad">
            <label class="control-label">Localidad</label>
            <select #ciudad [formControl]="fcCiudad" class="form-control custom-select" (change)="changeSelectCiudad(ciudad.value)">
                <option value="">Todas</option>
                <option *ngFor="let ciudad of ciudades" value="{{ciudad.id}}">
                    {{ciudad.nombre}}
                </option>
            </select>
        </div>

        <div class="col-lg-2 form-group" *ngIf="searchFields.fcBarrios">
            <label class="control-label">Barrio</label>

            <select [formControl]="fcBarrios" class="form-control custom-select">
                <option value="">Todos</option>
                <option *ngFor="let barrio of barrios" value="{{barrio.id}}">{{barrio.nombre}}</option>
            </select>
        </div>

        <div class="col-lg-2 form-group f-a-b">
            <button type="button" class="form-button" (click)="doSearchConParametros()">
				Buscar
			</button>
    	</div>

	</div>

    <!-- Seccion Resultados -->
	<div class="result" id="resultado">

		<div class="title">
			<h3>Propiedades encontradas</h3>
		</div>

        <div class="buscador-mensaje" *ngIf="esperandoRespuestaDelBuscador">
            <i class="mdi mdi-magnify"></i>
            <span>Buscando <span class="punto">.</span><span class="punto">.</span><span class="punto">.</span> espere por favor.</span>
        </div>

        <div class="buscador-mensaje" *ngIf="!esperandoRespuestaDelBuscador && inmuebles.length <= 0">
            <i class="mdi mdi-information-outline"></i>
            <span>Lo lamentamos ! pero no se encontraron inmuebles con estas características.</span>
        </div>

		<article class="inmueble" *ngFor="let inmueble of inmuebles">

			<div class="content">
				<h1><a [routerLink]="['/inmueble/', inmueble.id]" title="Ver detalles">{{ inmueble.titulo }}</a></h1>

				<figure>
          <ng-container [ngSwitch]="inmueble.id_estado_aviso">
            <div class="inmueble-estado reservado" *ngSwitchCase="2">
              Reservado
            </div>
            <div class="inmueble-estado vendido" *ngSwitchCase="3">
              Vendido
            </div>
            <div class="inmueble-estado alquilado" *ngSwitchCase="4">
              Alquilado
            </div>
          </ng-container>

					<a [routerLink]="['/inmueble/', inmueble.id]" title="Ver detalles">
            <ng-template [ngIf]="inmueble.imagen_principal" [ngIfElse]="imagenInmuebleDefault">
                <img src="{{ url_thumbnails_inmuebles + inmueble.imagen_principal }}">
            </ng-template>

            <ng-template #imagenInmuebleDefault>
                <img src="{{ imagen_default_inmueble }} ">
            </ng-template>
          </a>

          <div class="mensajes-importantes">
            <span *ngIf="inmueble.urgente">Urgente</span>
            <span *ngIf="inmueble.oportunidad">Oportunidad</span>
            <span *ngIf="inmueble.apto_credito">Apto crédito</span>
            <span *ngIf="inmueble.retasado">Retasado</span>
          </div>

					<figcaption> {{ inmueble.tipo_operacion }} </figcaption>
				</figure>

				<div class="detail">
					<h5 class="price" >

                        <ng-template [ngIf]="!inmueble.no_publicar_precio && inmueble.precio && inmueble.precio > 0" [ngIfElse]="sinPrecio">
                            <span *ngIf="inmueble.moneda == 1">$</span>
                            <span *ngIf="inmueble.moneda == 2">U$D</span>

    						{{ inmueble.precio | number: '1.0' }}
						</ng-template>

                        <ng-template #sinPrecio>
                            Consulte su valor
                        </ng-template>

						<small> - {{ inmueble.tipo_inmueble }}</small>
					</h5>

					<div class="descripcion">
						<p [innerHtml]="inmueble.descripcion"></p>
						<a class="more-details" [routerLink]="['/inmueble/', inmueble.id]">Más información <i class="fa fa-caret-right"></i></a>
					</div>
				</div>
			</div>

			<div class="footer">

                <!-- Detalles del tipo casa -->
                <ng-container *ngIf="inmueble.id_tipo_inmueble == 1">
                    <span *ngIf="inmueble.casa_ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.casa_ambientes}} ambientes</span>
                    <span *ngIf="inmueble.casa_dormitorios"><i class="mdi mdi-bed"></i>{{inmueble.casa_dormitorios}} dormitorios</span>
                    <span *ngIf="inmueble.casa_banos"><i class="mdi mdi-shower"></i>{{inmueble.casa_banos}} baños</span>
                    <span *ngIf="inmueble.casa_cocheras"><i class="mdi mdi-car"></i>{{inmueble.casa_cocheras}} cocheras</span>
                    <span *ngIf="inmueble.casa_plantas"><i class="mdi mdi-stairs"></i>Plantas: {{inmueble.casa_plantas}}</span>
                    <span *ngIf="inmueble.casa_sup_cubierta && inmueble.casa_sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.casa_sup_cubierta}} m²</span>
                    <span *ngIf="inmueble.casa_sup_terreno && inmueble.casa_sup_terreno > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. terreno: {{inmueble.casa_sup_terreno}} m²</span>
                    <!--
                    <span *ngIf="inmueble.long_frente && inmueble.long_frente > 0"><i class="mdi mdi-vector-line"></i>Long.frente:  {{inmueble.long_frente}} </span>
                    <span *ngIf="inmueble.long_fondo && inmueble.long_fondo > 0"><i class="mdi mdi-vector-line"></i>Long.fondo:  {{inmueble.long_fondo}}</span>
                    <span *ngIf="inmueble.estado"><i class="mdi mdi-sort-ascending"></i>Estado: {{inmueble.estado}} </span>
                    <span *ngIf="inmueble.tipo_techo"><i class="mdi mdi-home-outline"></i>Tipo techo: {{inmueble.tipo_techo}}</span>
                    <span *ngIf="inmueble.tipo_piso"><i class="mdi mdi-format-vertical-align-bottom"></i>Tipo piso: {{inmueble.tipo_piso}}</span>
                    -->
                </ng-container>
                <!-- Detalles del tipo cochera -->
                <ng-container *ngIf="inmueble.id_tipo_inmueble == 2">
                    <span *ngIf="inmueble.cochera_largo && inmueble.cochera_largo > 0"><i class="mdi mdi-vector-line"></i>Largo: {{inmueble.cochera_largo}} mt.</span>
                    <span *ngIf="inmueble.cochera_ancho  && inmueble.cochera_ancho > 0"><i class="mdi mdi-vector-line"></i>Ancho: {{inmueble.cochera_ancho}} mt.</span>
                    <span *ngIf="inmueble.cochera_altura && inmueble.cochera_altura > 0"><i class="mdi mdi-vector-line"></i>Altura: {{inmueble.cochera_altura}} mt.</span>
                    <span *ngIf="inmueble.cochera_sup_cubierta && inmueble.cochera_sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.cochera_sup_cubierta}} m²</span>
                    <span *ngIf="inmueble.cochera_tipo_cobertura"><i class="mdi mdi-garage-open"></i>Cobertura: {{inmueble.cochera_tipo_cobertura}}</span>
                    <span *ngIf="inmueble.cochera_tipo_coche"><i class="mdi mdi-car"></i>Coche: {{inmueble.cochera_tipo_coche}}</span>
                    <span *ngIf="inmueble.cochera_tipo_cochera"><i class="mdi mdi-car-side"></i>Tipo: {{inmueble.cochera_tipo_cochera}}</span>
                    <!--
                    <span *ngIf="inmueble.tipo_acceso"><i class="mdi mdi-arrow-up-bold-outline"></i>Acceso: {{inmueble.tipo_acceso}}</span>
                    <span *ngIf="inmueble.baulera"><i class="mdi mdi-check"></i>Con baulera</span>
                    -->
                </ng-container>
                <!-- Detalles del tipo comercio -->
                <ng-container *ngIf="inmueble.id_tipo_inmueble == 3">
                    <span *ngIf="inmueble.comercio_sup_local && inmueble.comercio_sup_local > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. local: {{inmueble.comercio_sup_local}}</span>
                    <span *ngIf="inmueble.comercio_sup_deposito && inmueble.comercio_sup_deposito > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. deposito: {{inmueble.comercio_sup_deposito}}</span>
                    <span *ngIf="inmueble.comercio_ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.comercio_ambientes}} ambientes</span>
                    <span *ngIf="inmueble.comercio_banos"><i class="mdi mdi-toilet"></i>{{inmueble.comercio_banos}} baños</span>
                    <span *ngIf="inmueble.comercio_cocheras"><i class="mdi mdi-car"></i>{{inmueble.comercio_cocheras}} cocheras</span>
                    <!--
                    <span *ngIf="inmueble.estado"><i class="mdi mdi-sort-ascending"></i>Estado: {{inmueble.estado}} </span>
                    <span *ngIf="inmueble.recaudacion_mensual && inmueble.recaudacion_mensual > 0"><i class="mdi mdi-currency-usd"></i>Recaudación: {{inmueble.recaudacion_mensual}}</span>
                    -->
                    <span *ngIf="inmueble.comercio_antiguedad_comercio"><i class="mdi mdi-calendar-clock"></i>Antiguedad: {{inmueble.comercio_antiguedad_comercio}}</span>
                </ng-container>
                <!-- Detalles del tipo departamento -->
                <ng-container  *ngIf="inmueble.id_tipo_inmueble == 4">
                    <span *ngIf="inmueble.dpto_ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.dpto_ambientes}} ambientes</span>
                    <span *ngIf="inmueble.dpto_dormitorios"><i class="mdi mdi-bed"></i>{{inmueble.dpto_dormitorios}} dormitorios</span>
                    <span *ngIf="inmueble.dpto_banos"><i class="mdi mdi-shower"></i>{{inmueble.dpto_banos}} baños</span>
                    <!--
                    <span *ngIf="inmueble.toilettes"><i class="mdi mdi-toilet"></i>{{inmueble.toilettes}} toilettes</span>
                    -->
                    <span *ngIf="inmueble.dpto_cocheras"><i class="mdi mdi-car"></i>{{inmueble.dpto_cocheras}} cocheras</span>
                    <span *ngIf="inmueble.dpto_sup_cubierta"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.dpto_sup_cubierta}} m²</span>
                    <span *ngIf="inmueble.dpto_sup_descubierta"><i class="mdi mdi-vector-difference-ab"></i>Sup. descubierta: {{inmueble.dpto_sup_descubierta}} m²</span>
                    <!--
                    <span *ngIf="inmueble.estado"><i class="mdi mdi-sort-ascending"></i>Estado: {{inmueble.estado}} </span>
                    -->
                    <span *ngIf="inmueble.dpto_disposicion"><i class="mdi mdi-compass-outline"></i>Disposición:  {{inmueble.dpto_disposicion}} </span>
                    <!--
                    <span *ngIf="inmueble.tipo_balcon"><i class="mdi mdi-gate"></i>Balcon: {{inmueble.tipo_balcon}}</span>
                    -->
                    <span *ngIf="inmueble.dpto_expensas"><i class="mdi mdi-cash-usd"></i>Expensas: {{inmueble.dpto_expensas}}</span>
                </ng-container>
                <!-- Detalles del tipo galpon -->
                <ng-container *ngIf="inmueble.id_tipo_inmueble == 5">
                    <span *ngIf="inmueble.galpon_sup_cubierta && inmueble.galpon_sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.galpon_sup_cubierta}} m²</span>
                    <span *ngIf="inmueble.galpon_sup_descubierta && inmueble.galpon_sup_descubierta > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. descubierta: {{inmueble.galpon_sup_descubierta}} m²</span>
                    <span *ngIf="inmueble.galpon_long_frente && inmueble.galpon_long_frente > 0"><i class="mdi mdi-vector-line"></i>Long. frente: {{inmueble.galpon_long_frente}} mt.</span>
                    <span *ngIf="inmueble.galpon_long_fondo && inmueble.galpon_long_fondo > 0"><i class="mdi mdi-vector-line"></i>Long. fondo: {{inmueble.galpon_long_fondo}} mt.</span>
                    <span *ngIf="inmueble.galpon_altura_techo && inmueble.galpon_altura_techo > 0"><i class="mdi mdi-home-outline"></i>Altura: {{inmueble.galpon_altura_techo}} mt.</span>
                    <span *ngIf="inmueble.galpon_banos"><i class="mdi mdi-toilet"></i>{{inmueble.galpon_banos}} baños</span>
                    <!--
                    <span *ngIf="inmueble.oficinas"><i class="mdi mdi-desk-lamp"></i>{{inmueble.oficinas}} oficinas</span>
                    -->
                    <span *ngIf="inmueble.galpon_cocheras"><i class="mdi mdi-car"></i>{{inmueble.galpon_cocheras}} cocheras</span>
                    <!--
                    <span *ngIf="inmueble.estado"><i class="mdi mdi-sort-ascending"></i>Estado: {{inmueble.estado}}</span>
                    -->
                </ng-container>
                <!-- Detalles del tipo local -->
                <ng-container *ngIf="inmueble.id_tipo_inmueble == 6">
                    <span *ngIf="inmueble.local_sup_cubierta && inmueble.local_sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.local_sup_cubierta}} m²</span>
                    <span *ngIf="inmueble.local_sup_descubierta && inmueble.local_sup_descubierta > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. descubierta: {{inmueble.local_sup_descubierta}} m²</span>
                    <span *ngIf="inmueble.local_long_frente && inmueble.local_long_frente > 0"><i class="mdi mdi-vector-line"></i>Long.frente:  {{inmueble.local_long_frente}} </span>
                    <span *ngIf="inmueble.local_long_fondo && inmueble.local_long_fondo > 0"><i class="mdi mdi-vector-line"></i>Long.fondo:  {{inmueble.local_long_fondo}}</span>
                    <!--
                    <span *ngIf="inmueble.banos"><i class="mdi mdi-toilet"></i>{{inmueble.banos}} baños</span>
                    <span *ngIf="inmueble.cocheras"><i class="mdi mdi-car"></i>{{inmueble.cocheras}} cocheras</span>
                    <span *ngIf="inmueble.plantas"><i class="mdi mdi-stairs"></i>Plantas: {{inmueble.plantas}}</span>
                    <span *ngIf="inmueble.estado"><i class="mdi mdi-sort-ascending"></i>Estado: {{inmueble.estado}} </span>
                    -->
                    <span *ngIf="inmueble.local_tipo_local"><i class="mdi mdi-store"></i>Tipo local: {{inmueble.local_tipo_local}}</span>
                </ng-container>
                <!-- Detalles del tipo obra nueva -->
                <ng-container *ngIf="inmueble.id_tipo_inmueble == 7">
                    <span *ngIf="inmueble.obra_estado_construccion"><i class="mdi mdi-chart-gantt"></i>Estado: {{inmueble.obra_estado_construccion}}</span>
                    <span *ngIf="inmueble.obra_construye"><i class="mdi mdi-office-building"></i>Construye: {{inmueble.obra_construye}}</span>
                    <span *ngIf="inmueble.obra_dirige"><i class="mdi mdi-teach"></i>Dirige: {{inmueble.obra_dirige}}</span>
                    <span *ngIf="inmueble.obra_administra"><i class="mdi mdi-site-map"></i>Administra: {{inmueble.obra_administra}}</span>
                    <!--
                    <span *ngIf="inmueble.f_fecha_inicio"><i class="mdi mdi-calendar-clock"></i>Inicio: {{inmueble.f_fecha_inicio}}</span>
                    <span *ngIf="inmueble.f_fecha_entrega"><i class="mdi mdi-calendar-check"></i>Entrega: {{inmueble.f_fecha_entrega}}</span>
                    -->
                </ng-container>
                <!-- Detalles del tipo oficina -->
                <ng-container *ngIf="inmueble.id_tipo_inmueble == 8">
                    <span *ngIf="inmueble.oficina_sup_oficina && inmueble.oficina_sup_oficina > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. oficina: {{inmueble.oficina_sup_oficina}} m²</span>
                    <!--
                    <span *ngIf="inmueble.sup_deposito && inmueble.sup_deposito > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. deposito: {{inmueble.sup_deposito}} m²</span>
                    -->
                    <span *ngIf="inmueble.oficina_ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.oficina_ambientes}} ambientes</span>
                    <span *ngIf="inmueble.oficina_banos"><i class="mdi mdi-shower"></i>{{inmueble.oficina_banos}} baños</span>
                    <!--
                    <span *ngIf="inmueble.toilettes"><i class="mdi mdi-toilet"></i>{{inmueble.toilettes}} toilettes</span>
                    -->
                    <span *ngIf="inmueble.oficina_cocheras"><i class="mdi mdi-car"></i>{{inmueble.oficina_cocheras}} cocheras</span>
                    <!--
                    <span *ngIf="inmueble.estado"><i class="mdi mdi-sort-ascending"></i>Estado: {{inmueble.estado}} </span>
                    -->
                    <span *ngIf="inmueble.oficina_expensas && inmueble.oficina_expensas > 0"><i class="mdi mdi-cash-usd"></i>Expensas: {{inmueble.oficina_expensas}}</span>
                </ng-container>
                <!-- Detalles del quinta -->
                <ng-container *ngIf="inmueble.id_tipo_inmueble == 9">
                    <span *ngIf="inmueble.quinta_ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.quinta_ambientes}} ambientes</span>
                    <span *ngIf="inmueble.quinta_dormitorios"><i class="mdi mdi-bed"></i>{{inmueble.quinta_dormitorios}} dormitorios</span>
                    <span *ngIf="inmueble.quinta_banos"><i class="mdi mdi-shower"></i>{{inmueble.quinta_banos}} baños</span>
                    <!--
                    <span *ngIf="inmueble.toilettes"><i class="mdi mdi-toilet"></i>{{inmueble.toilettes}} toilettes</span>
                    -->
                    <span *ngIf="inmueble.quinta_cocheras"><i class="mdi mdi-car"></i>{{inmueble.quinta_cocheras}} cocheras</span>
                    <span *ngIf="inmueble.quinta_plantas"><i class="mdi mdi-stairs"></i>Plantas: {{inmueble.quinta_plantas}}</span>
                    <span *ngIf="inmueble.quinta_sup_cubierta && inmueble.quinta_sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.quinta_sup_cubierta}} m²</span>
                    <span *ngIf="inmueble.quinta_sup_terreno && inmueble.quinta_sup_terreno > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. terreno: {{inmueble.quinta_sup_terreno}} m²</span>
                    <span *ngIf="inmueble.quinta_long_frente && inmueble.quinta_long_frente > 0"><i class="mdi mdi-vector-line"></i>Long.frente:  {{inmueble.quinta_long_frente}} </span>
                    <span *ngIf="inmueble.quinta_long_fondo && inmueble.quinta_long_fondo > 0"><i class="mdi mdi-vector-line"></i>Long.fondo:  {{inmueble.quinta_long_fondo}}</span>
                    <!--
                    <span *ngIf="inmueble.estado"><i class="mdi mdi-sort-ascending"></i>Estado: {{inmueble.estado}} </span>
                    <span *ngIf="inmueble.tipo_techo"><i class="mdi mdi-home-outline"></i>Tipo techo: {{inmueble.tipo_techo}}</span>
                    <span *ngIf="inmueble.tipo_piso"><i class="mdi mdi-format-vertical-align-bottom"></i>Tipo piso: {{inmueble.tipo_piso}}</span>
                    -->
                </ng-container>
                <!-- Detalles del terreno -->
                <ng-container *ngIf="inmueble.id_tipo_inmueble == 10">
                    <span *ngIf="inmueble.terreno_sup_terreno && inmueble.terreno_sup_terreno > 0"><i class="mdi mdi-vector-difference-ab"></i>Superficie: {{inmueble.terreno_sup_terreno}} m²</span>
                    <span *ngIf="inmueble.terreno_sup_construible && inmueble.terreno_sup_construible > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. construible: {{inmueble.terreno_sup_construible}} m²</span>
                    <span *ngIf="inmueble.terreno_long_frente && inmueble.terreno_long_frente > 0"><i class="mdi mdi-vector-line"></i>Long.frente:  {{inmueble.terreno_long_frente}} </span>
                    <span *ngIf="inmueble.terreno_long_fondo && inmueble.terreno_long_fondo > 0"><i class="mdi mdi-vector-line"></i>Long.fondo:  {{inmueble.terreno_long_fondo}}</span>
                    <!--
                    <span *ngIf="inmueble.fot && inmueble.fot > 0"><i class="mdi mdi-wall"></i>FOT: {{inmueble.fot}}</span>
                    <span *ngIf="inmueble.zonificacion"><i class="mdi mdi-map-search-outline"></i>Zonificacion: {{inmueble.zonificacion}}</span>
                    -->
                </ng-container>
                <!-- Detalles del PH Duplex -->
                <ng-container *ngIf="inmueble.id_tipo_inmueble == 11">
                    <span *ngIf="inmueble.pd_ambientes"><i class="mdi mdi-floor-plan"></i>{{inmueble.pd_ambientes}} ambientes</span>
                    <span *ngIf="inmueble.pd_dormitorios"><i class="mdi mdi-bed"></i>{{inmueble.pd_dormitorios}} dormitorios</span>
                    <span *ngIf="inmueble.pd_banos"><i class="mdi mdi-shower"></i>{{inmueble.pd_banos}} baños</span>
                    <!--
                    <span *ngIf="inmueble.toilettes"><i class="mdi mdi-toilet"></i>{{inmueble.toilettes}} toilettes</span>
                    -->
                    <span *ngIf="inmueble.pd_cocheras"><i class="mdi mdi-car"></i>{{inmueble.pd_cocheras}} cocheras</span>
                    <!--
                    <span *ngIf="inmueble.plantas"><i class="mdi mdi-stairs"></i>Plantas: {{inmueble.plantas}}</span>
                    -->
                    <span *ngIf="inmueble.pd_sup_cubierta && inmueble.pd_sup_cubierta > 0"><i class="mdi mdi-vector-difference-ba"></i>Sup. cubierta: {{inmueble.pd_sup_cubierta}} m²</span>
                    <span *ngIf="inmueble.pd_sup_descubierta && inmueble.pd_sup_descubierta > 0"><i class="mdi mdi-vector-difference-ab"></i>Sup. descubierta: {{inmueble.pd_sup_descubierta}} m²</span>
                    <!--
                    <span *ngIf="inmueble.estado"><i class="mdi mdi-sort-ascending"></i>Estado: {{inmueble.estado}} </span>
                    -->
                </ng-container>
                <!-- Detalles del Campo -->
                <ng-container *ngIf="inmueble.id_tipo_inmueble == 12">
                    <span *ngIf="inmueble.tipo_campo">Tipo de campo: {{inmueble.tipo_campo}} </span>
                    <span *ngIf="inmueble.cant_hectareas > 0">Hectáreas: {{inmueble.cant_hectareas}} ha</span>
                </ng-container>
                <!-- Detalles del Hotel -->
                <ng-container *ngIf="inmueble.id_tipo_inmueble == 13">
                    <span *ngIf="inmueble.tipo_hotel">Tipo de hotel: {{inmueble.tipo_hotel}} </span>
                    <span *ngIf="inmueble.estrellas && inmueble.estrellas > 0">Estrellas: {{inmueble.estrellas}}</span>
                    <span *ngIf="inmueble.habitaciones && inmueble.habitaciones > 0">Habitaciones: {{inmueble.habitaciones}}</span>
                    <span *ngIf="inmueble.pisos && inmueble.pisos > 0">Pisos: {{inmueble.pisos}}</span>
                </ng-container>
			</div>

		</article>

		<div class="footer" [ngClass]="{ hidden: !showDataResult }" >

            <div class="dataTables_info" *ngIf="showDataResult">
                Mostrado {{dataResultQuery.items_desde}} a {{dataResultQuery.items_hasta}} de {{dataResultQuery.items_total}} inmuebles
            </div>

            <div class="">
                <app-pagination #pagination
                    (eventSelectPage)="eventChangePagination($event)"
                >
                </app-pagination>
            </div>

		</div>
	</div>

</div>
