import { Component, ViewChild, ChangeDetectorRef, ViewContainerRef, ComponentRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { Observable, Subscription, forkJoin } from 'rxjs';

// Components
import { T001InmuebleStandardComponent } from '../inmueble-standard/inmueble-standard.component';
import { T001EmprendimientoComponent } from '../emprendimiento/emprendimiento.component';

// Servicios
import { GLOBAL } from '../../services/global';
import { InmueblesService } from '../../services/inmuebles.service';

@Component({
	selector: 'T001-inmueble',
	templateUrl: './inmueble.component.html',
	providers: [ InmueblesService ]
})

export class T001InmuebleComponent {

	public identity: any;
	public inmobiliaria: any;
    public web: any;
    public dominio: string;
	public id_inmueble: number;
	public inmueble: any;
	public agentes: Array<any>;

	public imagenes: Array<any>;
    public unidades: Array<any>;
	
	public url_inmueble: string;


    private componenteInmueble: ComponentRef<any>;
    public campos: any;

    @ViewChild("inmueble", {read: ViewContainerRef, static: false }) inmuebleContainer: ViewContainerRef;

	constructor(private _route: ActivatedRoute,
				private _router: Router,
				private _inmueblesService: InmueblesService,
                private _titleService: Title,
                private _metaService: Meta ) {

		this.identity = GLOBAL.identity;
		this.inmobiliaria = GLOBAL.inmobiliaria;
		this.web = GLOBAL.web;
        this.dominio =  GLOBAL.dominio;
		this.id_inmueble = null;
		this.inmueble = {};
		this.agentes = [];
        this.imagenes = [];
	}

	ngOnInit(){
		console.log('T001InmuebleComponent component cargado ... ');

		this._route.paramMap.subscribe((params) => {

			this.id_inmueble = params.has('id') ? +params.get('id') : null;

			if ( this.id_inmueble ) {

				this.getDatosInmueble().subscribe(
					(response)=>{

                        // Seteo los tags el index
                        this.setTitle();
                        this.setTagsHTML();

                        this.createComponenteSegunElTipoDeInmueble();
					},
					(error)=>{
						this._router.navigate(['/']);
					});
			}

		});
	}

    getDatosInmueble(): Observable<any>{

    	return new Observable((observer)=>{

			forkJoin([
				this._inmueblesService.getCheckboxCampos(),
				this._inmueblesService.getInmueble(this.inmobiliaria.id, this.id_inmueble),
				this._inmueblesService.getAgentesDeLaInmobiliariaByInmueble(this.inmobiliaria.id, this.id_inmueble)
            ]).subscribe((response)=>{

                //console.log('response: ', response);

				const success = response[1].success;

				// Si el inmueble existe y pertenece a la inmobiliaria
				if ( success ) {

                    this.campos = response[0].campos;

					this.inmueble = response[1].inmueble;
					this.imagenes = response[1].imagenes;
                    this.unidades = response[1].unidades;

					this.agentes = response[2].agentes;

					observer.next();
					observer.complete();

				} else {
					observer.error({ error: 'El inmueble solicitado no existe.' });
					observer.complete();
				}
			},
			(error)=>{
				observer.error(error);
				observer.complete();
			});
		});
	}

    createComponenteSegunElTipoDeInmueble(): Promise<any>{

		return new Promise(async(resolve)=>{


			this.getComponenteDelTipoInmueble(this.inmueble.id_tipo_inmueble)
				.then((componente)=>{

				    return this.setComponenteInmueble(componente);
				})
				.then(()=>{

					// Seteo propiedades del Inmueble
				    return this.setPropiedadesDelInmueble();
				})
				.then(()=>{

					resolve(true);
				})
				.catch((error)=>{
					//this.dialog.error('Oops ! se produjo el siguiente error: '+error);
					resolve(false);
				});
		});
    }

    getComponenteDelTipoInmueble(id_tipo_inmueble=null): Promise<any> {

		return new Promise((resolve, reject)=>{

			if ( !id_tipo_inmueble )
				reject(new Error('Faltan parámetros requeridos.'));

			switch(id_tipo_inmueble) {

                case 1 :
                case 2 :
				case 3 :
				case 4 :
				case 5 :
				case 6 : resolve(T001InmuebleStandardComponent); break;
				case 7 : resolve(T001EmprendimientoComponent); break;
				case 8 :
				case 9 :
				case 10 :
				case 11 :
                case 12 :
                case 13 : resolve(T001InmuebleStandardComponent); break;

				default:
					reject(new Error('El tipo de inmueble no es existe.'));
					break;
			}
		});
	}

    setComponenteInmueble(componente): Promise<any>{

		return new Promise((resolve, reject)=>{

			this.componenteInmueble = this.inmuebleContainer.createComponent(componente);

		    if ( this.componenteInmueble )
		    	resolve(true);
		    else
		    	reject(new Error('No pudo crearse el componente del inmueble.'));
		});
	}

    setPropiedadesDelInmueble(): Promise<any>{

		return new Promise((resolve, reject)=>{

			if ( ! this.componenteInmueble )
				reject(new Error('No existe el componente del inmueble.'));

            const propiedades = {
                campos: this.campos,
                inmueble: this.inmueble,
                imagenes: this.imagenes,
                agentes: this.agentes,
                unidades: this.unidades
            };

            this.componenteInmueble.instance.setPropiedades(propiedades);

			resolve(true);
		});
	}

    setTitle(){

		this._titleService.setTitle( this.inmueble.titulo );
	}

    setTagsHTML(){

        // La descripcion es la misma para ambos
        let descripcion = this.inmueble.descripcion.substring(0, 150);
        descripcion = descripcion.replace(/<[^>]+>/g, '');

        this.setTagsGenerales({ descripcion });
        this.setTagsRedesSociales({ descripcion });
    }

    setTagsGenerales(tags={ descripcion: ''}){

        const keys = [];

        keys.push( this.inmueble.tipo_operacion );
        keys.push( this.inmueble.tipo_inmueble );

        if ( this.inmueble.tipo_unidad )
            keys.push( this.inmueble.tipo_unidad );
		
        keys.push( this.inmueble.provincia );
        keys.push( this.inmueble.ciudad );

	    if ( this.inmueble.barrio_nombre  )
            keys.push( this.inmueble.barrio_nombre );


        this._metaService.updateTag({ name: 'description', content: tags.descripcion });
        this._metaService.updateTag({ name: 'keywords', content: keys.toString() });
    }

    setTagsRedesSociales(tags={ descripcion: ''}){
        
        if ( ! this.dominio ) return;

        let url_imagen = GLOBAL.url_images_inmuebles;

        url_imagen += this.inmueble.imagen_principal ? this.inmueble.imagen_principal : 'sin-imagen.jpg';

        const url = 'https://www.' + this.dominio + '/inmueble/'+ this.inmueble.id;
        const site = 'https://www.' + this.dominio;

        // Tags Facebook
        this._metaService.updateTag({ property: 'og:url', content: url });
        this._metaService.updateTag({ property: 'og:title', content: this.inmueble.titulo });
        this._metaService.updateTag({ property: 'og:description', content: tags.descripcion });
        this._metaService.updateTag({ property: 'og:image', content: url_imagen });
        this._metaService.updateTag({ property: 'og:image:alt', content: this.inmueble.titulo });
        this._metaService.updateTag({ property: 'og:site_name', content: site });

        // Tags Twitter
        this._metaService.updateTag({ name: 'twitter:url', content: url });
        this._metaService.updateTag({ name: 'twitter:title', content: this.inmueble.titulo });
        this._metaService.updateTag({ name: 'twitter:description', content: tags.descripcion });
        this._metaService.updateTag({ name: 'twitter:image', content: url_imagen });
        this._metaService.updateTag({ name: 'twitter:site', content: site });
    }
}