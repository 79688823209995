
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, BehaviorSubject } from 'rxjs';


// Inyectando el servicio al root, esto permite
// que el servicio este disponible en toda la app
// El inyector raiz ( root ) es el encargado de crear una 
// instancia de LocalStorageService 
@Injectable({
  providedIn: 'root',
})


export class LocalStorageService {

	// Los servicios no pueden producir eventos con eventEmitter, para eso
	// usamos estos Subjects que devuelven un observable
	// Un Subject es un objeto observable que puede manejar multiples subscripciones 
	// y ademas puede emitir eventos.
	//private countdownEndSource = new Subject<void>(); // http://reactive.io/rxjs
	//public countdownEnd$ = this.countdownEndSource.asObservable();

	// El behaviorSubject es como un Subject salvo por el tema del estado, el 
	// behaviorSubject siempre tiene un valor, por eso al crearlo hay que inicializarlo.
	// Al suscribirte a un behaviorSubject obtienes al ultimo valor disponible.
	private identitySubject = new BehaviorSubject<any>(null);
	public identity$ = this.identitySubject.asObservable();

	constructor(@Inject(PLATFORM_ID) private platformId: Object) {
		const identity = this.getIdentity();

		if ( identity )
			this.identitySubject.next(identity);
		else // Si no esta identificado creo un nuevo usuario
			this.setIdNuevoUsuario();
	}

	async setIdNuevoUsuario(){

		const id_usuario = await this.getIdentificacionUsuario();
		this.setIdentity({ usuario: id_usuario });
	}

	getIdentificacionUsuario(){

		return new Promise((resolve) => {

			const fecha_actual = Date.now();
		    
		    // Le agrego dos digitos al final por si las dudas :)
		    let random: any = Math.floor(Math.random() * 99);

		    random = ("0"+random).substr(-2);

		    const id = fecha_actual+random;

		    resolve(Number(id));
		});
	}

	setIdentity(data=null){

		this.setItem('mardelinmueble-user-identity', data);
	}

	getIdentity(){

		const identity = this.getItem('mardelinmueble-user-identity');

		if ( identity ){
			return JSON.parse(identity);
		} else {
			return null;
		}
	}

	setItem(key, value){

        if ( ! (isPlatformBrowser(this.platformId)) ) return;

		localStorage.setItem(key, JSON.stringify(value));

		// Si se esta modificando la clave : identity doy aviso
		// a los subscriptores que escuchan ese Observable
		if ( key == 'mardelinmueble-user-identity' )
			this.identitySubject.next(value);
	}

	getItem(key){

		return (isPlatformBrowser(this.platformId)) && key ? localStorage.getItem(key) : null;
	}

	clear(){

        if ( ! (isPlatformBrowser(this.platformId)) ) return;

		localStorage.clear();
		this.identitySubject.next(null);
	}
}