import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { ConfigService } from '../../../services/config.service';


let Hammer
// Client-side
try {
  Hammer = require('hammerjs')
}
// SSR, (renderizado del lado del servidor ) pass el objeto vacio
catch (err) {
  Hammer = {}
}

@Component({
	selector: 'app-carousel-inmuebles',
	templateUrl: './carousel-inmuebles.component.html',
	styleUrls: ['./carousel-inmuebles.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class CarouselInmueblesComponent{
	
	@Input() inmuebles: Array<any>;
	@Input() url_imagenes_inmuebles: string;
	@Input() tiempoPausa: number;
	@Input() velocidadEfecto: number;
	@Input() activateCarousel: 'true' | 'false' | boolean;

	private timerRef: any = null;
	public show_buttons: boolean;
	public paused: boolean;

	public currentSlide: number;
	public previousSlide: number;
	public inmueblesBySlide: number;
	public slidesCount: number;
	// la propiedad slides, es solo una variable iterable para crear los
	// indicadores ( marcas ) de los slides y el slide actual
	public slides: any;

	public imagen_default_inmueble: string;

	public desplazar: number;

	/* para mobile */
	private touchMoving: boolean;
	private agregarUltimoAlComienzo: boolean;
	private animacion_en_curso: boolean;

    @ViewChild("carouselInmuebles", { static: false }) carouselInmuebles: ElementRef;
    @ViewChild("carousel", { static: false }) carousel: ElementRef;

	constructor(private _cdRef: ChangeDetectorRef,
				private _configService: ConfigService,
                private _renderer: Renderer2){

		//VALORES POR DEFECTO
		this.inmuebles = [];
		this.show_buttons = true;
		this.paused = false;

		this.currentSlide = 1;
		this.inmueblesBySlide = 0;
		this.slidesCount = 1;

		this.tiempoPausa = 10000; // 10 segundos
		this.velocidadEfecto = 1000;
		this.activateCarousel = true;

		this.desplazar = 0;

		this.agregarUltimoAlComienzo = true;
		this.animacion_en_curso = false;
		
		this.slides = [];

		this.imagen_default_inmueble = this._configService.imagen_default_inmueble;
	}

	@HostListener("window:resize", ['$event']) onWindowResize(evento){
  
		this.init();
	}

	ngOnChanges(change){

		if ( typeof this.activateCarousel == 'string' )
			this.activateCarousel =  this.activateCarousel == 'true' ? true : false;

		this.init();
	}

	ngOnInit(){

		if ( this.isTouchDevice() )
			this.show_buttons = false;
	}

	ngOnDestroy(){

		this.clearInterval();
	}

	detectChanges(){

		this._cdRef.detectChanges();
	}

	isTouchDevice(){

		return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
	}

	init(){

		this.setPropiedadesElementos().subscribe(
			(success)=>{

				this.detectChanges();

				if ( this.activateCarousel )
		           	this.playCarousel();
			});        
	}

	// Este metodo se ejecuta primero que el inicio del efecto, porque
	// primero tengo que setear los valores de ancho del carousel-container,
	// carousel, e image
	setPropiedadesElementos(): Observable<boolean>{

		return new Observable((observer) => {
			// Tengo que forzar a reconocer los cambios en el DOM, 
			// porque sino no me reconoce los inmuebles
			this.detectChanges();

			// Seteo el ancho de la lista completa de slides
			// ---------------------------------------------

			this.inmueblesBySlide = 1;

            if ( window.innerWidth >= 768 )
				this.inmueblesBySlide = 2;

			if ( window.innerWidth >= 1024 )
			    this.inmueblesBySlide = 3;


			this.slidesCount = Math.ceil( this.inmuebles.length / this.inmueblesBySlide );

			// limpio el array
			this.slides = [];

			for(let i=0; i < this.slidesCount; i++) {

				this.slides[i] = this.inmueblesBySlide;

				if ( i == this.slidesCount - 1 )
					this.slides[i] = ( this.inmuebles.length % this.inmueblesBySlide );
			}

			//console.log('slides[] : ', this.slides);

		   	observer.next(true);
			observer.complete();
		});
	}

	onPanStart(event){

		this.touchMoving = true;

		// paro animacion aunque no deberia porque no esta en curso
		this.clearInterval();

        this._renderer.removeClass(this.carouselInmuebles.nativeElement, 'animate');

        this.desplazar = this.carouselInmuebles.nativeElement.getBoundingClientRect().width;
	}

	onPanMove(event){

		if ( !this.animacion_en_curso && ( event.direction == Hammer.DIRECTION_LEFT || event.direction == Hammer.DIRECTION_RIGHT ) ) {

			if ( event.direction == Hammer.DIRECTION_RIGHT && this.agregarUltimoAlComienzo ) { // --> Si es anterior

                let inmuebles = document.querySelectorAll('.carousel-inmuebles > .carousel > .inmueble') as NodeListOf<HTMLElement>;

                var first = inmuebles[0];
                var last = inmuebles[ inmuebles.length - 1];

                this._renderer.insertBefore(this.carousel.nativeElement, last, first);
                this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(-' + ( this.desplazar - event.distance ) + 'px)' );

				this.agregarUltimoAlComienzo = false;
			} 

			if ( event.direction == Hammer.DIRECTION_RIGHT && !this.agregarUltimoAlComienzo ) 
                this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(-' + ( this.desplazar - event.distance ) + 'px)' );			

			if ( event.direction == Hammer.DIRECTION_LEFT )
                this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(-' +  event.distance  + 'px)' );
		}
	}

	onPanEnd(event){

	  	if ( !this.animacion_en_curso && ( event.direction == Hammer.DIRECTION_LEFT || event.direction == Hammer.DIRECTION_RIGHT ) ) {

			if ( event.direction == Hammer.DIRECTION_LEFT ) { // <--
				this.previousSlide = this.currentSlide;
				this.currentSlide = (this.currentSlide + 1 > this.slidesCount ) ? 1 : this.currentSlide + 1;

				this.detectChanges();

				this.doEfectoNext();

				this.playCarousel();

			} else if ( event.direction == Hammer.DIRECTION_RIGHT ) { 
				this.previousSlide = this.currentSlide;
				this.currentSlide = (this.currentSlide - 1) < 1  ? this.slidesCount : this.currentSlide -1;

				this.detectChanges();

				this.animacion_en_curso = true;

                this._renderer.addClass(this.carousel.nativeElement, 'animate');
                this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(0px)' );

				this.animacion_en_curso = false;				

				this.playCarousel();
				
			}
		} else // Reinicio el efecto porque en el panstart lo pare
			this.playCarousel();

		this.touchMoving = false;
		this.agregarUltimoAlComienzo = true;
	}

	playCarousel(){	    

		if ( this.activateCarousel && this.slidesCount > 1 ) {

			this.clearInterval();

			// Ahora creo la rutina
			// --------------------
			this.timerRef = setInterval(()=>{

				if ( !this.touchMoving && document.visibilityState == "visible" ) {
					// Paso a la siguiente slide, si llego al ultimo, vuelvo al principio
					this.previousSlide = this.currentSlide;
					this.currentSlide = (this.currentSlide + 1 > this.slidesCount ) ? 1 : this.currentSlide + 1;
					
					this.detectChanges();

					this.doEfectoNext();
				}

		    }, this.tiempoPausa);
		}
	}

	previous(){

		this.previousSlide = this.currentSlide;
		this.currentSlide = (this.currentSlide - 1) < 1  ? this.slidesCount : this.currentSlide -1;

		this.detectChanges();

		this.doEfectoPrevious();
		this.playCarousel();
	}

	doEfectoPrevious(){

		this.animacion_en_curso = true;

        this.desplazar = this.carouselInmuebles.nativeElement.getBoundingClientRect().width;

		// Primero agrego todos los inmuebles del ultimo slide al comienzo del carousel
		for(let i=1; i <= this.inmueblesBySlide; i++) {

            let inmuebles = document.querySelectorAll('.carousel-inmuebles > .carousel > .inmueble') as NodeListOf<HTMLElement>;

            var first = inmuebles[0];
            var last = inmuebles[ inmuebles.length - 1];

            this._renderer.insertBefore(this.carousel.nativeElement, last, first);

			if ( i == this.inmueblesBySlide ) {

                this._renderer.removeClass(this.carousel.nativeElement, 'animate');
                this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(-' + this.desplazar + 'px)' );
			}
		}

		setTimeout(() => {

            this._renderer.addClass(this.carousel.nativeElement, 'animate');
            this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(0px)' );

			this.animacion_en_curso = false;
		}, 200);
	}

	next(){

		this.previousSlide = this.currentSlide;
		this.currentSlide = (this.currentSlide + 1 > this.slidesCount ) ? 1 : this.currentSlide + 1;

		this.detectChanges();

		this.doEfectoNext();
		this.playCarousel();
	}

	doEfectoNext(){

		this.animacion_en_curso = true;

		// Desplazo el carousel hacia la izquierda
        this.desplazar = this.carouselInmuebles.nativeElement.getBoundingClientRect().width;

        this._renderer.addClass(this.carousel.nativeElement, 'animate');
        this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(-' + this.desplazar + 'px)' );

		// Agrego todos los inmuebles del slide previo al final del carousel
		setTimeout(() => {
			
			for(let i=1; i <= this.inmueblesBySlide; i++) {
                        
                var first = <HTMLElement> document.querySelector('.carousel-inmuebles > .carousel > .inmueble:first-child');

                if ( first )
                    this._renderer.appendChild(this.carousel.nativeElement, first);

				if ( i == this.inmueblesBySlide ) {

                    this._renderer.removeClass(this.carousel.nativeElement, 'animate');
                    this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(0px)' );
				}
			}

			this.animacion_en_curso = false;
		}, 600);
	}

	private clearInterval(){

	    if (this.timerRef) {
	      	clearInterval(this.timerRef);
	      	this.timerRef = null;
	    }
	}
}