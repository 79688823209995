import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { Observable, Subscription, forkJoin } from 'rxjs';

// Servicios
import { GLOBAL } from '../../services/global';
import { ScrollService } from '../../services/scroll.service';
import { ConfigService } from '../../services/config.service';
import { InmobiliariasService } from '../../services/inmobiliarias.service';
import { InmueblesService } from '../../services/inmuebles.service';

// Validators
import { CustomValidators } from '../../validators/custom.validators';

@Component({
	selector: 'T001-inicio',
	templateUrl: './inicio.component.html',
	styleUrls: ['./inicio.component.css', '../../../assets/css/form.css'],
	providers: [InmueblesService],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class T001InicioComponent {

	public identity: any;
	public inmobiliaria: any;
	public web: any;
	
	public imagenes_seccion_inicio: Array<any>;
	public importantes: Array<any>;
	public url_imagenes_seccion_inicio: string;
	public url_thumbnails_inmuebles: string;
	
	public tipos_operacion: Array<any>;
	public tipos_inmueble: Array<any>;
    public ciudades: Array<any>;
	public barrios: Array<any>;
	public precios_venta: Array<any>;
	public precios_alquiler: Array<any>;
	public superficies_cochera: Array<any>
	public superficies_local: Array<any>;
	public expensas: Array<any>;
	public superficies_galpon: Array<any>;
	public superficies_terreno: Array<any>;
	public searchFields: any;

	// Esta propiedad la tengo que mandar como parametro al componente buscador
	public searchPropertys: any;

	// Selects
	public fcTipoOperacion: UntypedFormControl;
	public fcTipoInmueble: UntypedFormControl;
    public fcCiudad: UntypedFormControl;
	public fcBarrios: UntypedFormControl;
	public fcPrecioVenta: UntypedFormControl;
	public fcPrecioAlquiler: UntypedFormControl;
    public fcPrecioDesde: UntypedFormControl;
    public fcPrecioHasta: UntypedFormControl;
	public fcAmbientes: UntypedFormControl;
	public fcDormitorios: UntypedFormControl;
	public fcBanos: UntypedFormControl;
	public fcCocheras: UntypedFormControl;
	public fcPlantas: UntypedFormControl;
	public fcDependencia: UntypedFormControl;
	public fcSupCochera: UntypedFormControl;
	public fcTipoCobertura: UntypedFormControl;
	public fcTipoCoche: UntypedFormControl;
	public fcSupLocal: UntypedFormControl;
	public fcDisposicion: UntypedFormControl;
	public fcExpensas: UntypedFormControl;
	public fcAptoProfesional: UntypedFormControl;	
	public fcSupGalpon: UntypedFormControl;
	public fcSupCubiertaLocal: UntypedFormControl;
	public fcSupTerreno: UntypedFormControl;

	private subTipoInmueble: Subscription;
	
	constructor(private _route: ActivatedRoute,
				private _router: Router,
				private _cdRef: ChangeDetectorRef,
                private _scrollService: ScrollService,
				private _configService: ConfigService,
				private _inmobiliariasService: InmobiliariasService,
				private _inmueblesService: InmueblesService ) {

		this.identity = GLOBAL.identity;
		this.inmobiliaria = GLOBAL.inmobiliaria;
		this.web = GLOBAL.web;

		this.imagenes_seccion_inicio = [];
		this.importantes = [];
		this.url_imagenes_seccion_inicio = GLOBAL.url_webs + GLOBAL.inmobiliaria.id + '/';
		this.url_thumbnails_inmuebles = GLOBAL.url_thumbnails_inmuebles;

		// Selects
		this.fcTipoOperacion = new UntypedFormControl('');
		this.fcTipoInmueble = new UntypedFormControl('');
        this.fcCiudad = new UntypedFormControl('');
		this.fcBarrios = new UntypedFormControl('');
		this.fcPrecioVenta = new UntypedFormControl('');
		this.fcPrecioAlquiler = new UntypedFormControl('');
        this.fcPrecioDesde = new UntypedFormControl('');
        this.fcPrecioHasta = new UntypedFormControl('');
		this.fcAmbientes = new UntypedFormControl('');
		this.fcDormitorios = new UntypedFormControl('');
		this.fcBanos =  new UntypedFormControl('');
		this.fcCocheras = new UntypedFormControl('');
		this.fcPlantas = new UntypedFormControl('');
		this.fcDependencia = new UntypedFormControl('');
		this.fcSupCochera = new UntypedFormControl('');
		this.fcTipoCobertura = new UntypedFormControl('');
		this.fcTipoCoche = new UntypedFormControl('');
		this.fcSupLocal = new UntypedFormControl('');
		this.fcDisposicion = new UntypedFormControl('');
		this.fcExpensas = new UntypedFormControl('');
		this.fcAptoProfesional = new UntypedFormControl('');
		this.fcSupGalpon = new UntypedFormControl('');
		this.fcSupCubiertaLocal = new UntypedFormControl('');
		this.fcSupTerreno = new UntypedFormControl('');

		this.tipos_operacion = [];
		this.tipos_inmueble = [];
        this.ciudades = [];
		this.precios_venta = [];
		this.precios_alquiler = [];
		this.superficies_cochera = [];
		// Este array lo uso para comercios y locales
		this.superficies_local = [];
		this.expensas = [];
		this.superficies_galpon = [];
		this.superficies_terreno = [];

		this.searchFields = {};

		this.searchPropertys = {
						page: 1,
						items_x_page: 10
		};
	}

	ngOnInit(){
		console.log('T001InicioComponent component cargado ... ');
		
		this.getDatosInicio().subscribe(
			(response)=>{

				if ( !this.imagenes_seccion_inicio || 
					( Array.isArray(this.imagenes_seccion_inicio) && this.imagenes_seccion_inicio.length <= 0 ) )
					this.setImagenesInicioPorDefecto();

				this.detectChanges();
				this.irArribaDeLaPagina();
			},
			(error)=>{
				this._router.navigate(['/inicio']);
			});

		this.getDatosParaControlesDelBuscador().subscribe(
			(response)=>{

				this.detectChanges();
				this.subcribirmeFormControlTipoInmueble();
			},
            (error)=>{
                console.log(error);
            });
	}

	ngOnDestroy(){

		this.subTipoInmueble.unsubscribe();
	}	

	detectChanges(){

		this._cdRef.detectChanges();
	}

	irArribaDeLaPagina(){
		
		this._scrollService.scrollTo({ y:0, duration: 0 });
	}

	getDatosInicio(): Observable<any>{	

		return new Observable((observer)=>{

			forkJoin([
				this._inmobiliariasService.getImagesBySeccion(this.inmobiliaria.id, 'inicio'),
				this._inmueblesService.getInmueblesImportantes(this.inmobiliaria.id)
            ]).subscribe((response)=>{
				//console.log('response: ', response);
				this.imagenes_seccion_inicio = response[0].imagenes;
				this.importantes = response[1].inmuebles;

				observer.next();
				observer.complete();
			},
			(error)=>{
				observer.error(error);
				observer.complete();				
			});
		});
	}

	// obtengo todos los datos de los formControls de la busqueda
	getDatosParaControlesDelBuscador(): Observable<any>{

	    return new Observable((observer)=>{

	      	forkJoin([
	      		this._inmueblesService.getTiposOperacion(),
		        this._inmueblesService.getTiposInmueble(),
                this._inmueblesService.getCiudades(),
		        this._inmueblesService.getNombresBarrios(),
		        this._inmueblesService.getPreciosVenta(),
		        this._inmueblesService.getPreciosAlquiler(),
		        this._inmueblesService.getSupCochera(),
		        this._inmueblesService.getSupLocal(),
		        this._inmueblesService.getExpensas(),
		        this._inmueblesService.getSupGalpon(),
		        this._inmueblesService.getSupTerreno()
              ]).subscribe((response)=>{
	      		//console.log('response: ', response);

	      		this.tipos_operacion = response[0].tipos_operacion;
	      		this.tipos_inmueble = response[1].tipos_inmueble;

                this.ciudades = response[2].ciudades;
                
                if ( this.ciudades.length == 1 ) {

                    this.searchFields.fcCiudad = false;
                    // Si la unica ciudad que hay es Mar del Plata
                    this.searchFields.fcBarrios = this.ciudades[0].id == 1 ? true : false;
                } else if ( this.ciudades.length > 1 )
                    this.searchFields.fcCiudad = true;

	      		this.barrios = response[3].barrios;
	      		this.precios_venta = response[4].precios_venta;
	      		this.precios_alquiler = response[5].precios_alquiler;
	      		this.superficies_cochera = response[6].superficies;
	      		this.superficies_local = response[7].superficies;
	      		this.expensas = response[8].expensas;
	      		this.superficies_galpon = response[9].superficies;
		        this.superficies_terreno = response[10].superficies;

		        observer.next();
		        observer.complete();
	      	},
	      	(error)=>{
		        observer.error(error);
		        observer.complete();
	      	});
	    });
	}

	subcribirmeFormControlTipoInmueble(){
		// Subcripciones a los cambios de los FormControls
		this.subTipoInmueble = this.fcTipoInmueble.valueChanges.subscribe(
								(value)=>{

									this.mostrarCamposDeBusquedaSegunTipoInmuebleSeleccionado(value);
								});
	}

	mostrarCamposDeBusquedaSegunTipoInmuebleSeleccionado(id_tipo_inmueble){

		this.searchFields = {
                        fcCiudad: this.searchFields.fcCiudad ? true : false,
						fcAmbientes: false,
						fcDormitorios: false,
						fcBanos: false,
						fcCocheras: false,
						fcPlantas: false,
						fcDependencia: false,
						fcSupCochera: false,
						fcTipoCobertura: false,
						fcTipoCoche: false,
						fcSupLocal: false,
						fcDisposicion: false,
						fcExpensas: false,
						fcAptoProfesional: false,
						fcSupGalpon: false,
						fcSupCubiertaLocal: false,
						fcSupTerreno: false,
                        fcBarrios: this.searchFields.fcBarrios ? true : false
					};

        let updateSearchFields = {};

		switch(+id_tipo_inmueble){

			case 1: // Casa
					updateSearchFields = {
						fcAmbientes: true,
						fcDormitorios: true,
						fcBanos: true,
						fcCocheras: true,
						fcPlantas: true,
						fcSupTerreno: true,
						fcDependencia: true
					};
			break;
			case 2: // Cochera
					updateSearchFields = {
						fcSupCochera: true,
						fcTipoCobertura: true,
						fcTipoCoche: true
					};
			break;
			case 3: // Comercio
					updateSearchFields = {
						fcSupLocal: true
					};
			break;
			case 4: // Departamento
					updateSearchFields = {
						fcAmbientes: true,
						fcDormitorios: true,
						fcBanos: true,
						fcCocheras: true,
						fcDisposicion: true,
						fcExpensas: true,
						fcAptoProfesional: true
					};
			break;
			case 5: // Galpon
					updateSearchFields = {
						fcSupGalpon: true
					};
			break;
			case 6: // Local
					updateSearchFields = {
						fcSupCubiertaLocal: true
					};
			break;
			case 8: // Oficina
					updateSearchFields = {
						fcAmbientes: true,
						fcBanos: true,
						fcCocheras: true,
						fcExpensas: true
					};
			break;
			case 9: // Quinta
					updateSearchFields = {
						fcAmbientes: true,
						fcDormitorios: true,
						fcBanos: true,
						fcCocheras: true,
						fcPlantas: true,
						fcDependencia: true
					};
			break;
			case 10: // Terreno
					updateSearchFields = {
						fcSupTerreno: true
					};
			break;
			case 11: // PH - Duplex
					updateSearchFields = {
						fcAmbientes: true,
						fcDormitorios: true,
						fcBanos: true,
						fcCocheras: true
					};
			break;
		}

        Object.assign( this.searchFields, updateSearchFields);

		this.resetearFormControlsDeBusqueda();
	}

	resetearFormControlsDeBusqueda(){

		// Reseteo los formControls no visibles
		for( const nombre_control in this.searchFields ) {

			if ( ! this.searchFields[nombre_control] )
				this.resetearFormControl(nombre_control);
		}
	}

	resetearFormControl(nombre_control=null){
        
		switch(nombre_control){

			case 'fcPrecioVenta': this.fcPrecioVenta.setValue(''); break;
			case 'fcPrecioAlquiler': this.fcPrecioAlquiler.setValue(''); break;
            case 'fcPrecioDesde': this.fcPrecioDesde.setValue(''); break;
            case 'fcPrecioHasta': this.fcPrecioHasta.setValue(''); break;
			case 'fcAmbientes': this.fcAmbientes.setValue(''); break;
			case 'fcDormitorios': this.fcDormitorios.setValue(''); break;
			case 'fcBanos': this.fcBanos.setValue(''); break;
			case 'fcCocheras': this.fcCocheras.setValue(''); break;
			case 'fcPlantas': this.fcPlantas.setValue(''); break;
			case 'fcDependencia': this.fcDependencia.setValue(''); break;
			case 'fcSupCochera': this.fcSupCochera.setValue(''); break;
			case 'fcTipoCobertura': this.fcTipoCobertura.setValue(''); break;
			case 'fcTipoCoche': this.fcTipoCoche.setValue(''); break;
			case 'fcSupLocal': this.fcSupLocal.setValue(''); break;
			case 'fcDisposicion': this.fcDisposicion.setValue(''); break;
			case 'fcExpensas': this.fcExpensas.setValue(''); break;
			case 'fcAptoProfesional': this.fcAptoProfesional.setValue(''); break;
			case 'fcSupGalpon': this.fcSupGalpon.setValue(''); break;
			case 'fcSupCubiertaLocal': this.fcSupCubiertaLocal.setValue(''); break;
			case 'fcSupTerreno': this.fcSupTerreno.setValue(''); break;
		}
	}

    changeSelectCiudad(id_ciudad=null) {

        // Solo si la ciudad seleccionada es "Mar del Plata" muestro los barrios
        this.searchFields.fcBarrios = id_ciudad == 1 ? true : false;
        this.fcBarrios.setValue('');
    }

    allowOnlyInteger(event, setPoints=false){

		CustomValidators.allowOnlyInteger(event, {setPoints: setPoints});
	}

	getDatosConsulta(): Promise<any>{

		return new Promise((resolve)=>{

            const consulta : any = {
				id_tipo_operacion: this.fcTipoOperacion.value ? this.fcTipoOperacion.value : undefined,
				id_tipo_inmueble: this.fcTipoInmueble.value ? this.fcTipoInmueble.value : undefined,
                id_ciudad: this.fcCiudad.value ? this.fcCiudad.value : undefined,
				id_barrio: this.fcBarrios.value ? this.fcBarrios.value : undefined,
				precio_venta: this.fcPrecioVenta.value ? this.fcPrecioVenta.value : undefined,
				precio_alquiler: this.fcPrecioAlquiler.value ? this.fcPrecioAlquiler.value : undefined,
                precio_desde: this.fcPrecioDesde.value ? this.fcPrecioDesde.value : undefined,
                precio_hasta: this.fcPrecioHasta.value ? this.fcPrecioHasta.value : undefined,
				ambientes: this.fcAmbientes.value ? this.fcAmbientes.value : undefined,
				dormitorios: this.fcDormitorios.value ? this.fcDormitorios.value : undefined,
				banos: this.fcBanos.value ? this.fcBanos.value : undefined,
				cocheras: this.fcCocheras.value ? this.fcCocheras.value : undefined,
				plantas: this.fcPlantas.value ? this.fcPlantas.value : undefined,
				dependencia: this.fcDependencia.value ? this.fcDependencia.value : undefined,
				sup_cochera: this.fcSupCochera.value ? this.fcSupCochera.value : undefined,
				tipo_cobertura: this.fcTipoCobertura.value ? this.fcTipoCobertura.value : undefined,
				tipo_coche: this.fcTipoCoche.value ? this.fcTipoCoche.value : undefined,
				sup_local: this.fcSupLocal.value ? this.fcSupLocal.value : undefined,
				disposicion: this.fcDisposicion.value ? this.fcDisposicion.value : undefined,
				expensas: this.fcExpensas.value ? this.fcExpensas.value : undefined,
				apto_profesional: this.fcAptoProfesional.value ? this.fcAptoProfesional.value : undefined,
				sup_galpon: this.fcSupGalpon.value ? this.fcSupGalpon.value : undefined,
				sup_cubierta: this.fcSupCubiertaLocal.value ? this.fcSupCubiertaLocal.value : undefined,
				sup_terreno: this.fcSupTerreno.value ? this.fcSupTerreno.value : undefined,
                solo_datos_para_mapa: this.searchPropertys.solo_datos_para_mapa ? this.searchPropertys.solo_datos_para_mapa : undefined
			};

			resolve(consulta);
		});
	}

	async doSearch(){

		const consulta = await this.getDatosConsulta();

		Object.assign(this.searchPropertys, consulta);

		//console.log('search: ', this.searchPropertys);
		
		this._router.navigate(['/buscador/', JSON.stringify(this.searchPropertys)]);	
	}

	setImagenesInicioPorDefecto(){

		this.url_imagenes_seccion_inicio = GLOBAL.url_webs_default + 'inicio/';

		this.imagenes_seccion_inicio = [
			{ nombre: this._configService.imagenes_default_inicio[0] },
			{ nombre: this._configService.imagenes_default_inicio[1] },
			{ nombre: this._configService.imagenes_default_inicio[2] },
			{ nombre: this._configService.imagenes_default_inicio[3] },
			{ nombre: this._configService.imagenes_default_inicio[4] }
		];

		this.detectChanges();
	}
}