import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { T001MainComponent } from './components/main/main.component';
import { T001InicioComponent } from './components/inicio/inicio.component';
import { T001BuscadorComponent } from './components/buscador/buscador.component';
import { T001EmprendimientosComponent } from './components/emprendimientos/emprendimientos.component';
import { T001NosotrosComponent } from './components/nosotros/nosotros.component';
import { T001ServiciosComponent } from './components/servicios/servicios.component';
import { T001ContactosComponent } from './components/contactos/contactos.component';
import { T001InmuebleComponent } from './components/inmueble/inmueble.component';

// Guard
import { MainGuard } from './services/main.guard';

const routes: Routes = [
	{
	    path: '',
	    component: T001MainComponent,
	    canActivate: [ MainGuard ],
	    children: [
	        {path: '', component: T001InicioComponent},
	        {path: 'inicio', component: T001InicioComponent},   
	        {path: 'buscador', component: T001BuscadorComponent},
	        {path: 'buscador/:query', component: T001BuscadorComponent},
	        {path: 'inmueble/:id', component: T001InmuebleComponent},
            {path: 'emprendimientos', component: T001EmprendimientosComponent},
	        {path: 'nosotros', component: T001NosotrosComponent},
	        {path: 'servicios', component: T001ServiciosComponent},   
	        {path: 'contactos', component: T001ContactosComponent},
	        {path: '**', component: T001InicioComponent}
	    ]
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})

export class AppRoutingModule { }