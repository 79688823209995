<div #galleryComponent id="gallerycomponent" class="gallery-component" [ngClass]="{ show: visible }">

	<button type="button" class="gallery-btn-close" (click)="close()" >X</button>

	<svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"></circle>
    </svg>

	<ng-template [ngIf]="touch_device" [ngIfElse]="desktop">

		<div class="gallery-numeration">
			{{ selectedImage + 1}} / {{ images.length }}
		</div>

		<div #carousel class="gallery-carousel" (panstart)="onPanStartCarousel($event)" (pan)="onPanCarousel($event)" (panend)="onPanEndCarousel($event)">

			<ng-container *ngFor="let image of images; index as i">

				<div id="gallery_carousel_imagen_{{i}}" class="gallery-carousel-image" (pinchstart)="onPinchStart(i, $event)" (pinch)="onPinch(i, $event)" (pinchend)="onPinchEnd(i, $event)" (panstart)="onPanStartImagen(i, $event)" (pan)="onPanImagen(i, $event)" (panend)="onPanEndImagen(i, $event)">

					<img src="{{ pathDirImages + image.nombre }}">
					
					<div class="gallery-image-epigrafe" *ngIf="show_epigrafe && image.epigrafe">
						{{ image.epigrafe }}
					</div>
				</div>

			</ng-container>
			
		</div>
		
	</ng-template>

	<ng-template #desktop>

		<div class="gallery-numeration" *ngIf="!show_thumbs">
			{{ selectedImage + 1}} / {{ images.length }}
		</div>

		<div class="gallery-image-epigrafe" *ngIf="visible && show_epigrafe && images[selectedImage].epigrafe" [ngClass]="{ top : show_thumbs }">
			{{ images[selectedImage].epigrafe }}
		</div>

		<div class="gallery-image-desktop" *ngIf="images[selectedImage]">

			<img src="{{ pathDirImages + images[selectedImage].nombre }}">
			
		</div>		

		<ng-container *ngIf="show_nav_buttons">
			<a class="gallery-btn-previous" (click)="previous()" >
			</a>

			<a class="gallery-btn-next" (click)="next()">
			</a>
		</ng-container>

		<div class="gallery-thumbs" *ngIf="show_thumbs">

			<ul>
				<li *ngFor="let image of images; let i=index" (click)="setSelectedImage(i)" [ngClass]="{ selected: i == selectedImage }">
					<img src="{{ pathDirThumbnails + image.nombre }}">
				</li>
			</ul>

		</div>

	</ng-template>	
	
</div>