import { Component, ViewEncapsulation, ViewChild, ChangeDetectorRef, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

// Servicios
import { GLOBAL } from '../../services/global';
import { AnalyticsService } from '../../services/analytics.service';

// Components
//import { ChatManagementComponent } from '../globals/chat-management/chat-management.component';

@Component({
	selector: 'T001-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.css', './common.css', './main.component.scss'],
	encapsulation: ViewEncapsulation.None,
    providers: [ AnalyticsService ]
})

export class T001MainComponent {

	public identity: any;
	public inmobiliaria: any;
	public web: any;
    public dominio: string;

	public url_web_inmobiliaria: string;
	public is_menu_open: boolean;

	//@ViewChild("chats", { static: true }) chats: ChatManagementComponent;

	constructor(private _cdRef: ChangeDetectorRef,
                private _titleService: Title,
                private _metaService: Meta,
                private _analyticsService: AnalyticsService,
                @Inject(PLATFORM_ID) private platformId: Object,
                private renderer: Renderer2 ) {

		this.identity = GLOBAL.identity;
		this.inmobiliaria = GLOBAL.inmobiliaria;
		this.web = GLOBAL.web;
        this.dominio = GLOBAL.dominio;

		this.url_web_inmobiliaria =  GLOBAL.url_webs + GLOBAL.inmobiliaria?.id + '/';

		this.is_menu_open = false;
	}

	ngOnInit(){
		console.log('T001MainComponent component cargado ... ');

		this.setEtiquetaTitleHTML();
        this.setTagDescription();
        this.setTagkeywords();
        this.setIconoDeLaPagina();
		this.setHojaDeEstilos();
        this.setClaseBotonWhatsApp();

        if ( this.web.id_analytics )
			this._analyticsService.activate(this.web.id_analytics);
	}

    ngAfterViewInit() {

        if ( isPlatformBrowser(this.platformId) )
            this.hideAppLoading();
    }

	setEtiquetaTitleHTML(){

		this._titleService.setTitle( this.inmobiliaria?.nombre );
	}

    setTagDescription(){

        this._metaService.updateTag({ name: 'description', content: this.web?.description });
    }

    setTagkeywords(){

        this._metaService.updateTag({ name: 'keywords', content: this.web?.keywords });
    }

    setIconoDeLaPagina() {

        const icon = <HTMLLinkElement> document?.querySelector('#pagina-icono');

        if ( !icon && this.web.logotipo ) return;

        icon.href = this.url_web_inmobiliaria + this.web.logotipo;
    }

	setHojaDeEstilos(){

		const element = document.getElementById("mardelinmueble-css-inmobiliaria");

		if ( element === null )
		    this.createElementLinkHojaDeEstilos();

		this._cdRef.detectChanges();
	}

    createElementLinkHojaDeEstilos(){

        this.getURLDeArchivoCSSDeLaInmobiliaria()
            .then((url_css)=>{
                const style = document.createElement("link");

                style.id = "mardelinmueble-css-inmobiliaria";
                style.type = "text/css";
                style.rel = "stylesheet";
                style.href = url_css;

                document.head.appendChild(style);
            });
	}

    getURLDeArchivoCSSDeLaInmobiliaria(): Promise<any>{

        return new Promise((resolve)=>{

            if ( this.dominio.startsWith('plantilla-web') ) {

                const css = this.dominio.substring(0, this.dominio.indexOf('.')).toLowerCase() + '.css';
                
                resolve(this.url_web_inmobiliaria + css);
            } else 
                resolve(this.url_web_inmobiliaria + "custom.css");
        });
    }

    setClaseBotonWhatsApp() {

        if ( this.web.boton_whatsapp && this.inmobiliaria.whatsapp ) {
            document.body.classList.add('btn-whatsapp-enabled');

            if ( this.web.boton_whatsapp_position == 'right')
                document.body.classList.add('btn-whatsapp-right');
            else if ( this.web.boton_whatsapp_position == 'left')
                document.body.classList.add('btn-whatsapp-left');
        }
    }

    hideAppLoading() {

        const loader = this.renderer.selectRootElement('#app-loading');

        if ( loader.style.display != "none")
            loader.style.display = "none";
    }

	toggleMobileMenu(){

        const button = document.querySelector('.boton-menu');
        const nav = document.querySelector('#nav');

         if (!button.classList.contains("open")) {
			button.classList.add('open');
			nav.classList.add('open');
		} else {
			button.classList.remove('open');
			nav.classList.remove('open');
		}		
	}

    openRedSocialInmobiliaria(red_social=null) {

        if ( ! red_social ) return;

        this._analyticsService.sendEvento('ver_red_social_inmobiliaria '+ red_social, 'redes_sociales', 'El usuario hizo clic en un link de la red social '+ red_social );
    }

    openWhatsAppInmobiliaria() {
		
        this._analyticsService.sendEvento('abrir_whatsapp_inmobiliaria', 'comunicacion', 'El usuario abrió el whatsapp de la inmobiliaria.');
	}
}
