<div class="pagination" *ngIf="cant_pages > 1">

	<button type="button" class="page" (click)="previous()" [disabled]="current_page == 1" [ngClass]="{ disabled: current_page == 1 }">
		<span class="desktop">Anterior</span>
		<i class="mdi mdi-chevron-left mobile"></i>
	</button>

	<ng-container *ngFor="let page of show_pages; let i = index">

		<button type="button" class="page" (click)="selectPage(page)" [disabled]="page == current_page" [ngClass]="{ current: page == current_page }">
			{{page}}
		</button>
		
	</ng-container>

	<button type="button" class="page" (click)="next()" [disabled]="current_page == cant_pages" [ngClass]="{ disabled: current_page == cant_pages}">
		<span class="desktop">Siguiente</span>
		<i class="mdi mdi-chevron-right mobile"></i>
	</button>
</div>