import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-whatsapp',
  templateUrl: './button-whatsapp.component.html',
  styleUrls: ['./button-whatsapp.component.css']
})

export class ButtonWhatsAppComponent {

	@Input() visible: string;
    @Input() position: 'left' | 'right' | 'none' | null;
    @Input() number: string;
    @Input() tooltip: string;
    
    @Output() eventClic = new EventEmitter<any>();

	constructor(){

        this.position = 'right';
	}

    openWhatsAppInmobiliaria() {
		
        this.eventClic.emit();
	}
}