import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// Services
import { LocalStorageService } from './localStorage.service';
import { InmobiliariasService } from './inmobiliarias.service';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root',
})

export class MainGuard {

	public identity: any;
	public inmobiliaria: any;
	public web: any;
	public dominio: string;

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private _localStorageService: LocalStorageService,
				private _inmobiliariasService: InmobiliariasService
	){

		this.identity = this._localStorageService.getIdentity();
		this.inmobiliaria = {};
		this.web = {};
		this.dominio = null;
	}

	//canActivate(route, state){
  	async canActivate(){

        if ( ! isPlatformBrowser(this.platformId) ) return false;

	    this.dominio = await this.quitarWWW(window.location.hostname);
        this.dominio = 'prototipo.mardelinmueble.com';
	    if ( this.dominio ) {
	      return this.getDatosDeLaInmobiliariaYSuWebByDominio(this.dominio)
							.then(() => {
								return true;
							})
							.catch(error => {
								return false;
							});
	    } else
	    	return false;
	}

	quitarWWW(dominio=null): Promise<string>{

		return new Promise((resolve, reject)=>{

			let value = dominio.toLowerCase();

		    if ( value.startsWith('www.') ) 
		        value = value.slice(4);

		    resolve(value);
		});
	}

  	getDatosDeLaInmobiliariaYSuWebByDominio(dominio=null){

    	return new Promise((resolve, reject) => {

      		if ( ! dominio )
        		reject({ error: true, message: 'Faltan parametros requeridos.'});

			this._inmobiliariasService.getDatosDeLaInmobiliariaYSuWebByDominio(dominio).subscribe(
				(response)=>{

					if ( response.success ) {

						this.web = response.web;
                        this.inmobiliaria = response.inmobiliaria;

			            this.setDatosGLOBALESInmobiliaria();

			            resolve(true);
					} else
						reject({ error: true, message: 'La web con el dominio especificado no existe.'});
				},
				(error)=>{
					reject(error);
				}
			);
		});
  	}

	setDatosGLOBALESInmobiliaria(){

		GLOBAL.identity = this.identity ? this.identity : null;
		GLOBAL.inmobiliaria = this.inmobiliaria ? this.inmobiliaria : null;
		GLOBAL.web = this.web ? this.web : null;
		GLOBAL.dominio = this.dominio;
        GLOBAL.IS_WEB = true;
	}
}