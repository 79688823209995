import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from  '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// services
import { GLOBAL } from './global';


@Injectable()

export class InmueblesService{

	constructor(private _http: HttpClient) {}

	// -----------------------------------------------------
	// Datos Parametricos
	// -----------------------------------------------------
    getTiposOperacion(): Observable<any>{

        if ( GLOBAL.TIPOS_OPERACION.length > 0 )
            return of( { success: true, tipos_operacion: GLOBAL.TIPOS_OPERACION });
        else {

            const url = GLOBAL.url_back_end + 'inmuebles/';

            const headers = new HttpHeaders().set('Content-Type', 'application/json');

            return this._http.get(url+'tipos-operacion', { headers: headers })
                        .pipe( 
                            map( (data ) => { this.setTiposOperacion(data); return data; } )
                        );
        }
	}

    setTiposOperacion(data) {

        if ( data.success )
            GLOBAL.TIPOS_OPERACION = data.tipos_operacion;
    }

    getTiposInmueble(): Observable<any>{

        if ( GLOBAL.TIPOS_INMUEBLE.length > 0 )
            return of( { success: true, tipos_inmueble: GLOBAL.TIPOS_INMUEBLE });
        else {

            const url = GLOBAL.url_back_end + 'inmuebles/';

            const headers = new HttpHeaders().set('Content-Type', 'application/json');

            return this._http.get(url+'tipos-inmueble/solo-los-que-opera-la-inmobiliaria/'+GLOBAL.inmobiliaria.id, { headers: headers })
                        .pipe( 
                            map( (data ) => { this.setTiposInmueble(data); return data; } )
                        );
        }
	}

    setTiposInmueble(data) {

        if ( data.success )
            GLOBAL.TIPOS_INMUEBLE = data.tipos_inmueble;
    }

    getCiudades(): Observable<any>{

        if ( GLOBAL.CIUDADES.length > 0 )
            return of( { success: true, ciudades: GLOBAL.CIUDADES });
        else {

            const url = GLOBAL.url_back_end + 'inmuebles/';

            const headers = new HttpHeaders().set('Content-Type', 'application/json');

            return this._http.get(url+'ciudades/solo-las-que-tiene-inmuebles-la-inmobiliaria/'+GLOBAL.inmobiliaria.id, { headers: headers })
                        .pipe( 
                            map( (data ) => { this.setCiudades(data); return data; } )
                        );
        }
	}

    setCiudades(data) {

        if ( data.success )
            GLOBAL.CIUDADES = data.ciudades;
    }

    getNombresBarrios(): Observable<any>{

        if ( GLOBAL.NOMBRES_BARRIOS.length > 0 )
            return of( { success: true, barrios: GLOBAL.NOMBRES_BARRIOS });
        else {

            const url = GLOBAL.url_back_end + 'inmuebles/';

            const headers = new HttpHeaders().set('Content-Type', 'application/json');

            return this._http.get(url+'nombres-barrios', { headers: headers })
                        .pipe( 
                            map( (data ) => { this.setNombresBarrios(data); return data; } )
                        );
        }
	}

    setNombresBarrios(data) {

        if ( data.success )
            GLOBAL.NOMBRES_BARRIOS = data.barrios;
    }

    getPreciosVenta(): Observable<any>{

        if ( GLOBAL.PRECIOS_VENTA.length > 0 )
            return of( { success: true, precios_venta: GLOBAL.PRECIOS_VENTA });
        else {

            const url = GLOBAL.url_back_end + 'inmuebles/';

            const headers = new HttpHeaders().set('Content-Type', 'application/json');

            return this._http.get(url+'precios-venta', { headers: headers })
                        .pipe( 
                            map( (data ) => { this.setPreciosVenta(data); return data; } )
                        );
        }
	}

    setPreciosVenta(data) {

        if ( data.success )
            GLOBAL.PRECIOS_VENTA = data.precios_venta;
    }

    getPreciosAlquiler(): Observable<any>{

        if ( GLOBAL.PRECIOS_ALQUILER.length > 0 )
            return of( { success: true, precios_alquiler: GLOBAL.PRECIOS_ALQUILER });
        else {

            const url = GLOBAL.url_back_end + 'inmuebles/';

            const headers = new HttpHeaders().set('Content-Type', 'application/json');

            return this._http.get(url+'precios-alquiler', { headers: headers })
                        .pipe( 
                            map( (data ) => { this.setPreciosAlquiler(data); return data; } )
                        );
        }
	}

    setPreciosAlquiler(data) {

        if ( data.success )
            GLOBAL.PRECIOS_ALQUILER = data.precios_alquiler;
    }

    getSupCochera(): Observable<any>{

        if ( GLOBAL.SUPERFICIES_COCHERA.length > 0 )
            return of( { success: true, superficies: GLOBAL.SUPERFICIES_COCHERA });
        else {

            const url = GLOBAL.url_back_end + 'inmuebles/';

            const headers = new HttpHeaders().set('Content-Type', 'application/json');

            return this._http.get(url+'superficies-cochera', { headers: headers })
                        .pipe( 
                            map( (data ) => { this.setSupCochera(data); return data; } )
                        );
        }
	}

    setSupCochera(data) {

        if ( data.success )
            GLOBAL.SUPERFICIES_COCHERA = data.superficies;
    }

    getSupLocal(): Observable<any>{

        if ( GLOBAL.SUPERFICIES_LOCAL.length > 0 )
            return of( { success: true, superficies: GLOBAL.SUPERFICIES_LOCAL });
        else {

            const url = GLOBAL.url_back_end + 'inmuebles/';

            const headers = new HttpHeaders().set('Content-Type', 'application/json');

            return this._http.get(url+'superficies-local', { headers: headers })
                        .pipe( 
                            map( (data ) => { this.setSupLocal(data); return data; } )
                        );
        }
	}

    setSupLocal(data) {

        if ( data.success )
            GLOBAL.SUPERFICIES_LOCAL = data.superficies;
    }

    getExpensas(): Observable<any>{

        if ( GLOBAL.EXPENSAS.length > 0 )
            return of( { success: true, expensas: GLOBAL.EXPENSAS });
        else {

            const url = GLOBAL.url_back_end + 'inmuebles/';

            const headers = new HttpHeaders().set('Content-Type', 'application/json');

            return this._http.get(url+'expensas', { headers: headers })
                        .pipe( 
                            map( (data ) => { this.setExpensas(data); return data; } )
                        );
        }
	}

    setExpensas(data) {

        if ( data.success )
            GLOBAL.EXPENSAS = data.expensas;
    }

    getSupGalpon(): Observable<any>{

        if ( GLOBAL.SUPERFICIES_GALPON.length > 0 )
            return of( { success: true, superficies: GLOBAL.SUPERFICIES_GALPON });
        else {

            const url = GLOBAL.url_back_end + 'inmuebles/';

            const headers = new HttpHeaders().set('Content-Type', 'application/json');

            return this._http.get(url+'superficies-galpon', { headers: headers })
                        .pipe( 
                            map( (data ) => { this.setSupGalpon(data); return data; } )
                        );
        }
	}

    setSupGalpon(data) {

        if ( data.success )
            GLOBAL.SUPERFICIES_GALPON = data.superficies;
    }

    getSupTerreno(): Observable<any>{

        if ( GLOBAL.SUPERFICIES_TERRENO.length > 0 )
            return of( { success: true, superficies: GLOBAL.SUPERFICIES_TERRENO });
        else {

            const url = GLOBAL.url_back_end + 'inmuebles/';

            const headers = new HttpHeaders().set('Content-Type', 'application/json');

            return this._http.get(url+'superficies-terreno', { headers: headers })
                        .pipe( 
                            map( (data ) => { this.setSupTerreno(data); return data; } )
                        );
        }
	}

    setSupTerreno(data) {

        if ( data.success )
            GLOBAL.SUPERFICIES_TERRENO = data.superficies;
    }

    getCheckboxCampos(): Observable<any>{

        // Si ya fueron pedidos al servidor los campos checkbox
        // estan guardados en GLOBAL
        // Entonces no los vuelvo a pedir al servidor
        if ( GLOBAL.CAMPOS_CHECKBOX.length > 0 )
            return of( { success: true, campos: GLOBAL.CAMPOS_CHECKBOX });
        else {

            const url = GLOBAL.url_back_end + 'inmuebles/checkbox-campos';

            return this._http.get(url)
                        .pipe( 
                            map( (data ) => { this.setCheckboxCampos(data); return data; } )
                        );
        }
	}

    setCheckboxCampos(data) {

        if ( data.success )
            GLOBAL.CAMPOS_CHECKBOX = data.campos;
    }
	// -----------------------------------------------------
	// End Datos Parametricos
	// -----------------------------------------------------

	// -----------------------------------------------------
	// SOLICITUDES a los puntos de acceso de ./inmobiliarias/
	// -----------------------------------------------------
	searchInmuebles(id_inmobiliaria=null, objSearch=null): Observable<any> {
		
		const url = GLOBAL.url_back_end + 'inmobiliarias/';

		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		const params = new HttpParams();

		return this._http.get(url+id_inmobiliaria+'/inmuebles', { headers: headers, params: objSearch });
	}

	getInmueble(id_inmobiliaria=null, id_inmueble=null): Observable<any>{
		const url = GLOBAL.url_back_end + 'inmobiliarias/';

		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(url+id_inmobiliaria+'/inmuebles/'+id_inmueble, {headers: headers});
	}

    getEmprendimientos(id_inmobiliaria=null): Observable<any>{
		const url = GLOBAL.url_back_end + 'inmobiliarias/';

		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(url+id_inmobiliaria+'/emprendimientos', {headers: headers});
	}

	getInmueblesSimilares(id_inmobiliaria=null, cant=0): Observable<any>{
		const url = GLOBAL.url_back_end + 'inmobiliarias/';

		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(url+id_inmobiliaria+'/web/similares/cantidad/'+cant, {headers: headers});
	}

	getInmueblesImportantes(id_inmobiliaria=null, cant=null): Observable<any>{
		const url = GLOBAL.url_back_end + 'inmobiliarias/';

		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(url+id_inmobiliaria+'/web/importantes/cantidad/'+cant, {headers: headers});
	}

	getAgentesDeLaInmobiliariaByInmueble(id_inmobiliaria=null, id_inmueble=null): Observable<any> {

		const url = GLOBAL.url_back_end + 'inmuebles/';

		return this._http.get(url+id_inmueble+'/agentes/inmobiliaria/'+id_inmobiliaria);
	}

	sendConsultaInmueble(id_inmueble=null, consulta=null): Observable<any> {

		const url = GLOBAL.url_back_end + 'inmuebles/';

		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(url+id_inmueble+'/enviar-consulta/inmobiliaria/'+GLOBAL.inmobiliaria.id, consulta, {headers: headers});
	}
}