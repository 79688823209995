<div #carouselInmuebles class="carousel-inmuebles" *ngIf="inmuebles.length > 0">

	<button *ngIf="show_buttons && ( slidesCount > 1 )" class="button previous" (click)="previous()">
		<i class="mdi mdi-chevron-left"></i>
	</button>
	<button *ngIf="show_buttons && ( slidesCount > 1 )" class="button next" (click)="next()">
		<i class="mdi mdi-chevron-right"></i>
	</button>

	<div #carousel class="carousel" (panstart)="onPanStart($event)" (panmove)="onPanMove($event)" (panend)="onPanEnd($event)" [style.touch-action]="'pan-y'">

		<div class="inmueble" *ngFor="let inmueble of inmuebles">
			<a [routerLink]="['/inmueble', inmueble.id]">
				<div class="imagen">
          <ng-container [ngSwitch]="inmueble.id_estado_aviso">
            <div class="inmueble-estado reservado" *ngSwitchCase="2">
              Reservado
            </div>
            <div class="inmueble-estado vendido" *ngSwitchCase="3">
              Vendido
            </div>
            <div class="inmueble-estado alquilado" *ngSwitchCase="4">
              Alquilado
            </div>
          </ng-container>

          <ng-template [ngIf]="inmueble.imagen_principal" [ngIfElse]="imagenInmuebleDefault">
              <img src="{{ url_imagenes_inmuebles + inmueble.imagen_principal }}">
          </ng-template>

          <ng-template #imagenInmuebleDefault>
              <img src="{{ imagen_default_inmueble }} ">
          </ng-template>

          <div class="mensajes-importantes">
            <span *ngIf="inmueble.urgente">Urgente</span>
            <span *ngIf="inmueble.oportunidad">Oportunidad</span>
            <span *ngIf="inmueble.apto_credito">Apto crédito</span>
            <span *ngIf="inmueble.retasado">Retasado</span>
          </div>

          <figcaption>{{ inmueble.tipo_operacion }}</figcaption>
        </div>

				<div class="detail">
					<h4>{{ inmueble.titulo }}</h4>

					<div class="description">
						<p [innerHtml]="inmueble.descripcion"></p>
						<a class="more-details" [routerLink]="['/inmueble', inmueble.id]">Más información</a>
					</div>

                    <ng-template [ngIf]="!inmueble.no_publicar_precio && inmueble.precio && inmueble.precio > 0" [ngIfElse]="sinPrecio">
                        <span class="price">
                            <span class="moneda" *ngIf="inmueble.moneda==1">$</span>
                            <span class="moneda" *ngIf="inmueble.moneda==2">U$D</span>
                            {{ inmueble.precio | number: '1.0' }}
                        </span>
                    </ng-template>

                    <ng-template #sinPrecio>
                        <span class="price">
                            Consulte su valor
                        </span>
                    </ng-template>
				</div>
			</a>
		</div>

	</div>

	<ol class="carousel-indicators">
		<li *ngFor="let indi of slides; index as i" [ngClass]="{ active: i+1 == currentSlide }">
		</li>
	</ol>

</div>
