import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

const ESCALAS = {
			MINIMO: 1,
			MAXIMO: 3
		};

const DIRECCIONES = {
		NONE: 1,
    	LEFT: 2,
    	RIGHT: 4,
    	UP: 8,
    	DOWN: 16
    };

const DISTANCIA_MINIMA_SWIPE = 30;

const ELEMENTOS = {
		NONE: null,
		CAROUSEL: 'CAROUSEL',
		IMAGEN: 'IMAGEN'
	};

@Component({
	selector: 'gallery',
	templateUrl: './gallery.component.html',
	styleUrls: ['./gallery.component.css']
})

export class GalleryComponent{

	@Input() selectedImage: number;
	@Input() images: Array<any>;
	@Input() pathDirThumbnails: string;
	@Input() pathDirImages: string;
	@Input() show_thumbs: 'true' | 'false' | boolean;
	@Input() show_epigrafe: 'true' | 'false' | boolean;

	@Output() onClose = new EventEmitter();

	public visible: boolean;
	public show_spinner: boolean;
	public show_nav_buttons: boolean;

	public imagen:any;

	// Todas las propiedades de Touch
	// ------------------------------
	public touch_device: boolean;

	public scale = 1;
	public moveX: any = 0;
	public moveY: any = 0;

	public last = {
	        x: 0,
	        y: 0,
	        scale: 1
	    };

	public animacion_en_curso = false;
	public agregarUltimaImagenAlComienzo = true;

	private elem_activo_para_pan: any;

	private changeElementoATransformarSubject = new Subject<any>();
	public changeElementoATransformar$ = this.changeElementoATransformarSubject.asObservable();
	private subCambioDeElementoATransformar: Subscription;

	private punto_de_cambio: any = 0;

    @ViewChild("carouselInmuebles", { static: false }) carouselInmuebles: ElementRef;
    @ViewChild("carousel", { static: false }) carousel: ElementRef;

	constructor(private _renderer: Renderer2){
		this.visible = false;

		this.selectedImage = 0;
		this.pathDirThumbnails = null;
		this.pathDirImages = null;

		this.show_spinner = true;
		this.show_thumbs = true;
		this.show_epigrafe = false;
		this.show_nav_buttons = true;

		this.touch_device = this.isTouchDevice() ? true : false;

		this.imagen = {
						width: 0,
						height: 0,
						margin_right: 0
					};

		this.elem_activo_para_pan = ELEMENTOS.NONE;
	}

	ngOnChanges(change){

		if ( change.selectedImage && change.selectedImage.currentValue != undefined && change.selectedImage.currentValue != -1 )
			this.show();

		if ( change.pathDirImages && !this.pathDirThumbnails )
			this.pathDirThumbnails = change.pathDirImages.currentValue;

		if ( typeof this.show_thumbs == 'string' )
			this.show_thumbs =  this.show_thumbs == 'true' ? true : false;
		else if ( typeof this.show_thumbs != 'boolean' )
			this.show_thumbs = true;

		if ( typeof this.show_epigrafe == 'string' )
			this.show_epigrafe =  this.show_epigrafe == 'true' ? true : false;
		else if ( typeof this.show_epigrafe != 'boolean' )
			this.show_epigrafe = true;
	}

	ngOnInit(){

		if ( !this.pathDirThumbnails )
			this.pathDirThumbnails = this.pathDirImages;

		this.show_thumbs = this.show_thumbs ? true: false;
		this.show_nav_buttons = this.show_nav_buttons ? true: false;

		this.setPropiedadesImagen();

		this.setSubscripciones();
	}

	ngAfterViewInit(){

		//this.setEventoLoadImagenes();
	}

	ngOnDestroy() {

		this.enableScrollDeLaPagina();

		this.subCambioDeElementoATransformar.unsubscribe();
	}

	setSpinner(){

		let imagen;
		let spinner;

		if ( this.touch_device )
			imagen = document.querySelector('#gallery_carousel_imagen_'+ this.selectedImage +' > img');
		else
			imagen = document.querySelector('.gallery-image-desktop > img');

		spinner = document.querySelector('.spinner');

		this.addClassShowAlSpinner(spinner);

		imagen.addEventListener('load', this.removeClassShowAlSpinner(spinner));
	}

	addClassShowAlSpinner(spinner) {

		if ( ! spinner ) return;

		spinner.classList.add('show');
	}

	removeClassShowAlSpinner(spinner) {

		if ( ! spinner ) return;

		spinner.classList.remove('show');
	}

	getValorDeDesplazamientoEnElPanDelCarousel() {

		return ( this.imagen.width + this.imagen.margin_right );
	}

	isTouchDevice(){

		return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
	}

	setSubscripciones(){

		this.subCambioDeElementoATransformar = this.changeElementoATransformar$
													.pipe(distinctUntilChanged())
													.subscribe((elemento) => {

														this.controlarCambioDeElementoATransformar(elemento);
													});
	}

	controlarCambioDeElementoATransformar(elemento) {

		if ( ( elemento == ELEMENTOS.CAROUSEL ) && ( this.scale > 1 ) ) {

			const imagen = this.getPosicionDeLaImagen(this.selectedImage);

			if ( !imagen ) return;

			let x;

			// Se paso para el lado izquierdo <----|
			if ( ( imagen.right < window.innerWidth ) && ( imagen.left < 0 ) )
				x = window.innerWidth - imagen.right;
			// Se paso para el lado derecho |--->
			else if ( ( imagen.left > 0 ) && ( imagen.right > window.innerWidth ) )
				x = -( imagen.left );

			this.translateImagen(x, 0);
		}
	}

	show(){

		this.visible = true;
		this.setValoresPorDefecto();
		this.setUltimosValores();
		this.disableScrollDeLaPagina();
		this.setPropiedadesImagen();
		this.setSpinner();
	}

	setValoresPorDefecto(){

		this.scale = 1;
        this.moveX = 0;
        this.moveY = 0;
	}

	setUltimosValores(){

		this.last.scale = this.scale;
		this.last.x = isNaN(Number(this.moveX)) ? 0 : this.moveX;
    	this.last.y = isNaN(Number(this.moveY)) ? 0 : this.moveY;
	}

	close(){

		this.visible = false;
		this.setValoresPorDefecto();
		this.restablecerValoresPorDefectoDeLaImagen(this.selectedImage);
		this.enableScrollDeLaPagina();
		this.onClose.emit();
	}

	setPropiedadesImagen(){
		// Alternativas
		// document.body.clientWidth              /* width of <body> */
		// document.documentElement.clientWidth   /* width of <html> */
		// window.innerWidth                      /* window's width */

		this.imagen.width = window.innerWidth;
		this.imagen.height = window.innerHeight;
		this.imagen.margin_right = 50;

		// Seteo el ancho de cada imagen
        // ESTO NO ANDA ? no me devuelve elementos
        let images = document.querySelectorAll('#gallerycomponent > .gallery-carousel > .gallery-carousel-image') as NodeListOf<HTMLElement>;

        images.forEach(el => {
            el.style.width = this.imagen.width + 'px';
        });
	}

	disableScrollDeLaPagina(){

        document.body.classList.add('desactivar-scroll');
    }

    enableScrollDeLaPagina(){

        document.body.classList.remove('desactivar-scroll');
    }

    setSelectedImage(index){

		this.selectedImage = index;

		if ( this.touch_device )
			this.reubicarImagenesCarouselDejandoPrimeraLaImagenSeleccionada();
	}

	reubicarImagenesCarouselDejandoPrimeraLaImagenSeleccionada(){

        let images = document.querySelectorAll('#gallerycomponent > .gallery-carousel > .gallery-carousel-image') as NodeListOf<HTMLElement>;

        images.forEach(img => {

            if ( img.getAttribute('id') === 'gallery_carousel_imagen_'+this.selectedImage )
                return false;
			else
				this._renderer.appendChild(this.carousel.nativeElement, img);
        });
	}

	previous(){

		this.selectedImage = (this.selectedImage - 1) < 0  ? this.images.length - 1 : this.selectedImage -1;

		this.setSpinner();
	}

	next(){

		this.selectedImage = (this.selectedImage + 1 >= this.images.length ) ? 0 : this.selectedImage +1;

		this.setSpinner();
	}

	// ---------------------------------------------------
	// Eventos PAN en CAROUSEL
	// ---------------------------------------------------
    onPanStartCarousel(event){

        this._renderer.removeClass(this.carousel.nativeElement, 'animate');

		if ( event.isFirst )
			this.punto_de_cambio = 0;
    }

	async onPanCarousel(event){

		if ( event.maxPointers > 1 ) return; // Si no es pan sale


		if ( !this.animacion_en_curso &&
			( event.direction == DIRECCIONES.LEFT || event.direction == DIRECCIONES.RIGHT ) ) {


			this.elem_activo_para_pan = await this.getElementoActivoParaEventoPan();

			if ( this.elem_activo_para_pan === ELEMENTOS.CAROUSEL ) {

				// Solo si la imagen esta ampliada y punto_de_cambio todavia no fue
				// seteado, me fijo si la imagen toco el borde del viewport
				// ACA SOLO SE ESPECIFICA el valor del punto de cambio de elemento.
				// punto_de_cambio: va a contener el valor de la cantidad
				// de pixeles que se hicieron del pan, hasta que el borde izquierdo
				// o derecho de la imagen llegan al viewport
				if ( ( this.scale > 1 ) && ( this.punto_de_cambio === 0 ) ) {

					const imagen = this.getPosicionDeLaImagen(this.selectedImage);

					// pueser ser que el borde derecho de la imagen haya pasado el viewport
					//if ( ( imagen.left < 0 ) && ( this.imagen.width - imagen.right >= 0 ) )
					if ( ( imagen.left < 0 ) && ( imagen.right <= window.innerWidth ) )
						this.punto_de_cambio = event.deltaX;
			    	// puede ser que el borde izquierdo de la imagen haya pasado el viewport
					else if ( ( imagen.left >= 0 ) && ( imagen.right > window.innerWidth ) )
						this.punto_de_cambio = event.deltaX;

				} else // Si la imagen no esta ampliada muevo el carousel de manera normal
					this.moveCarousel(event);
			}
		}
	}

	async moveCarousel(event) {

		const desplazar = this.getValorDeDesplazamientoEnElPanDelCarousel();
		let x = 0;

		x = await this.getValorXParaMoverElCarousel(event);

		if ( x > 0 && this.agregarUltimaImagenAlComienzo ) { // --> Si es anterior

			// controlar que no venga el usuario haciendo un left
			const carousel = this.getPosicionCarousel();

			if ( carousel.left > 0 ) { // Solo si el elemento carousel esta desplazado hacia la derecha

                this._renderer.removeClass(this.carousel.nativeElement, 'animate');
                    
                let images = document.querySelectorAll('#gallerycomponent > .gallery-carousel > .gallery-carousel-image') as NodeListOf<HTMLElement>;

                var first = images[0];
                var last = images[ images.length - 1];

                this._renderer.insertBefore(this.carousel.nativeElement, last, first);

                this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(-' +  desplazar + 'px)' );

				this.agregarUltimaImagenAlComienzo = false;
			} else // Si el carousel tiene el left negativo quiere decir que tiene margen todavia para ser movido hacia la derecha sin tener que agregar la ultima imagen al comienzo
                this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(' +  x  + 'px)' );
		} else if ( x > 0 && !this.agregarUltimaImagenAlComienzo ) 
            this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(-' + ( desplazar - Math.abs(x) ) + 'px)' );
		else if ( x < 0 && this.agregarUltimaImagenAlComienzo ) // solo cuando va la derecha sin evento previo
            this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(' +  x  + 'px)' );
		else if ( x < 0 && !this.agregarUltimaImagenAlComienzo ) // el usuario vuelve a la izquierda despues de haber ido hacia la derecha y haber agregado la ultima imagen al comienzo
            this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(' + ( -desplazar - Math.abs(x) ) + 'px)' );
	}

	getValorXParaMoverElCarousel(event): Promise<number>{

		return new Promise((resolve)=>{

			if ( this.punto_de_cambio == 0 )
				resolve(event.deltaX);
			else {

				if ( event.offsetDirection == DIRECCIONES.LEFT )
					var x = (event.deltaX < this.punto_de_cambio) ? -(Math.abs(event.deltaX) - Math.abs(this.punto_de_cambio)) : event.deltaX;
				else if ( event.offsetDirection == DIRECCIONES.RIGHT )
					var x = (event.deltaX > this.punto_de_cambio) ? (event.deltaX - this.punto_de_cambio) : event.deltaX;

				resolve(x);
			}
		});
	}
	// Devuelve el elemento que tiene que ser tenido en cuenta
	// para el evento Pan.
	// devuelve: ( CAROSUEL o IMAGEN )
	getElementoActivoParaEventoPan(index_imagen=null): Promise<any>{

		return new Promise((resolve)=>{

			if ( this.scale == 1 ) {
				this.changeElementoATransformarSubject.next(ELEMENTOS.CAROUSEL);
				resolve(ELEMENTOS.CAROUSEL);
			} else {
				// Si la imagen ha sido ampliada tengo que verificar los bordes
				// derecho e izquierdo
				const index = index_imagen === null ? this.selectedImage : index_imagen;

				const imagen = this.getPosicionDeLaImagen(index);
				let elemento = ELEMENTOS.NONE;

				if ( !imagen ) {
					elemento = ELEMENTOS.CAROUSEL;
					this.changeElementoATransformarSubject.next(elemento);
		    		resolve(elemento);
				}

				// pueser ser que el borde derecho de la imagen haya pasado el viewport
				if ( ( imagen.left < 0 ) && ( this.imagen.width - imagen.right >= 0 ) )
		    		elemento = ELEMENTOS.CAROUSEL;
		    	else if ( ( imagen.left < 0 ) && ( this.imagen.width - imagen.right < 0 ) )
		    		elemento = ELEMENTOS.IMAGEN;
		    	// puede ser que el borde izquierdo de la imagen haya pasado el viewport
				else if ( ( imagen.left >= 0 ) && ( imagen.right > this.imagen.width ) )
					elemento = ELEMENTOS.CAROUSEL;
		    	else if ( ( imagen.left < 0 ) && ( imagen.right > this.imagen.width ) )
		    		elemento = ELEMENTOS.IMAGEN;

		    	this.changeElementoATransformarSubject.next(elemento);
		    	resolve(elemento);
		   	}
		});
	}

	onPanEndCarousel(event){

		if ( event.maxPointers > 1 ) return; // Si no es pan sale

	  	if ( ( event.isFinal ) && ( this.elem_activo_para_pan === ELEMENTOS.CAROUSEL ) ) {

			this.controlarDesplazamientoTotalDeCarousel(event);

			this.punto_de_cambio = 0;
	  	}
	}

	// Controla el total de desplacamiento del carousel,
	// en caso que supere la distancia minima del swipe ( |-----> o <-----| )
	// efectuo el desplazamiento del carousel para mostrar la proxima o anterior imagen
	// sino lo vuelvo a poner en el estado natural x = 0
	controlarDesplazamientoTotalDeCarousel(event){

		if ( this.scale < 1 ) return;

		const imagen = this.getPosicionDeLContainerImagen(this.selectedImage);

		// Si la toda la imagen esta dentro del viewport => salir
		if ( ( imagen.left > 0 ) && ( imagen.right < window.innerWidth ) ) return;

		// puede ser que este desplazado hacia la izquierda <-----| y se tenga que pasar al siquiente
		if ( (  imagen.left < 0 ) && ( this.imagen.width - imagen.right >= DISTANCIA_MINIMA_SWIPE ) )
    		this.desplazarCarouselALaImagenSiguiente();
    	// puede ser que se haya desplazado hacia la izquierda <----| pero no sea tanto el desplamiento como ir a la siguiente imagen
		else if ( (  imagen.left < 0 ) && ( this.imagen.width - imagen.right < DISTANCIA_MINIMA_SWIPE ) )
    		this.desplazarCarouselALaImagenSiguienteFallido(imagen);
    	// puede ser que este desplazado hacia la derecha |---> y se tenga que pasar a la anterior
		else if ( ( imagen.left >= DISTANCIA_MINIMA_SWIPE ) && ( imagen.right > window.innerWidth ) )
			this.desplazarCarouselALaImagenAnterior();
		// puede ser que se haya desplazado hacia la derecha |--->  pero no sea tanto el desplamiento como ir a la anterior imagen
    	else if ( ( imagen.left > 0 ) && ( imagen.left < DISTANCIA_MINIMA_SWIPE ) )
    		this.desplazarCarouselALaImagenAnteriorFallido();
	}

	desplazarCarouselALaImagenSiguiente(){

		this.deplazarCarousel(DIRECCIONES.LEFT);
	}

	desplazarCarouselALaImagenSiguienteFallido(imagen=null){

		// Si se agrego la ultima imagen al comienzo .. la vuelvo a poner al final
		if ( !this.agregarUltimaImagenAlComienzo ) {

			const x = imagen && imagen.left ? imagen.left : 0;

            var first = <HTMLElement> document.querySelector('#gallerycomponent > .gallery-carousel > .gallery-carousel-image:first-child');

            this._renderer.appendChild(this.carousel.nativeElement, first);

			this.agregarUltimaImagenAlComienzo = true;

			this.translateXCarousel(x, false)
				.then(()=>{
					this.translateXCarousel(0);
				});

		} else
			this.translateXCarousel(0);
    }

	desplazarCarouselALaImagenAnterior() {

		this.deplazarCarousel(DIRECCIONES.RIGHT);
	}

	desplazarCarouselALaImagenAnteriorFallido(){

		const desplazar = this.getValorDeDesplazamientoEnElPanDelCarousel();

    	this.translateXCarousel(-desplazar)
			.then(()=> {

                var first = <HTMLElement> document.querySelector('#gallerycomponent > .gallery-carousel > .gallery-carousel-image:first-child');
                
                this._renderer.appendChild(this.carousel.nativeElement, first);

                this._renderer.removeClass(this.carousel.nativeElement, 'animate');
                this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(0px)' );

				this.agregarUltimaImagenAlComienzo = true;
			});
    }

    deplazarCarousel(direccion) {

    	if ( direccion == DIRECCIONES.LEFT )
			this.doEfectoNext();
		else if ( direccion == DIRECCIONES.RIGHT )
			this.doEfectoPrevious();
    }

	// ---------------------------------------------------
	// Eventos PINCH en IMAGEN
	// ---------------------------------------------------
	onPinchStart(index_imagen, event){

	}

    onPinch(index_imagen, event){

    	this.scale = this.last.scale * event.scale;
    	this.moveX = event.deltaX < 0 ? (this.last.x - Math.abs(event.deltaX)) : (this.last.x + event.deltaX);
    	this.moveY = event.deltaY < 0 ? (this.last.y - Math.abs(event.deltaY)) : (this.last.y + event.deltaY);

    	this.transformImagen(index_imagen, 0);
    }

    onPinchEnd(index_imagen, event){

    	if ( this.scale < ESCALAS.MINIMO || this.scale > ESCALAS.MAXIMO ) {

    		this.scale = this.controlarLimitesDelZoom(this.scale);  // anda

    		this.setUltimosValores();

    		this.transformImagen(index_imagen, 500)
    			.then(()=>{

    				// si espero a que termine de transformarse, y luego
    				// la reubico no me gusta mucho el efecto, prefiero que se
    				// haga todo a la vez, por eso llamo al metodo en paralelo
    				// this.setImagenEnUnaPosicionCorrectaDentroDelViewport(index_imagen);
    			});

    		// pongo a reubicar la imagen al mismo tiempo que se esta transformando
    		this.setImagenEnUnaPosicionCorrectaDentroDelViewport(index_imagen);
    	} else {

	    	this.setUltimosValores();
	    	this.setImagenEnUnaPosicionCorrectaDentroDelViewport(index_imagen);
    	}
    }

    controlarLimitesDelZoom(scale) {

        if ( scale < ESCALAS.MINIMO )
          scale = ESCALAS.MINIMO;
        else if (scale > ESCALAS.MAXIMO)
          scale = ESCALAS.MAXIMO;

        return scale;
    }

    // ---------------------------------------------------
	// Eventos PAN en IMAGEN
	// ---------------------------------------------------
	onPanStartImagen(index_imagen, event) {

	}

    async onPanImagen(index_imagen, event){

    	if ( ! this.touch_device ) return;

    	if ( event.maxPointers > 1 ) return;  // que sea pan y no pinch

    	this.elem_activo_para_pan = await this.getElementoActivoParaEventoPan(index_imagen);

		if ( this.elem_activo_para_pan === ELEMENTOS.IMAGEN ) {

			if ( this.punto_de_cambio )
				this.punto_de_cambio = 0;

			this.moveImagen(index_imagen, event);
		}
	}

	moveImagen(index_imagen, event) {

    	this.moveX = event.deltaX < 0 ? (this.last.x - Math.abs(event.deltaX)) : (this.last.x + event.deltaX);
    	this.moveY = event.deltaY < 0 ? (this.last.y - Math.abs(event.deltaY)) : (this.last.y + event.deltaY);

    	this.transformImagen(index_imagen, 0);
    }

    onPanEndImagen(index_imagen, event) {

    	if ( ! this.isTouchDevice() ) return;

    	if ( event.maxPointers > 1 ) return;

    	if ( this.elem_activo_para_pan === ELEMENTOS.IMAGEN ) {

    		// Tengo que esperar un tiempo para poder setear los ultimos valores
	    	// porque en el metodo transformImagen usa la clase .animate
	    	// la cual tiene la transition en 600ms
	    	const timeoutID = setTimeout(()=>{
						    	this.setUltimosValores();

						    	this.setImagenEnUnaPosicionCorrectaDentroDelViewport(index_imagen);
						   	}, 200);
    	}
	}

	/* ----------------------------------------------------------------------
	EFECTOS DE IMAGEN
	---------------------------------------------------------------------- */
    async setImagenEnUnaPosicionCorrectaDentroDelViewport(index_imagen){

   		// Si luego de la transformacion del elemento, la escala a la cual
    	// se transformo es igual a 1 siempre la llevo al medio
		if ( this.scale == ESCALAS.MINIMO ) {
			this.centerImagen(index_imagen, 500);
			return;
		}

    	const imagen = this.getPosicionDeLaImagen(index_imagen);

		const opciones = {
			x: undefined,
			y: undefined,
			duracion: 500 // antes 200
		};

		let move_imagen = false;

		// no necesito chequear la x, porque si sobresale del viewport
		// debe comenzar a funcionar el panCarousel
		const nueva_x = await this.getNuevaPosicionXDeLaImagen(imagen);

		// Una vez obtenida la nuevo valor de X calculo cuanto tengo que mover la imagen
		if ( nueva_x !== null ) {

			opciones.x = ( nueva_x < imagen.left ) ? -(imagen.left - nueva_x) : (nueva_x - imagen.left);
			move_imagen = true;
		}

		const nueva_y = await this.getNuevaPosicionYDeLaImagen(imagen);

		// Una vez obtenida la nuevo valor de Y calculo cuanto tengo que mover la imagen
		if ( nueva_y !== null ) {

			opciones.y = ( nueva_y < imagen.top ) ? -(imagen.top - nueva_y) : (nueva_y - imagen.top);
			move_imagen = true;
		}

		if ( move_imagen )
			this.moveImagenANuevaPosicionXY(index_imagen, opciones);
    }

    getPosicionCarousel(){

    	const carousel: any = document.querySelector("#gallerycomponent > .gallery-carousel");

    	// left, top, right, bottom, x, y, width, height
        return carousel ? carousel.getBoundingClientRect() : null;
    }

    getPosicionDeLContainerImagen(index_imagen = null) {

    	const selector = "#gallery_carousel_imagen_"+index_imagen;

    	const div: any = document.querySelector(selector);

    	// left, top, right, bottom, x, y, width, height
        return div ? div.getBoundingClientRect() : null;
    }

    getPosicionDeLaImagen(index_imagen = null) {

    	const selector = "#gallery_carousel_imagen_"+index_imagen+" > img";

    	const img: any = document.querySelector(selector);

    	// left, top, right, bottom, x, y, width, height
        return img ? img.getBoundingClientRect() : null;
    }

    getElementoIMGDeLaImagen(index_imagen){

    	const selector = "#gallery_carousel_imagen_"+index_imagen+" > img";

    	const img: any = document.querySelector(selector);

        return img;
    }

    transformImagen(index_imagen, duration = 50 ): Promise<void>{

	    return new Promise((resolve)=>{

			const img = this.getElementoIMGDeLaImagen(index_imagen);

			let css = '';

			if ( duration > 0 )
				css += `transition: all ${duration}ms cubic-bezier( 0.5, 0, 0.5, 1 ); `;

			const x = isNaN( this.moveX ) ? this.moveX : Number(this.moveX);
			const y = isNaN( this.moveY ) ? this.moveY : Number(this.moveY);

			css += `transform: matrix(${Number(this.scale)}, 0, 0, ${Number(this.scale)}, ${ x }, ${ y });`;

	        img.setAttribute('style', css);

	        var timeoutID = setTimeout(()=>{

	        					clearTimeout(timeoutID);
	        					resolve();
	        				}, duration);
	    });
    }

	centerImagen(index_imagen, duration = 1000){

		const img = this.getElementoIMGDeLaImagen(index_imagen);

		if ( !img ) return;

		const img_rect = img.getBoundingClientRect();

		let x, y, calculado;

		if ( img_rect.width >= img_rect.height ) { // horizontal
			x = 0;
			calculado = ( img_rect.height / 2 );
			y = `calc(50% - ${ calculado }px)`;
		} else {
			calculado = ( img_rect.width / 2 );
			x = `calc(50% - ${ calculado }px)`;
			y = 0;
		}

		let css = '';

		if ( duration > 0 )
			css += `transition: all ${duration}ms cubic-bezier( 0.5, 0, 0.5, 1 ); `;

		css += `transform: matrix(${Number(this.scale)}, 0, 0, ${Number(this.scale)}, ${ x }, ${ y });`;

        img.setAttribute('style', css);

        // Una vez centralizada la imagen, seteo los ultimos valores
        var timeoutID = setTimeout(()=>{

	        					clearTimeout(timeoutID);

	        					this.moveX = 0;
	        					this.moveY = 0;
	        					this.setUltimosValores();
	        				}, duration);
    }

    getNuevaPosicionXDeLaImagen(imagen: any ={}): Promise<any> {

    	return new Promise((resolve)=>{

    		// Si el left y el right esta ambos fuera del vierport, el usuario
			// se esta moviendo dentro de la imagen, no tengo que intervenir porque
			// no cruzo ningun limite
			if ( imagen.left < 0 && imagen.right > window.innerWidth )
				resolve(null);

    		let x = 0;

    		if ( imagen.width < window.innerWidth)
    			x = ( ( window.innerWidth / 2 ) - ( imagen.width / 2 ) );
    		else
    			x = imagen.left > 0 ? 0 : -( Math.abs(imagen.left) - ( window.innerWidth - imagen.right ) );

		    resolve(x);
		});
    }

    getNuevaPosicionYDeLaImagen(imagen: any ={}): Promise<any> {

    	return new Promise((resolve) => {

			// Si el top y el bottom esta ambos fuera del vierport, el usuario
			// se esta moviendo dentro de la imagen, no tengo que intervenir porque
			// no cruzo ningun limite
			if ( imagen.top < 0 && imagen.bottom > window.innerHeight )
				resolve(null);

			let y = 0;

			// Si la altura de la imagen es menor al viewport, lo coloco en el medio de la pantalla
			if ( imagen.height < window.innerHeight )
				y = ( ( window.innerHeight / 2 ) - ( imagen.height / 2 ) );
			else // Si es mas grande la imagen que el viewport pero paso el borde top o el bottom del viewport lo debo dejar justo en el borde
	    		y = imagen.top > 0 ? 0 : -( Math.abs(imagen.top) - ( window.innerHeight - imagen.bottom ) );

			resolve(y);
		});
    }

    moveImagenANuevaPosicionXY(index_imagen, params: any = { x: null, y: null, duracion: 600 }) {

    	const { x, y, duracion } = params;

    	if ( x )
	    	this.moveX = x < 0 ? (this.last.x - Math.abs(x)) : (this.last.x + x);

    	if ( y )
	    	this.moveY = y < 0 ? (this.last.y - Math.abs(y)) : (this.last.y + y);

        //this.scale = this.last.scale;

        this.transformImagen(index_imagen, duracion)
        	.then(()=>{
        		this.setUltimosValores();
        	});
    }

    translateImagen(index_imagen, params: any = { x: null, y: null, duracion: 600 } ): Promise<void>{

	    return new Promise((resolve)=>{

	    	const { x, y, duracion } = params;

	    	const imagen = this.getElementoIMGDeLaImagen(index_imagen);

	    	if ( !imagen ) return;

	       	if ( duracion > 0 )
				imagen.style.transition = `all ${duracion}ms cubic-bezier( 0.5, 0, 0.5, 1 )`;

			let translates = '';

			if ( x )
				translates += `translateX(${ x }px) `;

			if ( y )
				translates += `translateY(${ y }px)`;

	        imagen.style.transform = translates;

	        var timeoutID = setTimeout(()=>{

	        					imagen.style.transition = '';
	        					imagen.style.transform = '';
	        					resolve();
	        					clearTimeout(timeoutID);
	        				}, duracion);
	    });
    }

	/* ----------------------------------------------------------------------
	METODOS para CAROUSEL
	---------------------------------------------------------------------- */
	translateXCarousel(valor=0, con_animacion=true ): Promise<void>{

	    return new Promise((resolve)=>{

	    	const carousel: any = document.querySelector('#gallerycomponent > .gallery-carousel');

			const x = isNaN( valor ) ? valor : Number(valor);

	        //carousel.style.background = 'red';

	        if ( con_animacion )
	       		carousel.classList.add('animate');

	        carousel.style.transform = `translateX(${ x }px)`;

	        // Sin el translate se hace con animacion
	        // tengo que esperar lo que demora la clase .animate
	        // la transition : 600ms por ejemplo
	        const delay = con_animacion ? 600 : 0;

	        var timeoutID = setTimeout(()=>{

	        					carousel.classList.remove('animate');
	        					carousel.style.transform = '';
	        					resolve();
	        					clearTimeout(timeoutID);
	        				}, delay);
	    });
    }

	doEfectoPrevious(){

		this.animacion_en_curso = true;

		const imagen_a_restablecer = this.selectedImage;

		this.selectedImage = (this.selectedImage - 1) < 0  ? this.images.length - 1 : this.selectedImage -1;

		this.setSpinner();

		const desplazar = this.getValorDeDesplazamientoEnElPanDelCarousel();


		if ( this.agregarUltimaImagenAlComienzo ) {

            this._renderer.removeClass(this.carousel.nativeElement, 'animate');
            
            let images = document.querySelectorAll('#gallerycomponent > .gallery-carousel > .gallery-carousel-image') as NodeListOf<HTMLElement>;

            var first = images[0];
            var last = images[ images.length - 1];

            this._renderer.insertBefore(this.carousel.nativeElement, last, first);

            this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(-' + desplazar + 'px)' );

			this.agregarUltimaImagenAlComienzo = false;
		}

		var timeoutID = setTimeout(() => {

                this._renderer.addClass(this.carousel.nativeElement, 'animate');
                this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(0px)' );

				this.animacion_en_curso = false;
				this.agregarUltimaImagenAlComienzo = true;
				this.restablecerValoresPorDefectoDeLaImagen(imagen_a_restablecer, 600);
				this.quitarClaseAnimacionAlCarousel(700);
				clearTimeout(timeoutID);
			}, 100);
	}

	doEfectoNext(){

		this.animacion_en_curso = true;

		const imagen_a_restablecer = this.selectedImage;

		this.selectedImage = ( this.selectedImage + 1 >= this.images.length ) ? 0 : this.selectedImage + 1;

		this.setSpinner();

		let desplazar = this.getValorDeDesplazamientoEnElPanDelCarousel();


		// Si el usuario en algun momento del PAN va hacia la derecha |--->
		// y el componente agrega una imagen al comienzo para mostrar
		// y el usuario luego vuelve hacia la izquierada <----|
		// y cuando el panend del carousel, sabe que supera el minimo de Swipe
		// tengo que sacar la primer imagen primero y luego continuar con
		// normalmente, sino no funciona
		if ( !this.agregarUltimaImagenAlComienzo ) {

			desplazar += desplazar;
		}

        this._renderer.addClass(this.carousel.nativeElement, 'animate');
        this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(-' + desplazar + 'px)' );

		var timeoutID = setTimeout(() => {

				if ( !this.agregarUltimaImagenAlComienzo ) {

                    var first = <HTMLElement> document.querySelector('#gallerycomponent > .gallery-carousel > .gallery-carousel-image:first-child');
                
                    if ( first ) {
                        this._renderer.appendChild(this.carousel.nativeElement, first);
    					this.agregarUltimaImagenAlComienzo = true;
                    }
				}
                
                var first = <HTMLElement> document.querySelector('#gallerycomponent > .gallery-carousel > .gallery-carousel-image:first-child');

                if ( first ) {
                    this._renderer.appendChild(this.carousel.nativeElement, first);

                    this._renderer.removeClass(this.carousel.nativeElement, 'animate');
                    this._renderer.setStyle(this.carousel.nativeElement, 'transform', 'translateX(0px)' );

                    this.animacion_en_curso = false;
                    this.restablecerValoresPorDefectoDeLaImagen(imagen_a_restablecer);
                }
				clearTimeout(timeoutID);
			}, 600);
	}

	quitarClaseAnimacionAlCarousel(duration=0){

		var timeoutID = setTimeout(() => {

                            this._renderer.removeClass(this.carousel.nativeElement, 'animate');

							clearTimeout(timeoutID);
						}, duration);
	}

	restablecerValoresPorDefectoDeLaImagen(index_imagen, delay=0) {

		var timeoutID = setTimeout(() => {
				// Con solo poner la escala en 1 y centrarla vuelve la
				// imagen a su estado y posicion original
				this.setValoresPorDefecto();
				this.centerImagen(index_imagen, 0);

				clearTimeout(timeoutID);
			}, delay);
	}
}
